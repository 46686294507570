import { Edit, ContentCopy, Delete, Image } from "@mui/icons-material";
import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import { useNodeStorage } from "../../../stores/useNodeStorage";
import { Typography } from "@mui/material";
// import makeStyles from "@mui/styles/makeStyles";
// import { useTheme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    nodeContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: "4px",
        position: "relative",
        transition: "all 0.2s ease",
        width: "196px"
    },
    nodeHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        gap: theme.spacing(0.4),
        paddingLeft: "4px"
    },
    nodeTitle: {
        color: theme.palette.text.primary,
        fontSize: "16px"
    },
    actionButtons: {
        display: "flex",
        position: "absolute",
        right: "15px",
        top: "12px",
        cursor: "pointer",
        gap: 6
    },
    icon: {
        width: "16px",
        height: "16px",
        color: theme.palette.text.primary,
        marginTop: 0,
        marginRight: 0
    },
    imageContent: {
        color: theme.palette.text.primary,
        fontSize: "12px",
        width: 180,
        marginTop: theme.spacing(1)
    },
    imagePreview: {
        width: "180px",        
        height: "180px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px"       
    },
    placeholderContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100px",
        border: `1px dashed ${theme.palette.text.secondary}`,
        borderRadius: "4px",
        color: theme.palette.text.secondary,
        width: "180px",
        marginTop: theme.spacing(1)
    },
    placeholderIcon: {
        fontSize: "32px",
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary
    },
    handle: {
        width: "10px",
        height: "10px",
        //backgroundColor: theme?.palette?.mode === "dark" ? "#2a2b2d" : "#99999950",
        backgroundColor: theme.palette.type === "dark" ? "#2a2b2d" : "#dbd9d9",
        borderRadius: "6px"
    }
}));

export default memo(({ data, isConnectable, id }) => {
    const classes = useStyles();
    const theme = useTheme();
    const storageItems = useNodeStorage();

    const link =
        process.env.REACT_APP_BACKEND_URL === "http://localhost:8090"
            ? "http://localhost:8090"
            : process.env.REACT_APP_BACKEND_URL;

    return (
        <div className={classes.nodeContainer}>
            <Handle
                type="target"
                position="left"
                className={classes.handle}
                onConnect={params => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />

            <div className={classes.actionButtons}>
                <Edit
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("edit");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <ContentCopy
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("duplicate");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <Delete
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("delete");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
            </div>

            <div className={classes.nodeHeader}>
                <Image className={classes.icon} />
                <div className={classes.nodeTitle}>Imagem</div>
            </div>

            <div className={classes.imageContent}>
                {data.url ? (
                    <img
                        src={`${link}/public/${data.url}`}
                        className={classes.imagePreview}
                        alt="Preview"
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain"
                        }}
                    />
                ) : (
                    <div className={classes.placeholderContainer}>
                        <Image className={classes.placeholderIcon} />
                        <Typography variant="caption">Adicione sua imagem</Typography>
                    </div>
                )}
            </div>

            <Handle
                type="source"
                position="right"
                id="a"
                className={classes.handle}
                isConnectable={isConnectable}
            />
        </div>
    );
});
