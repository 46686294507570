import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { i18n } from "../../translate/i18n";
import { Stack, Grid, Typography } from "@mui/material";
import { FolderZip } from "@mui/icons-material";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { green, orange } from "@material-ui/core/colors";
import { Tooltip } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import SaveSharpIcon from "@mui/icons-material/Save";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    dialogContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(2)
    },
    contentWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    zipPreviewWrapper: {
        width: "300px",
        minHeight: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    placeholderContainer: {
        width: "300px",
        height: "200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: `2px dashed ${theme.palette.divider}`,
        borderRadius: "8px",
        backgroundColor: theme.palette.background.default,
        cursor: "pointer",
        transition: "all 0.3s ease",
        margin: "0 auto",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderColor: theme.palette.primary.main
        }
    },
    placeholderContainerDragging: {
        border: `2px dashed ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.action.hover
    },
    placeholderIcon: {
        fontSize: "48px",
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary
    },
    itemText: {
        textOverflow: "ellipsis",
        fontSize: "10px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textAlign: "center",
        color: theme.palette.text.primary
    },
    selectedFile: {
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1)
    },
    actionButtons: {
        display: "flex",
        width: "100%",
        gap: theme.spacing(1),
        alignSelf: "flex-end",
        marginTop: theme.spacing(1)
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const FlowBuilderAddFileZipModal = ({ open, onSave, onUpdate, data, close }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMounted = useRef(true);
    const [activeModal, setActiveModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [medias, setMedias] = useState([]);
    const fileInputRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [labels, setLabels] = useState({
        title: "Adicionar arquivo ZIP ao fluxo",
        btn: "Adicionar"
    });

    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const files = Array.from(e.dataTransfer.files);
        if (files.length > 0) {
            const file = files[0];

            // Verificar tipos de arquivo permitidos
            const allowedTypes = [
                "application/zip",
                "application/x-zip-compressed",
                "application/x-rar-compressed"
            ];

            if (!allowedTypes.includes(file.type)) {
                toast.error("Por favor, insira apenas arquivos ZIP ou RAR");
                return;
            }

            if (file.size > 10000000) {
                toast.error("Arquivo é muito grande! 10MB máximo");
                return;
            }

            setSelectedFile(file);
            setMedias([file]);
        }
    };

    useEffect(() => {
        if (open === "edit") {
            setLabels({
                title: "Editar arquivo ZIP",
                btn: "Salvar"
            });
            if (data?.data?.fileName) {
                setSelectedFile({ name: data.data.fileName });
            }
            setActiveModal(true);
        } else if (open === "create") {
            setLabels({
                title: "Adicionar arquivo ZIP ao fluxo",
                btn: "Adicionar"
            });
            setSelectedFile(null);
            setMedias([]);
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open, data]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
        setMedias([]);
        setSelectedFile(null);
    };

    const handleSaveZip = async () => {
        setIsSaving(true);
        try {
            if (medias.length > 0) {
                setLoading(true);
                const formData = new FormData();
                formData.append("fromMe", true);
                formData.append("medias", medias[0]);
                formData.append("body", medias[0].name);

                try {
                    const response = await api.post("/flowbuilder/filezip", formData);
                    handleClose();
                    if (open === "edit") {
                        onUpdate({
                            ...data,
                            data: {
                                url: response.data.name,
                                fileName: medias[0].name
                            }
                        });
                        toast.success("Arquivo ZIP/RAR atualizado com sucesso!");
                    } else {
                        onSave({
                            url: response.data.name,
                            fileName: medias[0].name
                        });
                        toast.success("Arquivo ZIP/RAR adicionado com sucesso!");
                    }
                } catch (error) {
                    toastError(error);
                }
            } else if (open === "edit") {
                handleClose();
                onUpdate({
                    ...data,
                    data: {
                        url: data.data.url,
                        fileName: data.data.fileName
                    }
                });
            } else {
                toast.error("Por favor, selecione um arquivo ZIP/RAR");
            }
        } catch (error) {
            console.error("Erro ao salvar:", error);
            toast.error("Erro ao salvar zip/rar");
        } finally {
            setLoading(false);
            setIsSaving(false);
        }
    };

    const handleChangeMedias = e => {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];

        if (file.size > 10000000) {
            toast.error("Arquivo é muito grande! 10MB máximo");
            return;
        }

        setSelectedFile(file);
        setMedias([file]);
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={activeModal}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: { maxWidth: "400px" }
                }}
            >
                <DialogTitle>{labels.title}</DialogTitle>
                <DialogContent dividers className={classes.dialogContent}>
                    <Stack spacing={2} className={classes.contentWrapper}>
                        <input
                            type="file"
                            accept="*/*"
                            disabled={loading}
                            hidden
                            ref={fileInputRef}
                            onChange={handleChangeMedias}
                        />

                        {selectedFile ? (
                            <div className={classes.zipPreviewWrapper}>
                                <div className={classes.selectedFile}>
                                    <FolderZip fontSize="large" />
                                    <Typography>{selectedFile.name}</Typography>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`${classes.placeholderContainer} ${
                                    isDragging ? classes.placeholderContainerDragging : ""
                                }`}
                                onClick={() => fileInputRef.current?.click()}
                                onDragEnter={handleDragEnter}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                            >
                                <FolderZip className={classes.placeholderIcon} />
                                <Typography className={classes.itemText} variant="body1">
                                    Clique ou arraste um arquivo ZIP aqui
                                </Typography>
                                <Typography
                                    className={classes.itemText}
                                    variant="caption"
                                    sx={{ mt: 1 }}
                                >
                                    Formatos aceitos: ZIP, RAR
                                </Typography>
                            </div>
                        )}

                        {!loading && (
                            <Button variant="contained" component="label">
                                {open === "edit" ? "Upload arquivo" : "Upload arquivo"}
                                <input
                                    type="file"
                                    accept="*/*"
                                    disabled={loading}
                                    hidden
                                    onChange={handleChangeMedias}
                                />
                            </Button>
                        )}
                        {loading && (
                            <Stack justifyContent={"center"} alignSelf={"center"}>
                                <CircularProgress />
                            </Stack>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {!loading && (
                        <>
                            {/* <Button onClick={handleClose} color="secondary" variant="outlined">
                                {i18n.t("contactModal.buttons.cancel")}
                            </Button>
                            <Button
                                onClick={handleSaveZip}
                                color="primary"
                                variant="contained"
                                disabled={loading}
                            >
                                {labels.btn}
                            </Button> */}
                            <div className={classes.actionButtons}>
                                <Grid item xs={12}>
                                    <MainHeaderButtonsWrapper>
                                        <Tooltip title={i18n.t("buttons.cancel")}>
                                            <Button
                                                onClick={handleClose}
                                                variant="text"
                                                style={{
                                                    right: -245
                                                }}
                                            >
                                                <BackspaceOutlinedIcon
                                                    style={{ color: orange[700] }}
                                                    fontSize="medium"
                                                />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={i18n.t("buttons.save")}>
                                            <Button
                                                type="submit"
                                                variant="text"
                                                onClick={() => handleSaveZip()}
                                                disabled={loading}
                                                className={classes.btnWrapper}
                                                style={{
                                                    right: -235
                                                }}
                                            >
                                                <SaveSharpIcon
                                                    style={{ color: green[700] }}
                                                    fontSize="medium"
                                                />
                                                {isSaving && (
                                                    <CircularProgress
                                                        size={24}
                                                        className={classes.buttonProgress}
                                                    />
                                                )}
                                            </Button>
                                        </Tooltip>
                                    </MainHeaderButtonsWrapper>
                                </Grid>
                            </div>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FlowBuilderAddFileZipModal;
