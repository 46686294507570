import { Edit, ContentCopy, Delete } from "@mui/icons-material";
import { WebhookOutlined } from "@mui/icons-material";
import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import { useNodeStorage } from "../../../stores/useNodeStorage";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    nodeContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: "4px",
        transition: "all 0.2s ease",
        width: "196px",
        minHeight: "100px",
        position: "relative",
        display: "flex",
        flexDirection: "column"
    },
    nodeHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        gap: theme.spacing(0.4),
        paddingLeft: "4px"
    },
    nodeTitle: {
        color: theme.palette.text.primary,
        fontSize: "16px"
    },
    actionButtons: {
        display: "flex",
        position: "absolute",
        right: "15px",
        top: "12px",
        cursor: "pointer",
        gap: 6
    },
    icon: {
        width: "16px",
        height: "16px",
        color: theme.palette.text.primary,
        marginTop: 0,
        marginRight: 0
    },
    webhookContent: {
        flex: 1,
        minHeight: "50px",
        padding: theme.spacing(1, 0),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    webhookText: {
        color: theme.palette.text.primary,
        marginLeft: "8px",
        fontSize: "12px",
        marginTop: theme.spacing(0.5)
    },
    handle: {
        width: "10px",
        height: "10px",
        marginRight: "-8px",
        backgroundColor: theme.palette.type === "dark" ? "#2a2b2d" : "#dbd9d9",
        border: "1px solid rgba(255, 255, 255)",
        borderRadius: "6px"
    },
    outputContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative",
        marginRight: theme.spacing(1)
    },
    outputLabel: {
        color: theme.palette.text.primary,
        fontSize: "12px",
        marginRight: theme.spacing(1)
    }
}));

export default memo(({ data, isConnectable, id }) => {
    const classes = useStyles();
    const theme = useTheme();
    const storageItems = useNodeStorage();

    return (
        <div className={classes.nodeContainer}>
            <Handle
                type="target"
                position="left"
                className={classes.handle}
                style={{
                    zIndex: 1000,
                    top: "50%"
                }}
                isConnectable={isConnectable}
            />

            <div className={classes.actionButtons}>
                <Edit
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("edit");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <ContentCopy
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("duplicate");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <Delete
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("delete");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
            </div>

            <div className={classes.nodeHeader}>
                <WebhookOutlined className={classes.icon} />
                <div className={classes.nodeTitle}>Webhook</div>
            </div>

            <div className={classes.webhookContent}>
                <div className={classes.webhookText}>{data.method || "POST"}</div>
                <div className={classes.webhookText}>{data.url || "URL não configurada"}</div>
                <div className={classes.webhookText}>{data.name || "Webhook"}</div>
            </div>

            <div
                className={classes.outputContainer}
                style={{
                    position: "absolute",
                    top: "35%",
                    right: 0,
                    width: "100%"
                }}
            >
                <span className={classes.outputLabel}>Sucesso</span>
                <Handle
                    type="source"
                    position="right"
                    id="success"
                    className={classes.handle}
                    isConnectable={isConnectable}
                />
            </div>

            <div
                className={classes.outputContainer}
                style={{
                    position: "absolute",
                    top: "65%",
                    right: 0,
                    width: "100%"
                }}
            >
                <span className={classes.outputLabel}>Erro</span>
                <Handle
                    type="source"
                    position="right"
                    id="error"
                    className={classes.handle}
                    isConnectable={isConnectable}
                />
            </div>
        </div>
    );
});
