import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green, orange } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import SaveSharpIcon from "@mui/icons-material/Save";
import { i18n } from "../../translate/i18n";
import { FormControl, Stack, Grid } from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";
import MessageVariablesPicker from "../MessageVariablesPicker";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { Tooltip } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    actionButtons: {
        display: "flex",
        width: "100%",
        gap: theme.spacing(1),
        alignSelf: "flex-end",
        marginTop: theme.spacing(1)
        //borderTop: `1px solid ${theme.palette.divider}`
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    textField: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1), // Adicionado espaço abaixo do campo
        flex: 1
    },
    emojiSection: {
        marginTop: theme.spacing(1)
    },
    emojiContainer: {
        width: "100%",
        maxHeight: 0,
        overflow: "hidden",
        transition: "max-height 0.3s ease",
        "&.expanded": {
            maxHeight: "400px"
        }
    }
}));

const FlowBuilderMenuModal = ({ open, onSave, onUpdate, data, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [activeModal, setActiveModal] = useState(false);
    const [textDig, setTextDig] = useState();
    const [arrayOption, setArrayOption] = useState([]);
    const [showEmoji, setShowEmoji] = useState(false);
    const [currentTextField, setCurrentTextField] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const [labels, setLabels] = useState({
        title: "Adicionar menu ao fluxo",
        btn: "Adicionar"
    });

    useEffect(() => {
        if (open === "edit") {
            setLabels({
                title: "Editar menu",
                btn: "Salvar"
            });
            setTextDig(data.data.message);
            setArrayOption(data.data.arrayOption);
            setActiveModal(true);
        } else if (open === "create") {
            setLabels({
                title: "Adicionar menu ao fluxo",
                btn: "Adicionar"
            });
            setTextDig();
            setArrayOption([]);
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleLocalMsgVar = msgVar => {
        const textField = document.querySelector(`#menu-message-field`);
        if (!textField) return;

        const cursorPos = textField.selectionStart;
        const newText = textDig.slice(0, cursorPos) + msgVar + textDig.slice(cursorPos);

        setTextDig(newText);

        setTimeout(() => {
            const newPos = cursorPos + msgVar.length;
            textField.setSelectionRange(newPos, newPos);
            textField.focus();
        }, 100);
    };

    const handleAddEmoji = emoji => {
        console.log("Campo atual focado:", currentTextField);
        console.log("Emoji selecionado:", emoji.native);

        // Se não tiver campo focado, não faz nada
        if (!currentTextField) return;

        // Pega o índice do campo de opção (se for um)
        const optionIndex = currentTextField.getAttribute("data-option-index");
        const isOptionField = optionIndex !== null;

        // Posição do cursor
        const start = currentTextField.selectionStart;
        const end = currentTextField.selectionEnd;
        const emojiChar = emoji.native;

        if (isOptionField) {
            // Campo de opção
            const index = parseInt(optionIndex);
            const newOptions = [...arrayOption];
            const currentOption = newOptions[index];

            newOptions[index] = {
                ...currentOption,
                value:
                    (currentOption.value || "").substring(0, start) +
                    emojiChar +
                    (currentOption.value || "").substring(end)
            };

            console.log("Atualizando opção:", {
                index,
                oldValue: currentOption.value,
                newValue: newOptions[index].value
            });

            setArrayOption(newOptions);
        } else {
            // Campo de mensagem principal
            const newText =
                (textDig || "").substring(0, start) + emojiChar + (textDig || "").substring(end);
            setTextDig(newText);
        }

        // Atualiza cursor
        setTimeout(() => {
            const newPos = start + emojiChar.length;
            currentTextField.focus();
            currentTextField.setSelectionRange(newPos, newPos);
        }, 10);
    };

    // Garante que o campo focado é atualizado corretamente
    const handleTextFieldFocus = e => {
        console.log("Campo focado:", {
            element: e.target,
            isOption: e.target.hasAttribute("data-option-index"),
            optionIndex: e.target.getAttribute("data-option-index")
        });
        setCurrentTextField(e.target);
    };

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    const handleSaveNode = async () => {
        setIsSaving(true);
        try {
            if (open === "edit") {
                await onUpdate({
                    ...data,
                    data: { message: textDig, arrayOption: arrayOption }
                });
            } else if (open === "create") {
                await onSave({
                    message: textDig,
                    arrayOption: arrayOption
                });
            }
            handleClose();
        } catch (error) {
            console.error("Erro ao salvar:", error);
            toast.error("Erro ao salvar menu");
        } finally {
            setIsSaving(false);
        }
    };

    const removeOption = number => {
        setArrayOption(old => old.filter(item => item.number !== number));
    };

    return (
        <div className={classes.root}>
            <Dialog 
                open={activeModal} 
                onClose={handleClose} 
                maxWidth="xs" 
                fullWidth 
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">{labels.title}</DialogTitle>
                <DialogContent dividers>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                        <TextField
                            id="menu-message-field"
                            label={"Mensagem de explicação do menu"}
                            rows={4}
                            name="text"
                            multiline
                            variant="outlined"
                            value={textDig}
                            onFocus={handleTextFieldFocus}
                            onChange={e => setTextDig(e.target.value)}
                            className={classes.textField}
                            style={{ width: "100%" }}
                        />
                        <Grid container spacing={2}>
                            <Grid xs={12} item>
                                <MessageVariablesPicker
                                    onClick={value => handleLocalMsgVar(value)}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>

                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems="center"
                        mt={2}
                    >
                        <Typography>Adicionar Opção</Typography>
                        <Button
                            onClick={() =>
                                setArrayOption(old => [
                                    ...old,
                                    { number: old.length + 1, value: "" }
                                ])
                            }
                            color="primary"
                            variant="contained"
                        >
                            <AddCircle />
                        </Button>
                    </Stack>
                    <Stack spacing={2}>
                        {arrayOption.map((item, index) => (
                            <Stack key={item.number} width={"100%"}>
                                <Typography>Opção {item.number} </Typography>
                                <Stack direction={"row"} width={"100%"} spacing={1} size="small">
                                    <TextField
                                        placeholder={"Digite opção"}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={item.value || ""}
                                        onFocus={handleTextFieldFocus}
                                        onChange={event => {
                                            const newOptions = [...arrayOption];
                                            newOptions[index].value = event.target.value;
                                            setArrayOption(newOptions);
                                        }}
                                        inputProps={{
                                            "data-option-index": index.toString()
                                        }}
                                    />
                                    {arrayOption.length === item.number && (
                                        <IconButton onClick={() => removeOption(item.number)}>
                                            <Delete />
                                        </IconButton>
                                    )}
                                </Stack>
                            </Stack>
                        ))}
                    </Stack>

                    <div className={classes.emojiSection}>
                        <Grid container spacing={2}>
                            <Grid xs={12} item>
                                <IconButton
                                    onClick={() => setShowEmoji(!showEmoji)}
                                    color={showEmoji ? "primary" : "default"}
                                >
                                    <SentimentSatisfiedAltIcon />
                                </IconButton>
                                <div
                                    className={`${classes.emojiContainer} ${
                                        showEmoji ? "expanded" : ""
                                    }`}
                                >
                                    <Picker
                                        title="Escolha um emoji"
                                        emoji="point_up"
                                        perLine={10}
                                        style={{ width: "100%" }}
                                        showPreview={false}
                                        showSkinTones={false}
                                        onSelect={handleAddEmoji}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions spacing={2}>
                    <div className={classes.actionButtons}>
                        <Grid item xs={12}>
                            <MainHeaderButtonsWrapper>
                                <Tooltip title={i18n.t("buttons.cancel")}>
                                    <Button
                                        onClick={handleClose}
                                        variant="text"
                                        style={{
                                            right: -275
                                        }}
                                    >
                                        <BackspaceOutlinedIcon
                                            style={{ color: orange[700] }}
                                            fontSize="medium"
                                        />
                                    </Button>
                                </Tooltip>
                                <Tooltip title={i18n.t("buttons.save")}>
                                    <Button
                                        type="submit"
                                        variant="text"
                                        onClick={() => handleSaveNode()}
                                        className={classes.btnWrapper}
                                        style={{
                                            right: -275
                                        }}
                                    >
                                        <SaveSharpIcon
                                            style={{ color: green[700] }}
                                            fontSize="medium"
                                        />
                                        {isSaving && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                </Tooltip>
                            </MainHeaderButtonsWrapper>
                        </Grid>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FlowBuilderMenuModal;
