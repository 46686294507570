import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green, orange } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import SaveSharpIcon from "@mui/icons-material/Save";
import { i18n } from "../../translate/i18n";
import { Stack, Grid } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { Tooltip } from "@mui/material";
import MessageVariablesPicker from "../MessageVariablesPicker";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    btnWrapper: {
        position: "relative"
    },
    emojiContainer: {
        width: "100%",
        maxHeight: 0,
        overflow: "hidden",
        transition: "max-height 0.3s ease",
        "&.expanded": {
            maxHeight: "400px"
        }
    },
    actionButtons: {
        display: "flex",
        width: "100%",
        gap: theme.spacing(1),
        alignSelf: "flex-end",
        marginTop: theme.spacing(1)
        //borderTop: `1px solid ${theme.palette.divider}`
    }
}));

const FlowBuilderAddTextModal = ({ open, onSave, onUpdate, data, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [activeModal, setActiveModal] = useState(false);
    const [showEmoji, setShowEmoji] = useState(false);
    const [currentTextField, setCurrentTextField] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const [labels, setLabels] = useState({
        title: "Adicionar mensagem ao fluxo",
        btn: "Adicionar"
    });

    const [textDig, setTextDig] = useState();

    useEffect(() => {
        if (open === "edit") {
            setLabels({
                title: "Editar mensagem ao fluxo",
                btn: "Salvar"
            });
            setTextDig(data.data.label);
            setActiveModal(true);
        } else if (open === "create") {
            setLabels({
                title: "Adicionar mensagem ao fluxo",
                btn: "Adicionar"
            });
            setTextDig("");
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleLocalMsgVar = msgVar => {
        const textField = document.querySelector(`#message-field`);
        if (!textField) return;

        const cursorPos = textField.selectionStart;
        const newText = textDig.slice(0, cursorPos) + msgVar + textDig.slice(cursorPos);

        setTextDig(newText);

        setTimeout(() => {
            const newPos = cursorPos + msgVar.length;
            textField.setSelectionRange(newPos, newPos);
            textField.focus();
        }, 100);
    };

    const handleAddEmoji = (e, targetField) => {
        const emoji = e.native;

        if (targetField && targetField.type === "textarea") {
            const cursorPos = targetField.selectionStart;
            const newValue = textDig.slice(0, cursorPos) + emoji + textDig.slice(cursorPos);
            setTextDig(newValue);

            setTimeout(() => {
                targetField.focus();
                targetField.setSelectionRange(cursorPos + emoji.length, cursorPos + emoji.length);
            }, 10);
        }
    };

    const handleTextFieldFocus = e => {
        setCurrentTextField(e.target);
    };

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    const handleSaveNode = async () => {
        setIsSaving(true);
        try {
            if (open === "edit") {
                await onUpdate({
                    ...data,
                    data: { label: textDig }
                });
            } else if (open === "create") {
                await onSave({
                    text: textDig
                });
            }
            handleClose();
        } catch (error) {
            console.error("Erro ao salvar:", error);
            toast.error("Erro ao salvar mensagem");
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog open={activeModal} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
                <DialogTitle id="form-dialog-title">{labels.title}</DialogTitle>
                <Stack>
                    <DialogContent dividers style={{ gap: "8px", padding: "16px" }}>
                        <TextField
                            id="message-field"
                            label={"Mensagem"}
                            multiline
                            rows={7}
                            name="text"
                            variant="outlined"
                            value={textDig}
                            margin="dense"
                            onFocus={handleTextFieldFocus}
                            onChange={e => setTextDig(e.target.value)}
                            className={classes.textField}
                            style={{ width: "100%" }}
                        />
                        <Grid container spacing={2}>
                            <Grid xs={12} item>
                                <MessageVariablesPicker
                                    onClick={value => handleLocalMsgVar(value)}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <IconButton
                                    onClick={() => setShowEmoji(!showEmoji)}
                                    color={showEmoji ? "primary" : "default"}
                                >
                                    <SentimentSatisfiedAltIcon />
                                </IconButton>
                                <div
                                    className={`${classes.emojiContainer} ${
                                        showEmoji ? "expanded" : ""
                                    }`}
                                >
                                    <Picker
                                        title="Escolha um emoji"
                                        emoji="point_up"
                                        perLine={10}
                                        style={{ width: "100%" }}
                                        showPreview={false}
                                        showSkinTones={false}
                                        onSelect={emoji => handleAddEmoji(emoji, currentTextField)}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.actionButtons}>
                            <Grid item xs={12}>
                                <MainHeaderButtonsWrapper>
                                    <Tooltip title={i18n.t("buttons.cancel")}>
                                        <Button
                                            onClick={handleClose}
                                            variant="text"
                                            style={{
                                                right: -275
                                            }}
                                        >
                                            <BackspaceOutlinedIcon
                                                style={{ color: orange[700] }}
                                                fontSize="medium"
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={i18n.t("buttons.save")}>
                                        <Button
                                            type="submit"
                                            variant="text"
                                            onClick={() => handleSaveNode()}
                                            className={classes.btnWrapper}
                                            style={{
                                                right: -275
                                            }}
                                        >
                                            <SaveSharpIcon
                                                style={{ color: green[700] }}
                                                fontSize="medium"
                                            />
                                            {isSaving && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </Tooltip>
                                </MainHeaderButtonsWrapper>
                            </Grid>
                        </div>
                    </DialogActions>
                </Stack>
            </Dialog>
        </div>
    );
};

export default FlowBuilderAddTextModal;
