import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Stack, Grid } from "@mui/material";
import { green, orange } from "@material-ui/core/colors";
import { Tooltip } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import SaveSharpIcon from "@mui/icons-material/Save";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },
    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    btnWrapper: {
        position: "relative"
    },
    actionButtons: {
        display: "flex",
        width: "100%",
        gap: theme.spacing(1),
        alignSelf: "flex-end",
        marginTop: theme.spacing(1),
        //borderTop: `1px solid ${theme.palette.divider}`
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const FlowBuilderIntervalModal = ({ open, onSave, data, onUpdate, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [timerSec, setTimerSec] = useState(0);
    const [activeModal, setActiveModal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (open === "edit") {
            setTimerSec(data.data.sec);
            setActiveModal(true);
        } else if (open === "create") {
            setTimerSec(0);
            setActiveModal(true);
        }
        return () => {
            isMounted.current = false;
        };
    }, [open]);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    const handleSaveInterval = async values => {
        setIsSaving(true);
        try {
            if (!timerSec || parseInt(timerSec) <= 0) {
                toast.error("Adicione o valor de intervalo");
                return;
            }
            if (parseInt(timerSec) > 120) {
                toast.error("Máximo de tempo atingido 120 segundos");
                return;
            }

            if (open === "edit") {
                await onUpdate({
                    ...data,
                    data: { sec: timerSec }
                });
            } else if (open === "create") {
                await onSave({
                    sec: timerSec
                });
            }
            handleClose();
        } catch (error) {
            console.error("Erro ao salvar:", error);
            toast.error("Erro ao salvar intervalo");
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog open={activeModal} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {open === "create" ? `Adicionar um intervalo ao fluxo` : `Editar intervalo`}
                </DialogTitle>
                <Stack>
                    <DialogContent dividers>
                        <TextField
                            label={"Tempo em segundos"}
                            name="timer"
                            type="number"
                            value={timerSec}
                            onChange={e => setTimerSec(e.target.value)}
                            autoFocus
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0 } }}
                            margin="dense"
                            className={classes.textField}
                            style={{ width: "95%" }}
                        />
                    </DialogContent>
                    <DialogActions dividers>                        
                        <div className={classes.actionButtons}>
                            <Grid item xs={12}>
                                <MainHeaderButtonsWrapper>
                                    <Tooltip title={i18n.t("buttons.cancel")}>
                                        <Button
                                            onClick={handleClose}
                                            variant="text"
                                            style={{
                                                right: -275
                                            }}
                                        >
                                            <BackspaceOutlinedIcon
                                                style={{ color: orange[700] }}
                                                fontSize="medium"
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={i18n.t("buttons.save")}>
                                        <Button
                                            type="submit"
                                            variant="text"
                                            onClick={() => handleSaveInterval()}
                                            className={classes.btnWrapper}
                                            style={{
                                                right: -275
                                            }}
                                        >
                                            <SaveSharpIcon
                                                style={{ color: green[700] }}
                                                fontSize="medium"
                                            />
                                            {isSaving && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </Tooltip>
                                </MainHeaderButtonsWrapper>
                            </Grid>
                        </div>
                    </DialogActions>
                </Stack>
            </Dialog>
        </div>
    );
};

export default FlowBuilderIntervalModal;
