import { Edit, CallSplit, ContentCopy, Delete } from "@mui/icons-material";
import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import { useNodeStorage } from "../../../stores/useNodeStorage";
// import makeStyles from "@mui/styles/makeStyles";
// import { useTheme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    nodeContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: "4px",
        transition: "all 0.2s ease",
        width: "196px",
        height: "100px",
    },
    nodeHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        gap: theme.spacing(0.4),
        paddingLeft: "4px"
    },
    nodeTitle: {
        color: theme.palette.text.primary,
        fontSize: "16px"
    },
    actionButtons: {
        display: "flex",
        position: "absolute",
        right: "15px",
        top: "12px",
        cursor: "pointer",
        gap: 6
    },
    icon: {
        width: "16px",
        height: "16px",
        color: theme.palette.text.primary,
        marginTop: 0,
        marginRight: 0
    },
    percentageText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: "16px",
        justifyContent: "end",
        position: "relative",
        marginRight: "5px",
        display: "flex",
        color: theme.palette.text.primary
    },
    secondPercentage: {
        marginTop: theme.spacing(1)
    },
    handle: {
        width: "10px",
        height: "10px",
        backgroundColor: theme.palette.type === "dark" ? "#2a2b2d" : "#dbd9d9",
        //border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.25)" : "1px solid rgba(255, 255, 255, 0.25)",
        border: "1px solid rgba(255, 255, 255)",
        borderRadius: "6px"
    },
    handleTop: {
        marginTop: "-2px"
    },
    handleBottom: {
        marginTop: "28px"
    }
}));

export default memo(({ data, isConnectable, id }) => {
    const classes = useStyles();
    const theme = useTheme();
    const storageItems = useNodeStorage();

    return (
        <div className={classes.nodeContainer}>
            <Handle
                type="target"
                position="left"
                className={classes.handle}
                onConnect={params => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />

            <div className={classes.actionButtons}>
                <Edit
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("edit");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <ContentCopy
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("duplicate");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <Delete
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("delete");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
            </div>

            <div className={classes.nodeHeader}>
                <CallSplit className={classes.icon} />
                <div className={classes.nodeTitle}>Randomizar</div>
            </div>

            <div className={classes.percentageText}>{`${data.percent}%`}</div>

            <Handle
                type="source"
                position="right"
                id="a"
                className={`${classes.handle} ${classes.handleTop}`}
                isConnectable={isConnectable}
            />

            <div className={`${classes.percentageText} ${classes.secondPercentage}`}>
                {`${100 - data.percent}%`}
            </div>

            <Handle
                type="source"
                position="right"
                id="b"
                className={`${classes.handle} ${classes.handleBottom}`}
                isConnectable={isConnectable}
            />
        </div>
    );
});
