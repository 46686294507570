import { Edit, ContentCopy, Delete, Description } from "@mui/icons-material";
import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import { useNodeStorage } from "../../../stores/useNodeStorage";
import { Typography } from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    nodeContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: "4px",
        transition: "all 0.2s ease",
        width: "196px"
    },
    nodeHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        gap: theme.spacing(0.4),
        paddingLeft: "4px"
    },
    nodeTitle: {
        color: theme.palette.text.primary,
        fontSize: "16px"
    },
    actionButtons: {
        display: "flex",
        position: "absolute",
        right: "15px",
        top: "12px",
        cursor: "pointer",
        gap: 6
    },
    icon: {
        width: "16px",
        height: "16px",
        color: theme.palette.text.primary,
        marginTop: 0,
        marginRight: 0
    },
    documentContent: {
        color: theme.palette.text.primary,
        fontSize: "12px",
        width: "180px",
        marginTop: theme.spacing(1)
    },
    placeholderContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100px",
        border: `1px dashed ${theme.palette.text.secondary}`,
        borderRadius: "4px",
        color: theme.palette.text.secondary,
        width: "180px",
        marginTop: theme.spacing(1)
    },
    placeholderIcon: {
        fontSize: "32px",
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary
    },
    itemContainer: {
        marginTop: "4px",
        padding: "6px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    contentItem: {
        backgroundColor: theme.palette.type === "dark" ? "#666666" : "#F6EEEE",
        marginBottom: "3px",
        borderRadius: "5px"
    },
    itemText: {
        textOverflow: "ellipsis",
        fontSize: "10px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: theme.palette.text.primary,
        display: "flex",
        alignItems: "center",
        alignItems: "center",
        justifyContent: "center",
        gap: theme.spacing(1.5),
        width: "100%"
    },
    handle: {
        width: "10px",
        height: "10px",
        backgroundColor: theme.palette.type === "dark" ? "#2a2b2d" : "#dbd9d9",
        borderRadius: "6px"
    }
}));

export default memo(({ data, isConnectable, id }) => {
    const classes = useStyles();
    const theme = useTheme();
    const storageItems = useNodeStorage();

    const link =
        process.env.REACT_APP_BACKEND_URL === "http://localhost:8090"
            ? "http://localhost:8090"
            : process.env.REACT_APP_BACKEND_URL;

    return (
        <div className={classes.nodeContainer}>
            <Handle
                type="target"
                position="left"
                className={classes.handle}
                onConnect={params => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />

            <div className={classes.actionButtons}>
                <Edit
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("edit");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <ContentCopy
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("duplicate");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <Delete
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("delete");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
            </div>

            <div className={classes.nodeHeader}>
                <Description className={classes.icon} />
                <div className={classes.nodeTitle}>Documento</div>
            </div>

            <div className={classes.documentContent}>
                {data.url ? (
                    <div className={classes.contentItem}>
                        <div className={classes.itemContainer}>
                            <Typography className={classes.itemText}>
                                <Description sx={{ fontSize: 16, marginRight: "4px" }} />
                                {data.fileName || "Documento"}
                            </Typography>
                        </div>
                    </div>
                ) : (
                    <div className={classes.placeholderContainer}>
                        <Description className={classes.placeholderIcon} />
                        <Typography variant="caption">Adicione seu documento</Typography>
                    </div>
                )}
            </div>

            <Handle
                type="source"
                position="right"
                id="a"
                className={classes.handle}
                isConnectable={isConnectable}
            />
        </div>
    );
});
