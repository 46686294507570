import { Edit, ContentCopy, Delete, Message } from "@mui/icons-material";
import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import { useNodeStorage } from "../../../stores/useNodeStorage";
// import makeStyles from "@mui/styles/makeStyles";
// import { useTheme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    nodeContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: "4px",
        position: "relative",
        transition: "all 0.2s ease",        
        width: "196px",
    },
    nodeHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        gap: theme.spacing(0.4),
        paddingLeft: "4px"
    },
    nodeTitle: {
        color: theme.palette.text.primary,
        fontSize: "16px"
    },
    actionButtons: {
        display: "flex",
        position: "absolute",
        right: "15px",
        top: "12px",
        cursor: "pointer",
        gap: 6
    },
    icon: {
        width: "16px",
        height: "16px",
        color: theme.palette.text.primary,
        marginTop: 0,
        marginRight: 0
    },
    messageContent: {
        color: theme.palette.text.primary,
        fontSize: "12px",
        width: "180px",
        marginTop: theme.spacing(1),
        maxHeight: "169px", // altura aproximada para 10 linhas
        overflowY: "auto", // adiciona scroll vertical quando necessário
        lineHeight: "1.2", // altura da linha
        "&::-webkit-scrollbar": {
            width: "6px"
        },
        "&::-webkit-scrollbar-track": {
            background: "transparent"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.mode === "dark" ? "#555" : "#ccc",
            borderRadius: "3px"
        }
    },
    handle: {
        width: "10px",
        height: "10px",
        //backgroundColor: theme?.palette?.mode === "dark" ? "#2a2b2d" : "#99999950",
        backgroundColor: theme.palette.type === "dark" ? "#2a2b2d" : "#dbd9d9",
        borderRadius: "6px"
    }
}));

export default memo(({ data, isConnectable, id }) => {
    const classes = useStyles();
    const theme = useTheme();
    const storageItems = useNodeStorage();

    return (
        <div className={classes.nodeContainer}>
            <Handle
                type="target"
                position="left"
                className={classes.handle}
                onConnect={params => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />

            <div className={classes.actionButtons}>
                <Edit
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("edit");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <ContentCopy
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("duplicate");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <Delete
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("delete");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
            </div>

            <div className={classes.nodeHeader}>
                <Message className={classes.icon} />
                <div className={classes.nodeTitle}>Mensagem</div>
            </div>

            <div className={classes.messageContent}>{data.label}</div>

            <Handle
                type="source"
                position="right"
                id="a"
                className={classes.handle}
                isConnectable={isConnectable}
            />
        </div>
    );
});
