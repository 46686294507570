import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select, Stack, Grid } from "@mui/material";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { green, orange } from "@material-ui/core/colors";
import { Tooltip } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import SaveSharpIcon from "@mui/icons-material/Save";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },
    btnWrapper: {
        position: "relative"
    },
    actionButtons: {
        display: "flex",
        width: "100%",
        gap: theme.spacing(1),
        alignSelf: "flex-end",
        marginTop: theme.spacing(1)
        //borderTop: `1px solid ${theme.palette.divider}`
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const FlowBuilderTicketModal = ({ open, onSave, data, onUpdate, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [activeModal, setActiveModal] = useState(false);
    const [queues, setQueues] = useState([]);
    const [selectedQueue, setSelectedQueue] = useState();
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (open === "edit") {
            (async () => {
                try {
                    const { data: old } = await api.get("/queue");
                    setQueues(old);
                    const queue = old.find(item => item.id === data.data.id);
                    if (queue) {
                        setSelectedQueue(queue.id);
                    }
                    setActiveModal(true);
                } catch (error) {
                    console.log(error);
                }
            })();
        } else if (open === "create") {
            (async () => {
                try {
                    const { data } = await api.get("/queue");
                    setQueues(data);
                    setActiveModal(true);
                } catch (error) {
                    console.log(error);
                }
            })();
        }
        return () => {
            isMounted.current = false;
        };
    }, [open]);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    const handleSaveNode = async () => {
        setIsSaving(true);
        try {
            if (!selectedQueue) {
                toast.error("Selecione uma fila");
                return;
            }

            const queue = queues.find(item => item.id === selectedQueue);

            if (open === "edit") {
                await onUpdate({
                    ...data,
                    data: queue
                });
            } else if (open === "create") {
                await onSave({
                    data: queue
                });
            }
            handleClose();
        } catch (error) {
            console.error("Erro ao salvar:", error);
            toast.error("Erro ao salvar fila");
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={activeModal}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                disableEnforceFocus
                scroll="paper"
            >
                <DialogTitle>
                    {open === "create" ? "Adicionar Fila ao fluxo" : "Editar Fila"}
                </DialogTitle>
                <Stack>
                    <DialogContent dividers>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel>Selecione uma Fila</InputLabel>
                            <Select
                                value={selectedQueue}
                                label="Selecione uma Fila"
                                onChange={e => setSelectedQueue(e.target.value)}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                renderValue={() => {
                                    if (selectedQueue === "") {
                                        return "Selecione uma Fila";
                                    }
                                    const queue = queues.find(w => w.id === selectedQueue);
                                    return queue.name;
                                }}
                            >
                                {queues.length > 0 &&
                                    queues.map((queue, index) => (
                                        <MenuItem dense key={index} value={queue.id}>
                                            {queue.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.actionButtons}>
                            <Grid item xs={12}>
                                <MainHeaderButtonsWrapper>
                                    <Tooltip title={i18n.t("buttons.cancel")}>
                                        <Button
                                            onClick={handleClose}
                                            variant="text"
                                            style={{
                                                right: -275
                                            }}
                                        >
                                            <BackspaceOutlinedIcon
                                                style={{ color: orange[700] }}
                                                fontSize="medium"
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={i18n.t("buttons.save")}>
                                        <Button
                                            type="submit"
                                            variant="text"
                                            onClick={() => handleSaveNode()}
                                            className={classes.btnWrapper}
                                            style={{
                                                right: -275
                                            }}
                                        >
                                            <SaveSharpIcon
                                                style={{ color: green[700] }}
                                                fontSize="medium"
                                            />
                                            {isSaving && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </Tooltip>
                                </MainHeaderButtonsWrapper>
                            </Grid>
                        </div>
                    </DialogActions>
                </Stack>
            </Dialog>
        </div>
    );
};

export default FlowBuilderTicketModal;
