import { Edit, ContentCopy, Delete } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import { useNodeStorage } from "../../../stores/useNodeStorage";
// import makeStyles from "@mui/styles/makeStyles";
// import { useTheme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    nodeContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: "4px",
        transition: "all 0.2s ease",
        position: "relative",
        width: "196px",
    },
    nodeHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        gap: theme.spacing(0.4),
        paddingLeft: "4px"
    },
    nodeTitle: {
        color: theme.palette.text.primary,
        fontSize: "16px"
    },
    actionButtons: {
        display: "flex",
        position: "absolute",
        right: "15px",
        top: "12px",
        cursor: "pointer",
        gap: 6
    },
    icon: {
        width: "16px",
        height: "16px",
        color: theme.palette.text.primary,
        marginTop: 0,
        marginRight: 0
    },
    messageContainer: {
        color: theme.palette.text.primary,
        fontSize: "12px",
        height: "50px",
        overflow: "hidden",
        marginBottom: theme.spacing(1)
    },
    optionContainer: {
        marginBottom: "9px",
        justifyContent: "end",
        display: "flex",
        position: "relative"
    },
    optionText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: theme.palette.text.primary,
        justifyContent: "center",
        flexDirection: "column",
        alignSelf: "end"
    },
    handle: {
        width: "10px",
        height: "10px",
        backgroundColor: theme.palette.type === "dark" ? "#2a2b2d" : "#dbd9d9",
        //border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.25)" : "1px solid rgba(255, 255, 255, 0.25)",
        border: "1px solid rgba(255, 255, 255)",
        borderRadius: "6px"
    },
    handleInput: {
        left: 0,
        transform: "translateX(-50%)"
    }
}));

export default memo(({ data, isConnectable, id }) => {
    const classes = useStyles();
    const theme = useTheme();
    const storageItems = useNodeStorage();

    return (
        <div className={classes.nodeContainer}>
            <Handle
                type="target"
                position="left"
                className={`${classes.handle} ${classes.handleInput}`}
                onConnect={params => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />

            <div className={classes.actionButtons}>
                <Edit
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("edit");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <ContentCopy
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("duplicate");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <Delete
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("delete");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
            </div>

            <div className={classes.nodeHeader}>
                <MenuIcon className={classes.icon} />
                <div className={classes.nodeTitle}>Menu</div>
            </div>

            <div className={classes.messageContainer}>{data.message}</div>
            {(data.arrayOption || []).map((option, index) => (
                <div key={option.number || index} className={classes.optionContainer}>
                    <div className={classes.optionText}>{`[${option.number}] ${option.value}`}</div>
                    <Handle
                        type="source"
                        position="right"
                        id={`a${option.number}`}
                        className={classes.handle}
                        style={{
                            top: `${74 + index * 23}%`,
                            right: "-13px"
                        }}
                        isConnectable={isConnectable}
                    />
                </div>
            ))}
        </div>
    );
});
