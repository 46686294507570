import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Slider, Stack, Grid } from "@mui/material";
import { green, orange } from "@material-ui/core/colors";
import { Tooltip } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import SaveSharpIcon from "@mui/icons-material/Save";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    btnWrapper: {
        position: "relative"
    },
    actionButtons: {
        display: "flex",
        width: "100%",
        gap: theme.spacing(1),
        alignSelf: "flex-end",
        marginTop: theme.spacing(1)
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const FlowBuilderRandomizerModal = ({ open, onSave, data, onUpdate, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [percent, setPercent] = useState(0);
    const [activeModal, setActiveModal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (open === "edit") {
            setPercent(data.data.percent);
            setActiveModal(true);
        } else if (open === "create") {
            setPercent(0);
            setActiveModal(true);
        }
        return () => {
            isMounted.current = false;
        };
    }, [open]);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    const handleValue = (event, newValue) => {
        setPercent(newValue);
    };

    const handleSaveRandomizer = async values => {
        setIsSaving(true);
        try {
            if (!percent || parseInt(percent) <= 0) {
                toast.error("Adicione o valor de intervalo");
                return;
            }
            if (parseInt(percent) > 120) {
                toast.error("Máximo de tempo atingido 120 segundos");
                return;
            }

            if (open === "edit") {
                await onUpdate({
                    ...data,
                    data: { percent: percent }
                });
            } else if (open === "create") {
                await onSave({
                    percent: percent
                });
            }
            handleClose();
        } catch (error) {
            console.error("Erro ao salvar:", error);
            toast.error("Erro ao salvar randomizador");
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog open={activeModal} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {open === "create"
                        ? `Adicionar um randomizador ao fluxo`
                        : `Editar randomizador`}
                </DialogTitle>
                <Stack>
                    <DialogContent dividers>
                        <Stack direction={"row"} minHeight={120} alignItems={"center"} gap={4}>
                            <Typography>{percent}%</Typography>
                            <Slider
                                aria-label="Temperature"
                                defaultValue={percent}
                                valueLabelDisplay="auto"
                                onChange={handleValue}
                                step={10}
                                marks
                                min={0}
                                max={100}
                            />
                            <Typography>{100 - percent}%</Typography>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={handleClose} color="secondary" variant="outlined">
                            {i18n.t("contactModal.buttons.cancel")}
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.btnWrapper}
                            onClick={() => handleSaveContact()}
                        >
                            {open === "create" ? `Adicionar` : "Editar"}
                        </Button> */}
                        <div className={classes.actionButtons}>
                            <Grid item xs={12}>
                                <MainHeaderButtonsWrapper>
                                    <Tooltip title={i18n.t("buttons.cancel")}>
                                        <Button
                                            onClick={handleClose}
                                            variant="text"
                                            style={{
                                                right: -275
                                            }}
                                        >
                                            <BackspaceOutlinedIcon
                                                style={{ color: orange[700] }}
                                                fontSize="medium"
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={i18n.t("buttons.save")}>
                                        <Button
                                            type="submit"
                                            variant="text"
                                            onClick={() => handleSaveRandomizer()}
                                            className={classes.btnWrapper}
                                            style={{
                                                right: -275
                                            }}
                                        >
                                            <SaveSharpIcon
                                                style={{ color: green[700] }}
                                                fontSize="medium"
                                            />
                                            {isSaving && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </Tooltip>
                                </MainHeaderButtonsWrapper>
                            </Grid>
                        </div>
                    </DialogActions>
                </Stack>
            </Dialog>
        </div>
    );
};

export default FlowBuilderRandomizerModal;
