import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select, Stack, Grid } from "@mui/material";
import { green, orange } from "@material-ui/core/colors";
import { Tooltip } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import SaveSharpIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
        "& .MuiOutlinedInput-root": {
            //borderColor: "#909090",
            borderRadius: "4px"
        }
    },
    btnWrapper: {
        position: "relative"
    },
    dialogContent: {
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`
    },
    cancelButton: {
        color: "#F44336"
    },
    addButton: {
        backgroundColor: "#666666",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#777777"
        }
    },
    actionButtons: {
        display: "flex",
        width: "100%",
        gap: theme.spacing(1),
        alignSelf: "flex-end",
        marginTop: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.divider}`
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const selectFieldStyles = {
    ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#909090"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000",
        borderWidth: "thin"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0000FF",
        borderWidth: "thin"
    }
};

const FlowBuilderWebhookModal = ({ open, onSave, onUpdate, data, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [activeModal, setActiveModal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [webhookData, setWebhookData] = useState({
        name: "",
        url: "",
        method: "POST",
        headers: "",
        body: "",
        ...data?.data
    });

    const [labels, setLabels] = useState({
        title: "Adicionar Webhook",
        btn: "ADICIONAR"
    });

    useEffect(() => {
        if (open === "edit") {
            setLabels({
                title: "Editar Webhook",
                btn: "SALVAR"
            });
            setWebhookData(data.data);
            setActiveModal(true);
        } else if (open === "create") {
            setLabels({
                title: "Adicionar Webhook",
                btn: "ADICIONAR"
            });
            setWebhookData({
                name: "",
                url: "",
                method: "POST",
                headers: "",
                body: ""
            });
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open]);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    const handleSaveWebhook = async () => {
        setIsSaving(true);
        try {
            if (open === "edit") {
                await onUpdate({
                    ...data,
                    data: webhookData
                });
            } else if (open === "create") {
                await onSave(webhookData);
            }
            handleClose();
        } catch (error) {
            console.error("Erro ao salvar:", error);
            toast.error("Erro ao salvar webhook");
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={activeModal}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                disableEnforceFocus
                PaperProps={{
                    style: {
                        borderRadius: "8px"
                    }
                }}
            >
                <DialogTitle>{labels.title}</DialogTitle>
                <Stack className={classes.dialogContent}>
                    <Stack spacing={2}>
                        <TextField
                            label="Nome do Webhook"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={webhookData.name}
                            onChange={e =>
                                setWebhookData(prev => ({ ...prev, name: e.target.value }))
                            }
                            className={classes.textField}
                        />

                        <TextField
                            label="URL do Webhook"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={webhookData.url}
                            onChange={e =>
                                setWebhookData(prev => ({ ...prev, url: e.target.value }))
                            }
                            className={classes.textField}
                        />

                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel>Método HTTP</InputLabel>
                            <Select
                                value={webhookData.method}
                                label="Método HTTP"
                                onChange={e =>
                                    setWebhookData(prev => ({ ...prev, method: e.target.value }))
                                }
                                fullWidth
                                className={classes.textField}
                            >
                                <MenuItem value="GET">GET</MenuItem>
                                <MenuItem value="POST">POST</MenuItem>
                                <MenuItem value="PUT">PUT</MenuItem>
                                <MenuItem value="DELETE">DELETE</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            label="Headers (JSON)"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={webhookData.headers}
                            onChange={e =>
                                setWebhookData(prev => ({ ...prev, headers: e.target.value }))
                            }
                            className={classes.textField}
                        />

                        <TextField
                            label="Body (JSON)"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={webhookData.body}
                            onChange={e =>
                                setWebhookData(prev => ({ ...prev, body: e.target.value }))
                            }
                            className={classes.textField}
                        />
                    </Stack>
                </Stack>
                <DialogActions>
                    <div className={classes.actionButtons}>
                        <Grid item xs={12}>
                            <MainHeaderButtonsWrapper>
                                <Tooltip title={i18n.t("buttons.cancel")}>
                                    <Button
                                        onClick={handleClose}
                                        variant="text"
                                        style={{
                                            right: -275
                                        }}
                                    >
                                        <BackspaceOutlinedIcon
                                            style={{ color: orange[700] }}
                                            fontSize="medium"
                                        />
                                    </Button>
                                </Tooltip>
                                <Tooltip title={i18n.t("buttons.save")}>
                                    <Button
                                        type="submit"
                                        variant="text"
                                        onClick={() => handleSaveWebhook()}
                                        className={classes.btnWrapper}
                                        style={{
                                            right: -275
                                        }}
                                    >
                                        <SaveSharpIcon
                                            style={{ color: green[700] }}
                                            fontSize="medium"
                                        />
                                        {isSaving && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                </Tooltip>
                            </MainHeaderButtonsWrapper>
                        </Grid>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FlowBuilderWebhookModal;
