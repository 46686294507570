import {
    AccessTime,
    Edit,
    ContentCopy,
    Delete,
    Image,
    Message,
    Videocam,
    Description,
    FolderZip,
    WebhookOutlined,
    CallSplit
} from "@mui/icons-material";
import MicIcon from "@mui/icons-material/Mic";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import QueueIcon from "@mui/icons-material/Queue";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PhoneIcon from "@mui/icons-material/Phone";
import MenuIcon from "@mui/icons-material/Menu";
import { ImportExport } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import { useNodeStorage } from "../../../stores/useNodeStorage";
import { Typography, Box, Chip } from "@mui/material";
// import makeStyles from "@mui/styles/makeStyles";
// import { useTheme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    nodeContainer: {
        padding: theme.spacing(1),
        borderRadius: "4px",
        backgroundColor: theme.palette.background.paper,
        transition: "all 0.2s ease",
        width: "196px"
    },
    nodeHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        gap: theme.spacing(0.4),
        paddingLeft: "4px"
    },
    nodeTitle: {
        fontSize: 16,
        color: theme.palette.text.primary
    },
    actionButtons: {
        display: "flex",
        position: "absolute",
        right: "15px",
        top: "12px",
        cursor: "pointer",
        gap: 6
    },
    icon: {
        width: "16px",
        height: "16px",
        color: theme.palette.text.primary,
        marginTop: 0,
        marginRight: 0
    },
    handle: {
        width: "10px",
        height: "10px",
        backgroundColor: theme.palette.type === "dark" ? "#2a2b2d" : "#dbd9d9",
        //border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.25)" : "1px solid rgba(255, 255, 255, 0.25)",
        border: "1px solid rgba(255, 255, 255)",
        borderRadius: "6px"
    },
    contentItem: {
        backgroundColor: theme.palette.type === "dark" ? "#666666" : "#F6EEEE",
        marginBottom: "3px",
        borderRadius: "5px"
    },
    itemContainer: {
        marginTop: "4px",
        gap: "5px",
        padding: "6px"
    },
    iconContainer: {
        display: "flex",
        position: "relative",
        flexDirection: "row",
        justifyContent: "center"
    },
    itemText: {
        textOverflow: "ellipsis",
        fontSize: "10px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textAlign: "center",
        color: theme.palette.text.primary
    },
    conditionText: {
        color: theme.palette.text.primary,
        marginLeft: "8px",
        fontSize: "12px",
        marginBottom: theme.spacing(1)
    },
    tagsScrollContainer: {
        maxHeight: "85px",
        overflowY: "auto",
        marginLeft: "2px",
        padding: "4px",
        borderRadius: "4px",
        "&::-webkit-scrollbar": {
            width: "8px"
        },
        "&::-webkit-scrollbar-track": {
            background: "transparent"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px"
        }
    },
    tagsWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "4px"
    },
    mediaPreviewContainer: {
        position: "relative",
        width: "100%",
        height: "80px",
        borderRadius: "4px",
        overflow: "hidden",
        marginBottom: theme.spacing(0.5)
    },
    mediaPreview: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    gradientOverlay: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: "45px", // Aumenter altura da sombra
        background: "linear-gradient(to bottom, transparent, rgba(0,0,0,0.5))", // Aumentar opacidade
        pointerEvents: "none"
    },
    mediaFileName: {
        fontSize: "10px",
        color: theme.palette.text.primary,
        textAlign: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: "0 4px"
    },
    videoPreviewContainer: {
        position: "relative",
        width: "100%",
        height: "80px",
        borderRadius: "4px",
        overflow: "hidden",
        marginBottom: theme.spacing(0.5),
        backgroundColor: theme.palette.type === "dark" ? "#424242" : "#f5f5f5"
    },
    audioContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(0.5),
        padding: theme.spacing(0.5)
    },
    mediaFileInfo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: theme.spacing(0.5),
        padding: "2px 4px",
        marginTop: "2px"
    },
    fileIcon: {
        //width: "12px",
        //height: "12px",
        color: theme.palette.text.primary
    }
}));

export default memo(({ data, isConnectable, id }) => {
    const classes = useStyles();
    const theme = useTheme();
    const storageItems = useNodeStorage();

    const renderIcon = type => {
        const iconProps = {
            sx: { color: theme.palette.text.primary }
        };
        const icons = {
            message: <Message {...iconProps} />,
            img: <Image {...iconProps} />,
            audio: <MicIcon {...iconProps} />,
            video: <Videocam {...iconProps} />,
            document: <Description {...iconProps} />,
            fileZip: <FolderZip {...iconProps} />,
            menu: <MenuIcon {...iconProps} />,
            interval: <AccessTime {...iconProps} />,
            randomizer: <CallSplit {...iconProps} />,
            condition: <ImportExport {...iconProps} />,
            businessHours: <AccessTime {...iconProps} />,
            connection: <PhoneIcon {...iconProps} />,
            queue: <QueueIcon {...iconProps} />,
            tags: <LoyaltyIcon {...iconProps} />,
            addTags: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <AddIcon sx={{ width: 10, height: 10 }} />
                    <LocalOfferIcon {...iconProps} />
                    {/* <AddIcon sx={{ width: 14, height: 14, marginRight: -0.5 }} /> */}
                </div>
            ),
            removeTags: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <RemoveIcon sx={{ width: 10, height: 10 }} />
                    <LocalOfferIcon {...iconProps} />
                    {/* <RemoveIcon sx={{ width: 14, height: 14, marginRight: -0.5 }} /> */}
                </div>
            ),
            webhook: <WebhookOutlined {...iconProps} />
        };
        return icons[type] || null;
    };

    return (
        <div className={classes.nodeContainer}>
            <Handle
                type="target"
                position="left"
                className={classes.handle}
                onConnect={params => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />
            <div className={classes.actionButtons}>
                <Edit
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("edit");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <ContentCopy
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("duplicate");
                    }}
                    sx={{
                        width: "12px",
                        height: "12px",
                        color: theme.palette.text.primary
                    }}
                />
                <Delete
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("delete");
                    }}
                    sx={{
                        width: "12px",
                        height: "12px",
                        color: theme.palette.text.primary
                    }}
                />
            </div>
            <div className={classes.nodeHeader}>
                <FilterAltIcon className={classes.icon} />
                <div className={classes.nodeTitle}>Funil</div>
            </div>
            {/* Botão de Memssagem */}
            <div>
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("message") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>{renderIcon("message")}</div>
                                <Typography className={classes.itemText}>
                                    {
                                        (data.elements || []).find(
                                            itemLoc => itemLoc.number === item
                                        )?.value
                                    }
                                </Typography>
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de Imagem */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("img") && (
                            <div className={classes.itemContainer}>
                                {data.elements?.find(itemLoc => itemLoc.number === item)?.value && (
                                    <>
                                        <div className={classes.mediaPreviewContainer}>
                                            <img
                                                src={`${process.env.REACT_APP_BACKEND_URL}/public/${
                                                    data.elements.find(
                                                        itemLoc => itemLoc.number === item
                                                    )?.value
                                                }`}
                                                className={classes.mediaPreview}
                                                alt="Preview"
                                            />
                                            <div className={classes.gradientOverlay} />
                                        </div>
                                        <div className={classes.mediaFileInfo}>
                                            <Image className={classes.icon} />
                                            <Typography className={classes.mediaFileName}>
                                                {data.elements.find(
                                                    itemLoc => itemLoc.number === item
                                                )?.original || "Imagem"}
                                            </Typography>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de Audio */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("audio") && (
                            <div className={classes.itemContainer}>
                                {data.elements?.find(itemLoc => itemLoc.number === item)?.value && (
                                    <div className={classes.audioContainer}>
                                        <audio
                                            src={`${process.env.REACT_APP_BACKEND_URL}/public/${
                                                data.elements.find(
                                                    itemLoc => itemLoc.number === item
                                                )?.value
                                            }`}
                                            controls
                                            style={{ width: "100%", height: "30px" }}
                                        />
                                        <div className={classes.mediaFileInfo}>
                                            <MicIcon className={classes.icon} />
                                            <Typography className={classes.mediaFileName}>
                                                {data.elements.find(
                                                    itemLoc => itemLoc.number === item
                                                )?.original || "Áudio"}
                                            </Typography>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de Video */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("video") && (
                            <div className={classes.itemContainer}>
                                {/* <div className={classes.iconContainer}>{renderIcon("video")}</div> */}
                                {data.elements?.find(itemLoc => itemLoc.number === item)?.value && (
                                    <>
                                        <div className={classes.videoPreviewContainer}>
                                            <video
                                                src={`${process.env.REACT_APP_BACKEND_URL}/public/${
                                                    data.elements.find(
                                                        itemLoc => itemLoc.number === item
                                                    )?.value
                                                }`}
                                                className={classes.mediaPreview}
                                            />
                                            <div className={classes.gradientOverlay} />
                                        </div>
                                        <div className={classes.mediaFileInfo}>
                                            <Videocam className={classes.icon} />
                                            <Typography className={classes.mediaFileName}>
                                                {data.elements.find(
                                                    itemLoc => itemLoc.number === item
                                                )?.original || "Vídeo"}
                                            </Typography>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de Documentos */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("document") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>
                                    {renderIcon("document")}
                                </div>
                                <Typography className={classes.itemText}>
                                    {
                                        (data.elements || []).find(
                                            itemLoc => itemLoc.number === item
                                        )?.value
                                    }
                                </Typography>
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de FileZip */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("fileZip") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>{renderIcon("fileZip")}</div>
                                <Typography className={classes.itemText}>
                                    {
                                        (data.elements || []).find(
                                            itemLoc => itemLoc.number === item
                                        )?.value
                                    }
                                </Typography>
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão do Menu */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("menu") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>{renderIcon("menu")}</div>
                                <Typography className={classes.itemText}>
                                    {(data.elements || []).find(itemLoc => itemLoc.number === item)
                                        ?.value?.message || "Menu"}
                                </Typography>
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de Daley */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("interval") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>
                                    {renderIcon("interval")}
                                </div>
                                <Typography className={classes.itemText}>
                                    {
                                        (data.elements || []).find(
                                            itemLoc => itemLoc.number === item
                                        )?.value
                                    }
                                </Typography>
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de Randomizer */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("randomizer") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>
                                    {renderIcon("randomizer")}
                                </div>
                                <Typography className={classes.itemText}>
                                    {`Randomizer ${
                                        (data.elements || []).find(
                                            itemLoc => itemLoc.number === item
                                        )?.value?.percent || 0
                                    }%`}
                                </Typography>
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de Condição */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("condition") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>
                                    {renderIcon("condition")}
                                </div>
                                <Typography className={classes.itemText}>
                                    {
                                        (data.elements || []).find(
                                            itemLoc => itemLoc.number === item
                                        )?.value
                                    }
                                </Typography>
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de Fila */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("queue") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>{renderIcon("queue")}</div>
                                <Typography className={classes.itemText}>
                                    {
                                        (data.elements || []).find(
                                            itemLoc => itemLoc.number === item
                                        )?.value?.name
                                    }
                                </Typography>
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de Conexão */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("connection") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>
                                    {renderIcon("connection")}
                                </div>
                                <Typography className={classes.itemText}>
                                    {(data.elements || []).find(itemLoc => itemLoc.number === item)
                                        ?.value?.name || "Conexão não encontrada"}
                                </Typography>
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de Condição de Tags */}
                {/* {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("tags") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>
                                    {renderIcon("tags")}</div>
                                <Typography className={classes.itemText}>
                                    {
                                        (data.elements || []).find(
                                            itemLoc => itemLoc.number === item
                                        )?.value
                                    }
                                </Typography>
                            </div>
                        )}
                    </div>
                ))} */}
                {/* Botão de Add Tags */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("addTags") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>{renderIcon("addTags")}</div>
                                <div className={classes.conditionText}>Adcionar Tags</div>
                                <div className={classes.tagsScrollContainer}>
                                    <div>
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {(data.elements || [])
                                                .find(itemLoc => itemLoc.number === item)
                                                ?.value?.tags?.map(tag => (
                                                    <Chip
                                                        key={tag.id}
                                                        size="small"
                                                        label={`${tag.name} (${
                                                            tag.type === "kanban" ? "Kanban" : "Tag"
                                                        })`}
                                                        style={{
                                                            backgroundColor: tag.color,
                                                            color: "white",
                                                            textShadow: "1px 1px #000",
                                                            width: "fit-content"
                                                        }}
                                                    />
                                                )) || "Nenhuma tag selecionada"}
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de Remover Tags */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("removeTags") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>
                                    {renderIcon("removeTags")}
                                </div>
                                <div className={classes.conditionText}>Remover Tags</div>
                                <div className={classes.tagsScrollContainer}>
                                    <div>
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {(data.elements || [])
                                                .find(itemLoc => itemLoc.number === item)
                                                ?.value?.tags?.map(tag => (
                                                    <Chip
                                                        key={tag.id}
                                                        size="small"
                                                        label={`${tag.name} (${
                                                            tag.type === "kanban" ? "Kanban" : "Tag"
                                                        })`}
                                                        style={{
                                                            backgroundColor: tag.color,
                                                            color: "white",
                                                            textShadow: "1px 1px #000",
                                                            width: "fit-content"
                                                        }}
                                                    />
                                                )) || "Nenhuma tag selecionada"}
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de BusinessHours */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("businessHours") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>
                                    {renderIcon("businessHours")}
                                </div>
                                <Typography className={classes.itemText}>
                                    {
                                        (data.elements || []).find(
                                            itemLoc => itemLoc.number === item
                                        )?.value
                                    }
                                </Typography>
                            </div>
                        )}
                    </div>
                ))}
                {/* Botão de Webhook */}
                {(data.seq || []).map(item => (
                    <div className={classes.contentItem} key={item}>
                        {item.includes("webhook") && (
                            <div className={classes.itemContainer}>
                                <div className={classes.iconContainer}>{renderIcon("webhook")}</div>
                                <Typography className={classes.itemText}>
                                    {
                                        (data.elements || []).find(
                                            itemLoc => itemLoc.number === item
                                        )?.value
                                    }
                                </Typography>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <Handle
                type="source"
                position="right"
                id="a"
                className={classes.handle}
                isConnectable={isConnectable}
            />
        </div>
    );
});
