import { Edit, ContentCopy, Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import LabelIcon from "@mui/icons-material/Label";
import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import { useNodeStorage } from "../../../stores/useNodeStorage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Chip } from "@mui/material";

const useStyles = makeStyles(theme => ({
    nodeContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: "4px",
        transition: "all 0.2s ease",
        width: "196px",
        minHeight: "100px",
        position: "relative",
        display: "flex",
        flexDirection: "column"
    },
    nodeHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        gap: theme.spacing(0.4),
        paddingLeft: "4px"
    },
    nodeTitle: {
        color: theme.palette.text.primary,
        fontSize: "16px"
    },
    actionButtons: {
        display: "flex",
        position: "absolute",
        right: "15px",
        top: "12px",
        cursor: "pointer",
        gap: 6
    },
    icon: {
        width: "16px",
        height: "16px",
        color: theme.palette.text.primary,
        marginTop: 0,
        marginRight: 0
    },
    tagsContent: {
        flex: 1,
        minHeight: "50px",
        padding: theme.spacing(1, 0),
        display: "flex",
        flexDirection: "column"
    },
    tagsScrollContainer: {
        maxHeight: "60px",
        overflowY: "auto",
        marginLeft: "2px",
        marginRight: "80px", // Aumentei a margem direita
        padding: "4px",
        backgroundColor: theme.palette.type === "dark" ? "#424242" : "#f5f5f5",
        borderRadius: "4px",
        "&::-webkit-scrollbar": {
            width: "8px"
        },
        "&::-webkit-scrollbar-track": {
            background: "transparent"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px"
        }
    },
    tagsWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "4px"
    },
    tagsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "4px",
        padding: "4px 8px"
    },
    conditionText: {
        color: theme.palette.text.primary,
        marginLeft: "8px",
        fontSize: "12px",
        marginBottom: theme.spacing(1)
    },
    handle: {
        width: "10px",
        height: "10px",
        marginRight: "-8px",
        backgroundColor: theme.palette.type === "dark" ? "#2a2b2d" : "#dbd9d9",
        border: "1px solid rgba(255, 255, 255)",
        borderRadius: "6px"
    },
    outputContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative",
        marginRight: theme.spacing(1)
    },
    outputLabel: {
        color: theme.palette.text.primary,
        fontSize: "12px",
        marginRight: theme.spacing(1)
    }
}));

export default memo(({ data, isConnectable, id }) => {
    const classes = useStyles();
    const theme = useTheme();
    const storageItems = useNodeStorage();

    return (
        <div className={classes.nodeContainer}>
            <Handle
                type="target"
                position="left"
                className={classes.handle}
                style={{
                    zIndex: 1000,
                    top: "50%"
                }}
                isConnectable={isConnectable}
            />

            <div className={classes.actionButtons}>
                <Edit
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("edit");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <ContentCopy
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("duplicate");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <Delete
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("delete");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
            </div>

            <div className={classes.nodeHeader}>
                <AddIcon className={classes.icon} />
                <LabelIcon className={classes.icon} style={{ marginLeft: -8 }} />
                <div className={classes.nodeTitle}>Add Tags</div>
            </div>

            <div className={classes.tagsContent}>
                <div className={classes.tagsScrollContainer}>
                    <div className={classes.tagsWrapper}>
                        {data.tags?.map(tag => (
                            <Chip
                                key={tag.id}
                                size="small"
                                label={`${tag.name} (${tag.type === "kanban" ? "Kanban" : "Tag"})`}
                                style={{
                                    backgroundColor: tag.color,
                                    color: "white",
                                    textShadow: "1px 1px #000",
                                    width: "fit-content"
                                }}
                            />
                        )) || "Nenhuma tag selecionada"}
                    </div>
                </div>
            </div>

            <div
                className={classes.outputContainer}
                style={{
                    position: "absolute",
                    top: "50%",
                    right: 0,
                    width: "100%"
                }}
            >
                <span className={classes.outputLabel}>Próximo</span>
                <Handle
                    type="source"
                    position="right"
                    id="next"
                    className={classes.handle}
                    isConnectable={isConnectable}
                />
            </div>
        </div>
    );
});
