import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select, Stack, Chip, Box, Grid } from "@mui/material";
import api from "../../services/api";
import { green, orange } from "@material-ui/core/colors";
import { Tooltip } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import SaveSharpIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
        "& .MuiOutlinedInput-root": {
            borderColor: "#909090",
            borderRadius: "4px"
        }
    },
    btnWrapper: {
        position: "relative"
    },
    dialogContent: {
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`
    },
    cancelButton: {
        color: "#F44336"
    },
    addButton: {
        backgroundColor: "#666666",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#777777"
        }
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
        gap: 0.5
    },
    actionButtons: {
        display: "flex",
        width: "100%",
        gap: theme.spacing(1),
        alignSelf: "flex-end",
        marginTop: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.divider}`
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const selectFieldStyles = {
    ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#909090"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000",
        borderWidth: "thin"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0000FF",
        borderWidth: "thin"
    }
};

const FlowBuilderTagsModal = ({ open, onSave, onUpdate, data, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [activeModal, setActiveModal] = useState(false);
    const [availableTags, setAvailableTags] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const [tagsData, setTagsData] = useState({
        tags: [], // Agora vai guardar os objetos completos das tags
        condition: "all"
    });

    const [labels, setLabels] = useState({
        title: "Adicionar Regra de Tags",
        btn: "ADICIONAR"
    });

    const loadTags = async () => {
        try {
            // Buscar tags simples
            const { data: simpleTags } = await api.get("/tags", {
                params: {
                    searchParam: "",
                    pageNumber: 1,
                    kanban: 0
                }
            });

            // Buscar tagsKanban
            const { data: kanbanTags } = await api.get("/tags", {
                params: {
                    searchParam: "",
                    pageNumber: 1,
                    kanban: 1
                }
            });

            if (!isMounted.current) return;

            // Combinar os dois tipos de tags
            const allTags = [
                ...simpleTags.tags.map(tag => ({ ...tag, type: "simple" })),
                ...kanbanTags.tags.map(tag => ({ ...tag, type: "kanban" }))
            ];

            console.log("Tags carregadas:", allTags);
            setAvailableTags(allTags);
        } catch (err) {
            console.error("Erro ao carregar tags:", err);
            setAvailableTags([]);
        }
    };

    useEffect(() => {
        loadTags();
    }, []);

    useEffect(() => {
        if (open === "edit" && data) {
            setLabels({
                title: "Editar Regra de Tags",
                btn: "SALVAR"
            });
            // Garantir que os dados mantêm a estrutura correta
            const editData = {
                ...data.data,
                tags: Array.isArray(data.data.tags) ? data.data.tags.map(tag => tag.id || tag) : []
            };
            setTagsData(editData);
            setActiveModal(true);
        } else if (open === "create") {
            setLabels({
                title: "Adicionar Regra de Tags",
                btn: "ADICIONAR"
            });
            setTagsData({
                tags: [],
                condition: "all"
            });
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open, data]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
        setTagsData({
            tags: [],
            condition: "all"
        });
    };

    const handleSaveNode = async () => {
        setIsSaving(true);
        try {
            // Pegar os objetos completos das tags selecionadas
            const selectedTagsData = tagsData.tags
                .map(tagId => {
                    const tag = availableTags.find(t => t.id === tagId);
                    return tag
                        ? {
                              id: tag.id,
                              name: tag.name,
                              color: tag.color,
                              type: tag.type
                          }
                        : null;
                })
                .filter(Boolean);

            const dataToSave = {
                condition: tagsData.condition,
                tags: selectedTagsData
            };

            if (open === "edit") {
                await onUpdate({
                    ...data,
                    data: dataToSave
                });
            } else {
                await onSave(dataToSave);
            }
            handleClose();
        } catch (error) {
            console.error("Erro ao salvar:", error);
            toast.error("Erro ao salvar tags");
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={activeModal}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                PaperProps={{
                    style: { borderRadius: "8px" }
                }}
                disableEnforceFocus                
            >
                <DialogTitle>{labels.title}</DialogTitle>
                <Stack className={classes.dialogContent}>
                    <Stack spacing={2}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel>Condição</InputLabel>
                            <Select
                                value={tagsData.condition}
                                label="Condição"
                                onChange={e =>
                                    setTagsData(prev => ({
                                        ...prev,
                                        condition: e.target.value
                                    }))
                                }
                                //sx={selectFieldStyles}
                            >
                                <MenuItem value="all">Todas as tags</MenuItem>
                                <MenuItem value="any">Qualquer tag</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel>Tags</InputLabel>
                            <Select
                                multiple
                                value={tagsData.tags}
                                label="Tags"
                                onChange={e =>
                                    setTagsData(prev => ({
                                        ...prev,
                                        tags: e.target.value
                                    }))
                                }
                                //sx={selectFieldStyles}
                                renderValue={selected => (
                                    <Box className={classes.chips}>
                                        {selected.map(value => {
                                            const tag = availableTags.find(t => t.id === value);
                                            return (
                                                <Chip
                                                    key={value}
                                                    size="small"
                                                    label={`${tag?.name} (${
                                                        tag?.type === "kanban" ? "Kanban" : "Tag"
                                                    })`}
                                                    style={{
                                                        backgroundColor: tag?.color,
                                                        color: "white",
                                                        textShadow: "1px 1px #000"
                                                    }}
                                                />
                                            );
                                        })}
                                    </Box>
                                )}
                            >
                                {availableTags.map(tag => (
                                    <MenuItem key={tag.id} value={tag.id}>
                                        <Chip
                                            size="small"
                                            label={`${tag.name} (${
                                                tag.type === "kanban" ? "Kanban" : "Tag"
                                            })`}
                                            style={{
                                                backgroundColor: tag.color,
                                                color: "white",
                                                textShadow: "1px 1px #000"
                                            }}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
                <DialogActions>                 
                    <div className={classes.actionButtons}>
                        <Grid item xs={12}>
                            <MainHeaderButtonsWrapper>
                                <Tooltip title={i18n.t("buttons.cancel")}>
                                    <Button
                                        onClick={handleClose}
                                        variant="text"
                                        style={{
                                            right: -275
                                        }}
                                    >
                                        <BackspaceOutlinedIcon
                                            style={{ color: orange[700] }}
                                            fontSize="medium"
                                        />
                                    </Button>
                                </Tooltip>
                                <Tooltip title={i18n.t("buttons.save")}>
                                    <Button
                                        type="submit"
                                        variant="text"
                                        onClick={() => handleSaveNode()}
                                        disabled={tagsData.tags.length === 0}
                                        className={classes.btnWrapper}
                                        style={{
                                            right: -275
                                        }}
                                    >
                                        <SaveSharpIcon
                                            style={{ color: green[700] }}
                                            fontSize="medium"
                                        />
                                        {isSaving && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                </Tooltip>
                            </MainHeaderButtonsWrapper>
                        </Grid>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FlowBuilderTagsModal;
