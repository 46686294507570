import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, InputLabel, MenuItem, Select, Stack, Grid } from "@mui/material";
import { green, orange } from "@material-ui/core/colors";
import { Tooltip } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import SaveSharpIcon from "@mui/icons-material/Save";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
        "& .MuiOutlinedInput-root": {
            //borderColor: "#909090",
            borderRadius: "4px"
        }
    },
    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    btnWrapper: {
        position: "relative"
    },
    dialogContent: {
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`
    },
    actionButtons: {
        display: "flex",
        width: "100%",
        gap: theme.spacing(1),
        alignSelf: "flex-end",
        marginTop: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.divider}`
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const selectFieldStyles = {
    ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#909090"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000",
        borderWidth: "thin"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0000FF",
        borderWidth: "thin"
    }
};

const FlowBuilderConditionModal = ({ open, onSave, onUpdate, data, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [activeModal, setActiveModal] = useState(false);
    const [rule, setRule] = useState();
    const [textDig, setTextDig] = useState();
    const [valueCondition, setValueCondition] = useState();
    const [isSaving, setIsSaving] = useState(false);

    const [labels, setLabels] = useState({
        title: "Adicionar condição ao fluxo",
        btn: "Adicionar"
    });

    useEffect(() => {
        if (open === "edit") {
            setLabels({
                title: "Editar condição",
                btn: "Salvar"
            });
            setTextDig(data.data.key);
            setRule(data.data.condition);
            setValueCondition(data.data.value);
            setActiveModal(true);
        } else if (open === "create") {
            setLabels({
                title: "Adicionar condição ao fluxo",
                btn: "Adicionar"
            });
            setTextDig();
            setRule();
            setValueCondition();
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    const handleSaveNode = async () => {
        setIsSaving(true);
        try {
            if (open === "edit") {
                await onUpdate({
                    ...data,
                    data: { key: textDig, condition: rule, value: valueCondition }
                });
            } else if (open === "create") {
                await onSave({
                    key: textDig,
                    condition: rule,
                    value: valueCondition
                });
            }
            handleClose();
        } catch (error) {
            console.error("Erro ao salvar:", error);
            toast.error("Erro ao salvar condição");
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={activeModal}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
                disableEnforceFocus
                disableAutoFocus
            >
                <DialogTitle id="form-dialog-title">{labels.title}</DialogTitle>
                <Stack className={classes.dialogContent}>
                    <Stack spacing={2}>
                        <TextField
                            label={"Campo da condição (Digiter apenas 1 chave)"}
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={textDig}
                            onChange={e => setTextDig(e.target.value)}
                            className={classes.textField}
                        />
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-label">
                                Regra de validação
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={rule}
                                label="Regra de validação"
                                onChange={e => setRule(e.target.value)}
                                variant="outlined"
                                fullWidth
                                MenuProps={{
                                    disablePortal: true,
                                    disableScrollLock: true
                                }}
                                className={classes.textField}
                            >
                                <MenuItem value={1}> {"=="} </MenuItem>
                                <MenuItem value={2}> {">="} </MenuItem>
                                <MenuItem value={3}> {"<="} </MenuItem>
                                <MenuItem value={4}> {" < "} </MenuItem>
                                <MenuItem value={5}> {" > "} </MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label={"Valor da condição a ser analisada"}
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={valueCondition}
                            onChange={e => setValueCondition(e.target.value)}
                            className={classes.textField}
                        />
                    </Stack>
                </Stack>
                <DialogActions>
                    <div className={classes.actionButtons}>
                        <Grid item xs={12}>
                            <MainHeaderButtonsWrapper>
                                <Tooltip title={i18n.t("buttons.cancel")}>
                                    <Button
                                        onClick={handleClose}
                                        variant="text"
                                        style={{
                                            right: -275
                                        }}
                                    >
                                        <BackspaceOutlinedIcon
                                            style={{ color: orange[700] }}
                                            fontSize="medium"
                                        />
                                    </Button>
                                </Tooltip>
                                <Tooltip title={i18n.t("buttons.save")}>
                                    <Button
                                        type="submit"
                                        variant="text"
                                        onClick={() => handleSaveNode()}
                                        className={classes.btnWrapper}
                                        style={{
                                            right: -275
                                        }}
                                    >
                                        <SaveSharpIcon
                                            style={{ color: green[700] }}
                                            fontSize="medium"
                                        />
                                        {isSaving && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                </Tooltip>
                            </MainHeaderButtonsWrapper>
                        </Grid>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FlowBuilderConditionModal;
