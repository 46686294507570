import { Edit, AccessTime, ContentCopy, Delete } from "@mui/icons-material";
import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import { Typography } from "@mui/material";
import { useNodeStorage } from "../../../stores/useNodeStorage";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    nodeContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: "4px",
        transition: "all 0.2s ease",
        width: "196px"
    },
    nodeHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        gap: theme.spacing(0.4),
        paddingLeft: "4px"
    },
    nodeTitle: {
        color: theme.palette.text.primary,
        fontSize: "16px"
    },
    actionButtons: {
        display: "flex",
        position: "absolute",
        right: "15px",
        top: "12px",
        cursor: "pointer",
        gap: 6
    },
    icon: {
        width: "16px",
        height: "16px",
        color: theme.palette.text.primary,
        marginTop: 0,
        marginRight: 0
    },
    itemContainer: {
        marginTop: "4px",
        gap: "5px",
        padding: "6px"
    },
    contentItem: {
        backgroundColor: theme.palette.type === "dark" ? "#666666" : "#F6EEEE",
        marginBottom: "3px",
        borderRadius: "5px"
    },
    itemText: {
        textOverflow: "ellipsis",
        fontSize: "10px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textAlign: "center",
        color: theme.palette.text.primary
    },
    handle: {
        width: "10px",
        height: "10px",
        backgroundColor: theme.palette.type === "dark" ? "#2a2b2d" : "#dbd9d9",
        //border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.25)" : "1px solid rgba(255, 255, 255, 0.25)",
        border: "1px solid rgba(255, 255, 255)",
        borderRadius: "6px"
    }
}));

export default memo(({ data, isConnectable, id }) => {
    const classes = useStyles();
    const theme = useTheme();
    const storageItems = useNodeStorage();

    return (
        <div className={classes.nodeContainer}>
            <Handle
                type="target"
                position="left"
                className={classes.handle}
                onConnect={params => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />
            <div className={classes.actionButtons}>
                <Edit
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("edit");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <ContentCopy
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("duplicate");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <Delete
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("delete");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
            </div>
            <div className={classes.nodeHeader}>
                <AccessTime className={classes.icon} />
                <div className={classes.nodeTitle}>Intervalo</div>
            </div>
            <div>
                <div className={classes.contentItem}>
                    <div className={classes.itemContainer}>
                        <Typography className={classes.itemText}>
                            {data.sec} - segundos
                        </Typography>
                    </div>
                </div>
            </div>
            <Handle
                type="source"
                position="right"
                id="a"
                className={classes.handle}
                isConnectable={isConnectable}
            />
        </div>
    );
});
