import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { SpeedDial, SpeedDialAction, SpeedDialIcon, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CircularProgress } from "@material-ui/core";

//import "reactflow/dist/style.css";
import "react-flow-renderer/dist/style.css";
import "./styles/flow-theme.css";
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge
} from "react-flow-renderer";
import FlowBuilderAddTextModal from "../../components/FlowBuilderAddTextModal";
import FlowBuilderIntervalModal from "../../components/FlowBuilderIntervalModal";

import menuNode from "./nodes/menuNode";
import singleBlockNode from "./nodes/singleBlockNode";
import intervalNode from "./nodes/intervalNode";
import randomizerNode from "./nodes/randomizerNode";
import conditionNode from "./nodes/conditionNode";
import ticketNode from "./nodes/ticketNode";
import connectionNode from "./nodes/connectionNode";
import tagsNode from "./nodes/tagsNode";
import addTagsNode from "./nodes/addTagsNode";
import removeTagsNode from "./nodes/removeTagsNode";
import businessHoursNode from "./nodes/businessHoursNode";
import webhookNode from "./nodes/webhookNode";
import startNode from "./nodes/startNode";
import RemoveEdge from "./nodes/removeEdge";
import messageNode from "./nodes/messageNode.js";
import imgNode from "./nodes/imgNode";
import audioNode from "./nodes/audioNode";
import videoNode from "./nodes/videoNode";
import documentNode from "./nodes/documentNode";
import fileZipNode from "./nodes/fileZipNode";

import {
    AccessTime,
    CallSplit,
    DynamicFeed,
    Image,
    ImportExport,
    LibraryBooks,
    Message,
    RocketLaunch,
    Videocam
} from "@mui/icons-material";
import MicIcon from "@mui/icons-material/Mic";
import QueueIcon from "@mui/icons-material/Queue";
import PhoneIcon from "@mui/icons-material/Phone";
import LabelIcon from "@mui/icons-material/Label";
import WebhookIcon from "@mui/icons-material/Webhook";
import MenuIcon from "@mui/icons-material/Menu";
import { Description } from "@mui/icons-material";
import { FolderZip } from "@mui/icons-material";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import { green } from "@mui/material/colors";

import FlowBuilderMenuModal from "../../components/FlowBuilderMenuModal";
import FlowBuilderSingleBlockModal from "../../components/FlowBuilderSingleBlockModal";
import FlowBuilderRandomizerModal from "../../components/FlowBuilderRandomizerModal";
import FlowBuilderConditionModal from "../../components/FlowBuilderConditionModal";
import FlowBuilderTicketModal from "../../components/FlowBuilderAddTicketModal";
import FlowBuilderConnectionModal from "../../components/FlowBuilderConnectionModal";
import FlowBuilderTagsModal from "../../components/FlowBuilderTagsModal";
import FlowBuilderAddTagsModal from "../../components/FlowBuilderAddTagsModal";
import FlowBuilderRemoveTagsModal from "../../components/FlowBuilderRemoveTagsModal";
import FlowBuilderBusinessHoursModal from "../../components/FlowBuilderBusinessHoursModal";
import FlowBuilderWebhookModal from "../../components/FlowBuilderWebhookModal";

import FlowBuilderAddImgModal from "../../components/FlowBuilderAddImgModal";
import FlowBuilderAddAudioModal from "../../components/FlowBuilderAddAudioModal";
import FlowBuilderAddVideoModal from "../../components/FlowBuilderAddVideoModal";
import FlowBuilderAddDocumentModal from "../../components/FlowBuilderAddDocumentModal";
import FlowBuilderAddFileZipModal from "../../components/FlowBuilderAddFileZipModal";

import { useNodeStorage } from "../../stores/useNodeStorage";
import { DnDProvider } from "../../context/DnDContext/DnDContext";
import { useDnD } from "../../context/DnDContext/DnDContext";
//import { useReactFlow } from "reactflow";
import { useReactFlow } from "react-flow-renderer";
import { ReactFlowProvider } from "react-flow-renderer";

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        position: "relative",
        backgroundColor: theme.palette.background.default
        // overflowY: "scroll",
        // ...theme.scrollbarStyles
    },
    reactFlowWrapper: {
        width: "100%",
        height: "100%",
        "& .react-flow__pane": {
            cursor: "grab !important",
            "&:active": {
                cursor: "grabbing !important"
            }
        }
    },
    speeddial: {
        backgroundColor: "red"
    },
    icons: {
        color: theme.palette.icons
    }
}));

function geraStringAleatoria(tamanho) {
    var stringAleatoria = "";
    var caracteres = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < tamanho; i++) {
        stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    return stringAleatoria;
}

const nodeTypes = {
    message: messageNode,
    start: startNode,
    condition: conditionNode,
    menu: menuNode,
    interval: intervalNode,
    img: imgNode,
    audio: audioNode,
    randomizer: randomizerNode,
    video: videoNode,
    document: documentNode,
    fileZip: fileZipNode,
    singleBlock: singleBlockNode,
    ticket: ticketNode,
    connection: connectionNode,
    tags: tagsNode,
    addTags: addTagsNode,
    removeTags: removeTagsNode,
    businessHours: businessHoursNode,
    webhook: webhookNode
};

const edgeTypes = {
    buttonedge: RemoveEdge
};

const initialNodes = [
    {
        id: "1",
        position: { x: 50, y: 280 },
        data: { label: "Inicio do fluxo" },
        type: "start"
    }
];

const initialEdges = [];

const FlowContent = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { id } = useParams();
    const storageItems = useNodeStorage();
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [dataNode, setDataNode] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const [isSelectable, setIsSelectable] = useState(true);
    const [isDraggable, setIsDraggable] = useState(true);
    const [isConnectable, setIsConnectable] = useState(true);
    const [zoomOnScroll, setZoomOnScroll] = useState(true);
    const [panOnScroll, setPanOnScroll] = useState(false);

    const [modalAddMenu, setModalAddMenu] = useState(null);
    const [modalAddSingleBlock, setModalAddSingleBlock] = useState(null);
    const [modalAddInterval, setModalAddInterval] = useState(false);
    const [modalAddRandomizer, setModalAddRandomizer] = useState(null);
    const [modalAddCondition, setModalAddCondition] = useState(null);
    const [modalAddTicket, setModalAddTicket] = useState(null);
    const [modalAddConnection, setModalAddConnection] = useState(null);
    const [modalAddTags, setModalAddTags] = useState(null);
    const [modalAddAddTags, setModalAddAddTags] = useState(null);
    const [modalAddRemoveTags, setModalAddRemoveTags] = useState(null);
    const [modalAddBusinessHours, setModalAddBusinessHours] = useState(null);
    const [modalAddWebhook, setModalAddWebhook] = useState(null);
    const [modalAddText, setModalAddText] = useState(null);
    const [modalAddImg, setModalAddImg] = useState(null);
    const [modalAddAudio, setModalAddAudio] = useState(null);
    const [modalAddVideo, setModalAddVideo] = useState(null);
    const [modalAddDocument, setModalAddDocument] = useState(null);
    const [modalAddFileZip, setModalAddFileZip] = useState(null);

    // Adicione essas constantes junto com seus outros estados
    const reactFlowWrapper = useRef(null);
    const [type] = useDnD();
    const { screenToFlowPosition } = useReactFlow();
    const [_, setType] = useDnD();

    const connectionLineStyle = { stroke: "#666666", strokeWidth: "2px" };

    // Adicione essas funções junto com suas outras funções
    const onDragOver = useCallback(event => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);

    const onDrop = useCallback(event => {
        event.preventDefault();
        const nodeType = event.dataTransfer.getData("application/reactflow");
        if (!nodeType) return;

        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const position = {
            x: (event.clientX - reactFlowBounds.left) / 2.5,
            y: (event.clientY - reactFlowBounds.top) / 2.5
        };

        switch (nodeType) {
            case "menu":
                addNode("menu", { message: "Construa seu Menu", arrayOption: [] }, position);
                break;
            case "singleBlock":
                addNode("singleBlock", {}, position);
                break;
            case "interval":
                addNode("interval", { sec: 5 }, position);
                break;
            case "randomizer":
                addNode("randomizer", { percent: 50 }, position);
                break;
            case "condition":
                addNode("condition", { key: "", condition: "", value: "" }, position);
                break;
            case "ticket":
                addNode("ticket", { label: "Nova fila" }, position);
                break;
            case "connection":
                addNode("connection", { name: "Nova conexão" }, position);
                break;
            case "tags":
                addNode("tags", { tags: [], condition: "all" }, position);
                break;
            case "addTags":
                addNode("addTags", { tags: [] }, position);
                break;
            case "removeTags":
                addNode("removeTags", { tags: [] }, position);
                break;
            case "businessHours":
                addNode("businessHours", { type: "queue", targetId: "", targetName: "" }, position);
                break;
            case "webhook":
                addNode(
                    "webhook",
                    {
                        name: "Novo Webhook",
                        url: "",
                        method: "POST",
                        headers: "",
                        body: "",
                        label: "Novo Webhook"
                    },
                    position
                );
                break;
            case "text":
                addNode("text", { text: "Digite seu texto" }, position);
                break;
            case "img":
                addNode("img", { url: "" }, position);
                break;
            case "audio":
                addNode("audio", { url: "", record: false }, position);
                break;
            case "video":
                addNode("video", { url: "" }, position);
                break;
            case "document":
                addNode("document", { url: "" }, position);
                break;
            case "fileZip":
                addNode("fileZip", { url: "" }, position);
                break;
            default:
                addNode(nodeType, { text: `Novo ${nodeType}` }, position);
        }
    }, []);

    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData("application/reactflow", nodeType);
        event.dataTransfer.effectAllowed = "move";
    };

    const addNode = (type, data, dropPosition = null) => {
        // Calcula a posição
        let position;
        if (dropPosition) {
            position = dropPosition;
        } else {
            const lastNode = nodes[nodes.length - 1];
            position = {
                x: lastNode.position.x + lastNode.width + 40,
                y: lastNode.position.y
            };
        }

        if (type === "start") {
            return setNodes(old => {
                return [
                    ...old.filter(item => item.id !== "1"),
                    {
                        id: "1",
                        position: position,
                        data: { label: "Inicio do fluxo" },
                        type: "start"
                    }
                ];
            });
        }
        if (type === "menu") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            message: data.message || "Construa seu Menu",
                            arrayOption: data.arrayOption || []
                        },
                        type: "menu"
                    }
                ];
            });
        }
        if (type === "singleBlock") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            seq: data.seq || [],
                            elements: data.elements || [],
                            label: "Novo funil"
                        },
                        type: "singleBlock"
                    }
                ];
            });
        }
        if (type === "interval") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            label: `Intervalo ${data.sec || 5} seg.`,
                            sec: data.sec || 5
                        },
                        type: "interval"
                    }
                ];
            });
        }
        if (type === "randomizer") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            percent: data.percent || 50,
                            label: "Novo randomizador"
                        },
                        type: "randomizer"
                    }
                ];
            });
        }
        if (type === "condition") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            key: data.key || "",
                            condition: data.condition || "equal",
                            value: data.value || ""
                        },
                        type: "condition"
                    }
                ];
            });
        }
        if (type === "ticket") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            ...data,
                            label: "Nova fila"
                        },
                        type: "ticket"
                    }
                ];
            });
        }
        if (type === "connection") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            ...data,
                            label: "Nova conexão"
                        },
                        type: "connection"
                    }
                ];
            });
        }
        if (type === "tags") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            ...data,
                            label: "Nova regra de tags"
                        },
                        type: "tags"
                    }
                ];
            });
        }
        if (type === "addTags") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            tags: data.tags || [],
                            label: "Add Tags"
                        },
                        type: "addTags"
                    }
                ];
            });
        }
        if (type === "removeTags") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            tags: data.tags || [],
                            label: "Remover Tags"
                        },
                        type: "removeTags"
                    }
                ];
            });
        }
        if (type === "businessHours") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            type: data.type || "queue",
                            targetId: data.targetId || "",
                            targetName: data.targetName || "",
                            label: "Expediente"
                        },
                        type: "businessHours"
                    }
                ];
            });
        }
        if (type === "webhook") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            name: data.name || "Novo Webhook",
                            url: data.url || "",
                            method: data.method || "POST",
                            headers: data.headers || "",
                            body: data.body || "",
                            label: "Novo Webhook"
                        },
                        type: "webhook"
                    }
                ];
            });
        }
        if (type === "text") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            label: data.text || "Digite seu texto",
                            text: data.text || "Digite seu texto"
                        },
                        type: "message"
                    }
                ];
            });
        }
        if (type === "img") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            url: data.url || "",
                            label: "Nova imagem"
                        },
                        type: "img"
                    }
                ];
            });
        }
        if (type === "audio") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            url: data.url || "",
                            record: data.record || false,
                            label: "Novo áudio"
                        },
                        type: "audio"
                    }
                ];
            });
        }
        if (type === "video") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            url: data.url || "",
                            label: "Novo vídeo"
                        },
                        type: "video"
                    }
                ];
            });
        }
        if (type === "document") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            url: data.url || "",
                            label: "Novo documento"
                        },
                        type: "document"
                    }
                ];
            });
        }
        if (type === "fileZip") {
            return setNodes(old => {
                return [
                    ...old,
                    {
                        id: geraStringAleatoria(30),
                        position: position,
                        data: {
                            url: data.url || "",
                            label: "Novo Arquivo Compactado"
                        },
                        type: "fileZip"
                    }
                ];
            });
        }
    };

    const menuAdd = data => {
        addNode("menu", data);
    };

    const singleBlockAdd = data => {
        addNode("singleBlock", data);
    };

    const intervalAdd = data => {
        addNode("interval", data);
    };

    const randomizerAdd = data => {
        addNode("randomizer", data);
    };

    const conditionAdd = data => {
        addNode("condition", data);
    };

    const ticketAdd = data => {
        addNode("ticket", data);
    };

    const connectionAdd = data => {
        addNode("connection", data);
    };

    const tagsAdd = data => {
        addNode("tags", data);
    };

    const addTagsAdd = data => {
        addNode("addTags", data);
    };

    const removeTagsAdd = data => {
        addNode("removeTags", data);
    };

    const businessHoursAdd = data => {
        addNode("businessHours", data);
    };

    const webhookAdd = data => {
        addNode("webhook", data);
    };

    const textAdd = data => {
        addNode("text", data);
    };

    const imgAdd = data => {
        addNode("img", data);
    };

    const audioAdd = data => {
        addNode("audio", data);
    };

    const videoAdd = data => {
        addNode("video", data);
    };

    const documentAdd = data => {
        addNode("document", data);
    };

    const fileZipAdd = data => {
        addNode("fileZip", data);
    };

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get(`/flowbuilder/flow/${id}`);
                    if (data.flow.flow !== null) {
                        setNodes(data.flow.flow.nodes);
                        setEdges(data.flow.flow.connections);
                    }
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [id]);

    useEffect(() => {
        if (storageItems.action === "delete") {
            setNodes(old => old.filter(item => item.id !== storageItems.node));
            setEdges(old => {
                const newData = old.filter(item => item.source !== storageItems.node);
                const newClearTarget = newData.filter(item => item.target !== storageItems.node);
                return newClearTarget;
            });
            storageItems.setNodesStorage("");
            storageItems.setAct("idle");
        }
        if (storageItems.action === "duplicate") {
            const nodeDuplicate = nodes.filter(item => item.id === storageItems.node)[0];
            const maioresX = nodes.map(node => node.position.x);
            const maiorX = Math.max(...maioresX);
            const finalY = nodes[nodes.length - 1].position.y;
            const nodeNew = {
                ...nodeDuplicate,
                id: geraStringAleatoria(30),
                position: {
                    x: maiorX + 240,
                    y: finalY
                },
                selected: false,
                style: { backgroundColor: "#555555", padding: 0, borderRadius: 6 }
            };
            setNodes(old => [...old, nodeNew]);
            storageItems.setNodesStorage("");
            storageItems.setAct("idle");
        }
        if (storageItems.action === "edit") {
            const nodeToEdit = nodes.find(node => node.id === storageItems.node);
            if (nodeToEdit) {
                setDataNode(nodeToEdit);
                switch (nodeToEdit.type) {
                    case "menu":
                        setModalAddMenu("edit");
                        break;
                    case "singleBlock":
                        setModalAddSingleBlock("edit");
                        break;
                    case "interval":
                        setModalAddInterval("edit");
                        break;
                    case "randomizer":
                        setModalAddRandomizer("edit");
                        break;
                    case "condition":
                        setModalAddCondition("edit");
                        break;
                    case "ticket":
                        setModalAddTicket("edit");
                        break;
                    case "connection":
                        setModalAddConnection("edit");
                        break;
                    case "tags":
                        setModalAddTags("edit");
                        break;
                    case "addTags":
                        setModalAddAddTags("edit");
                        break;
                    case "removeTags":
                        setModalAddRemoveTags("edit");
                        break;
                    case "businessHours":
                        setModalAddBusinessHours("edit");
                        break;
                    case "webhook":
                        setModalAddWebhook("edit");
                        break;
                    case "message":
                        setModalAddText("edit");
                        break;
                    case "img":
                        setModalAddImg("edit");
                        break;
                    case "audio":
                        setModalAddAudio("edit");
                        break;
                    case "video":
                        setModalAddVideo("edit");
                        break;
                    case "document":
                        setModalAddDocument("edit");
                        break;
                    case "fileZip":
                        setModalAddFileZip("edit");
                        break;
                }
            }
            storageItems.setNodesStorage("");
            storageItems.setAct("idle");
        }
    }, [storageItems.action]);

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(params => setEdges(eds => addEdge(params, eds)), [setEdges]);

    const saveFlow = async () => {
        await api
            .post("/flowbuilder/flow", {
                idFlow: id,
                nodes: nodes,
                connections: edges
            })
            .then(res => {
                toast.success("Fluxo salvo com sucesso");
            });
    };

    const doubleClick = (event, node) => {
        console.log("NODE", node);
        setDataNode(node);
        if (node.type === "menu") {
            setModalAddMenu("edit");
        }
        if (node.type === "singleBlock") {
            setModalAddSingleBlock("edit");
        }
        if (node.type === "interval") {
            setModalAddInterval("edit");
        }
        if (node.type === "randomizer") {
            setModalAddRandomizer("edit");
        }
        if (node.type === "condition") {
            setModalAddCondition("edit");
        }
        if (node.type === "ticket") {
            setModalAddTicket("edit");
        }
        if (node.type === "connection") {
            setModalAddConnection("edit");
        }
        if (node.type === "tags") {
            setModalAddTags("edit");
        }
        if (node.type === "addTags") {
            setModalAddAddTags("edit");
        }
        if (node.type === "removeTags") {
            setModalAddRemoveTags("edit");
        }
        if (node.type === "businessHours") {
            setModalAddBusinessHours("edit");
        }
        if (node.type === "webhook") {
            setModalAddWebhook("edit");
        }
        if (node.type === "message") {
            setModalAddText("edit");
        }
        if (node.type === "img") {
            setModalAddImg("edit");
        }
        if (node.type === "audio") {
            setModalAddAudio("edit");
        }
        if (node.type === "video") {
            setModalAddVideo("edit");
        }
        if (node.type === "document") {
            setModalAddDocument("edit");
        }
        if (node.type === "fileZip") {
            setModalAddFileZip("edit");
        }
    };

    const clickNode = (event, node) => {
        setNodes(old =>
            old.map(item => {
                if (item.id === node.id) {
                    return {
                        ...item,
                        style: { /*backgroundColor: "#13111C",*/ padding: 0, borderRadius: 6 }
                    };
                }
                return {
                    ...item,
                    style: { /*backgroundColor: "#13111C",*/ padding: 0, borderRadius: 6 }
                };
            })
        );
    };
    const clickEdge = (event, node) => {
        setNodes(old =>
            old.map(item => {
                return {
                    ...item,
                    style: { backgroundColor: "#13111C", padding: 0, borderRadius: 6 }
                };
            })
        );
    };

    const updateNode = dataAlter => {
        console.log("DATA ALTER", dataAlter);
        setNodes(old =>
            old.map(itemNode => {
                if (itemNode.id === dataAlter.id) {
                    return dataAlter;
                }
                return itemNode;
            })
        );
        setModalAddText(null);
        setModalAddInterval(null);
        setModalAddMenu(null);
    };

    const actions = [
        {
            icon: <RocketLaunch className={classes.icons} />,
            name: "Inicio",
            type: "start"
        },
        {
            icon: <MenuIcon className={classes.icons} />,
            name: "Menu",
            type: "menu"
        },
        {
            icon: <LibraryBooks className={classes.icons} />,
            name: "Funil",
            type: "content"
        },
        {
            icon: <AccessTime className={classes.icons} />,
            name: "Intervalo",
            type: "interval"
        },
        {
            icon: <CallSplit className={classes.icons} />,
            name: "Randomizar",
            type: "random"
        },
        {
            icon: <CallSplit className={classes.icons} />,
            name: "Condicional",
            type: "condition"
        },
        {
            icon: <QueueIcon className={classes.icons} />,
            name: "Fila",
            type: "ticket"
        },
        {
            icon: <AccessTime className={classes.icons} />,
            name: "Expediente",
            type: "businessHours"
        }
    ];

    const clickActions = type => {
        switch (type) {
            case "start":
                addNode("start");
                break;
            case "menu":
                setModalAddMenu("create");
                break;
            case "content":
                setModalAddSingleBlock("create");
                break;
            case "interval":
                setModalAddInterval("create");
                break;
            case "random":
                setModalAddRandomizer("create");
                break;
            case "condition":
                setModalAddCondition("create");
                break;
            case "ticket":
                setModalAddTicket("create");
                break;
            case "connection":
                setModalAddConnection("create");
                break;
            case "tags":
                setModalAddTags("create");
                break;
            case "addTags":
                setModalAddAddTags("create");
                break;
            case "removeTags":
                setModalAddRemoveTags("create");
                break;
            case "businessHours":
                setModalAddBusinessHours("create");
                break;
            default:
        }
    };

    return (
        <Stack sx={{ height: "100vh" }}>
            <FlowBuilderMenuModal
                open={modalAddMenu}
                onSave={menuAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddMenu}
            />
            <FlowBuilderSingleBlockModal
                open={modalAddSingleBlock}
                onSave={singleBlockAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddSingleBlock}
            />
            <FlowBuilderIntervalModal
                open={modalAddInterval}
                onSave={intervalAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddInterval}
            />
            <FlowBuilderRandomizerModal
                open={modalAddRandomizer}
                onSave={randomizerAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddRandomizer}
            />
            <FlowBuilderConditionModal
                open={modalAddCondition}
                onSave={conditionAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddCondition}
            />
            <FlowBuilderTicketModal
                open={modalAddTicket}
                onSave={ticketAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddTicket}
            />
            <FlowBuilderConnectionModal
                open={modalAddConnection}
                onSave={connectionAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddConnection}
            />
            <FlowBuilderTagsModal
                open={modalAddTags}
                onSave={tagsAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddTags}
            />
            <FlowBuilderAddTagsModal
                open={modalAddAddTags}
                onSave={addTagsAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddAddTags}
            />
            <FlowBuilderRemoveTagsModal
                open={modalAddRemoveTags}
                onSave={removeTagsAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddRemoveTags}
            />
            <FlowBuilderBusinessHoursModal
                open={modalAddBusinessHours}
                onSave={businessHoursAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddBusinessHours}
            />
            <FlowBuilderWebhookModal
                open={modalAddWebhook}
                onSave={webhookAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddWebhook}
            />
            <FlowBuilderAddTextModal
                open={modalAddText}
                onSave={textAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddText}
            />
            <FlowBuilderAddImgModal
                open={modalAddImg}
                onSave={imgAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddImg}
            />
            <FlowBuilderAddAudioModal
                open={modalAddAudio}
                onSave={audioAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddAudio}
            />
            <FlowBuilderAddVideoModal
                open={modalAddVideo}
                onSave={videoAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddVideo}
            />
            <FlowBuilderAddDocumentModal
                open={modalAddDocument}
                onSave={documentAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddDocument}
            />
            <FlowBuilderAddFileZipModal
                open={modalAddFileZip}
                onSave={fileZipAdd}
                data={dataNode}
                onUpdate={updateNode}
                close={setModalAddFileZip}
            />

            {!loading && (
                <Paper className={classes.mainPaper} variant="outlined" /*onScroll={handleScroll}*/>
                    <Stack>
                        <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            sx={{
                                position: "absolute",
                                top: 16,
                                left: 16,
                                color: theme.palette.text.primary,
                                ".MuiSpeedDial-fab": {
                                    backgroundColor: theme.palette.primary.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.action.hover
                                    },
                                    "& $icons": {
                                        color: theme.palette.primary.main
                                    }
                                }
                            }}
                            icon={<SpeedDialIcon />}
                            direction={"down"}
                        >
                            {actions.map(action => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    tooltipOpen
                                    tooltipPlacement={"right"}
                                    onClick={() => clickActions(action.type)}
                                />
                            ))}
                        </SpeedDial>
                    </Stack>
                    <Stack
                        sx={{
                            position: "absolute",
                            justifyContent: "center",
                            flexDirection: "row",
                            width: "100%"
                        }}
                    >
                        {/* <Typography style={{ color: "primary", textShadow: "#010101 1px 0 10px" }}>
                            Não se esqueça de salvar seu fluxo!
                        </Typography> */}
                    </Stack>
                    <Stack direction={"row"} justifyContent={"end"}>
                        <Button
                            sx={{ textTransform: "none" }}
                            variant="text"
                            onClick={() => saveFlow()}
                        >
                            <SaveSharpIcon style={{ color: green[700] }} fontSize="medium" />
                        </Button>
                    </Stack>
                    <Stack
                        direction={"row"}
                        style={{
                            width: "100%",
                            height: "90%",
                            position: "relative",
                            display: "flex"
                        }}
                    >
                        <ThemeProvider theme={theme}>
                            <DnDProvider>
                                <div
                                    style={{ width: "100%", height: "100%" }}
                                    ref={reactFlowWrapper}
                                    className={classes.reactFlowWrapper}
                                >
                                    <ReactFlow
                                        nodes={nodes}
                                        edges={edges}
                                        deleteKeyCode={["Backspace", "Delete"]}
                                        onNodesChange={onNodesChange}
                                        onEdgesChange={onEdgesChange}
                                        onNodeDoubleClick={doubleClick}
                                        onNodeClick={clickNode}
                                        onEdgeClick={clickEdge}
                                        onConnect={onConnect}
                                        nodeTypes={nodeTypes}
                                        onDrop={onDrop}
                                        onDragOver={onDragOver}
                                        fitView={false} // Desabilita o fitView automático
                                        defaultViewport={{ x: 0, y: 0, zoom: 1 }} // Define viewport inicial
                                        connectionLineStyle={connectionLineStyle}
                                        elementsSelectable={isSelectable}
                                        nodesConnectable={isConnectable}
                                        nodesDraggable={isDraggable}
                                        zoomOnScroll={zoomOnScroll}
                                        panOnScroll={panOnScroll}
                                        panOnDrag={true}
                                        //className={theme?.palette?.mode === "dark" ? "dark" : ""}
                                        className={
                                            theme?.palette?.mode === "dark" ? "dark" : "light"
                                        }
                                        edgeTypes={edgeTypes}
                                        variant={"cross"}
                                        defaultEdgeOptions={{
                                            animated: true,
                                            style: {
                                                strokeWidth: 1,
                                                strokeDasharray: 5,
                                                animation: "flowEdge 0.5s linear infinite"
                                            }
                                        }}
                                    >
                                        <Controls
                                            style={{
                                                button: {
                                                    backgroundColor:
                                                        theme?.palette?.mode === "dark"
                                                            ? "#1E1E1E"
                                                            : "#fff",
                                                    border: `1px solid ${
                                                        theme?.palette?.mode === "dark"
                                                            ? "#333"
                                                            : "#eee"
                                                    }`,
                                                    color:
                                                        theme?.palette?.mode === "dark"
                                                            ? "#fff"
                                                            : "#333"
                                                },
                                                backgroundColor:
                                                    theme?.palette?.mode === "dark"
                                                        ? "#1E1E1E"
                                                        : "#fff",
                                                borderRadius: "4px",
                                                border: `1px solid ${
                                                    theme?.palette?.mode === "dark"
                                                        ? "#333"
                                                        : "#eee"
                                                }`
                                            }}
                                            showZoom={true}
                                            showFitView={true}
                                            showInteractive={true}
                                            fitViewOptions={{ padding: 2 }}
                                            className={`react-flow__controls ${
                                                theme?.palette?.mode === "dark" ? "dark" : "light"
                                            }`}
                                        />
                                        {/* <MiniMap
                                            nodeStrokeColor={
                                                theme?.palette?.mode === "dark" ? "#555" : "#ddd"
                                            }
                                            nodeColor={
                                                theme?.palette?.mode === "dark" ? "#333" : "#eee"
                                            }
                                            nodeBorderRadius={2}
                                            style={{
                                                backgroundColor:
                                                    theme?.palette?.mode === "dark"
                                                        ? "#1E1E1E"
                                                        : "#fff",
                                                border: `1px solid ${
                                                    theme?.palette?.mode === "dark"
                                                        ? "#333"
                                                        : "#eee"
                                                }`
                                            }}
                                            maskColor={
                                                theme?.palette?.mode === "dark"
                                                    ? "rgba(30,30,30,0.8)"
                                                    : "rgba(255,255,255,0.6)"
                                            }
                                        /> */}
                                        <Background
                                            variant="dots"
                                            gap={12}
                                            size={1}
                                            color={
                                                theme?.palette?.mode === "dark"
                                                    ? "#2a2b2d"
                                                    : "#99999950"
                                            }
                                        />
                                    </ReactFlow>
                                </div>
                            </DnDProvider>
                        </ThemeProvider>

                        <Stack
                            style={{
                                backgroundColor:
                                    theme?.palette?.mode === "dark" ? "#1E1E1E" : "#F7F9FB",
                                height: "20px",
                                width: "58px",
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                zIndex: 1111
                            }}
                        />
                    </Stack>
                    <Stack
                        // style={{
                        //     backgroundColor: "-2px 0px 5px rgba(0,0,0,0.1)",
                        //     height: "66%",
                        //     width: "130px",
                        //     position: "absolute",
                        //     right: 0,
                        //     top: 50,
                        //     zIndex: 1111,
                        //     borderRadius: 3,
                        //     padding: 8,
                        //     boxShadow: "-2px 0px 5px rgba(0,0,0,0.1)"
                        // }}
                        // spacing={1}
                        style={{
                            backgroundColor: "-2px 0px 5px rgba(0,0,0,0.1)",
                            height: "100%",
                            width: "140px",
                            position: "absolute",
                            right: 0,
                            top: 50,
                            zIndex: 1111,
                            borderRadius: 3,
                            padding: 8,
                            boxShadow: "-2px 0px 5px rgba(0,0,0,0.1)",
                            overflowY: "scroll", // Mudamos para scroll
                            maxHeight: "calc(100vh - 100px)",
                            scrollbarWidth: "none", // Firefox
                            msOverflowStyle: "none", // IE/Edge
                            "&::-webkit-scrollbar": {
                                // Chrome/Safari/Opera
                                display: "none"
                            }
                        }}
                        spacing={1}
                    >
                        <Typography
                            style={{ color: theme.palette.text.primary, textAlign: "center" }}
                        >
                            Arraste e Solte
                        </Typography>

                        {/* Botão de Menu */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "menu")}
                            onClick={() => setModalAddMenu("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#683AC8",
                                color: theme.palette.text.primary,
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <DynamicFeed
                                sx={{ width: "16px", height: "16px", marginRight: "4px" }}
                            />
                            Menu
                        </Button>

                        {/* Botão de Funil */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "singleBlock")}
                            onClick={() => setModalAddSingleBlock("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#EC5858",
                                color: theme.palette.text.primary,
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <LibraryBooks
                                sx={{ width: "16px", height: "16px", marginRight: "4px" }}
                            />
                            Funil
                        </Button>

                        {/* Botão de Intervalo */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "interval")}
                            onClick={() => setModalAddInterval("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#F7953B",
                                color: theme.palette.text.primary,
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <AccessTime
                                sx={{ width: "16px", height: "16px", marginRight: "4px" }}
                            />
                            Intervalo
                        </Button>

                        {/* Botão de Randomizar */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "randomizer")}
                            onClick={() => setModalAddRandomizer("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#1FBADC",
                                color: theme.palette.text.primary,
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <CallSplit sx={{ width: "16px", height: "16px", marginRight: "4px" }} />
                            Randomizar
                        </Button>

                        {/* Botão de Condição */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "condition")}
                            onClick={() => setModalAddCondition("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#524d4d",
                                color: "#cccaed",
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <ImportExport
                                sx={{ width: "16px", height: "16px", marginRight: "4px" }}
                            />
                            Condição
                        </Button>

                        {/* Botão da Fila */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "ticket")}
                            onClick={() => setModalAddTicket("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#524d4d",
                                color: "#cccaed",
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <QueueIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} />
                            Fila
                        </Button>

                        {/* Botão de Conexão */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "connection")}
                            onClick={() => setModalAddConnection("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#524d4d",
                                color: "#cccaed",
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <PhoneIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} />
                            Conexão
                        </Button>

                        {/* Botão de Tags */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "tags")}
                            onClick={() => setModalAddTags("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#524d4d",
                                color: "#cccaed",
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <LabelIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} />
                            Tags
                        </Button>

                        {/* Botão de AddTags */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "addTags")}
                            onClick={() => setModalAddAddTags("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#524d4d",
                                color: "#cccaed",
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <LabelIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} />
                            Add Tags
                        </Button>

                        {/* Botão de RemoveTags */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "removeTags")}
                            onClick={() => setModalAddRemoveTags("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#524d4d",
                                color: "#cccaed",
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <LabelIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} />
                            Del Tags
                        </Button>

                        {/* Botão de Expediente */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "businessHours")}
                            onClick={() => setModalAddBusinessHours("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#524d4d",
                                color: "#cccaed",
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <AccessTime
                                sx={{ width: "16px", height: "16px", marginRight: "4px" }}
                            />
                            Expediente
                        </Button>

                        {/* Botão do WebHook */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "webhook")}
                            onClick={() => setModalAddWebhook("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#524d4d",
                                color: "#cccaed",
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <WebhookIcon
                                sx={{ width: "16px", height: "16px", marginRight: "4px" }}
                            />
                            Webhook
                        </Button>

                        {/* Botão de Texto */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "text")}
                            onClick={() => setModalAddText("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#6865A5",
                                color: theme.palette.text.primary,
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab" // Adiciona cursor de arrastar
                            }}
                        >
                            <Message sx={{ width: "16px", height: "16px", marginRight: "4px" }} />
                            Texto
                        </Button>

                        {/* Botão de Imagem */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "img")}
                            onClick={() => setModalAddImg("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#6865A5",
                                color: theme.palette.text.primary,
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <Image sx={{ width: "16px", height: "16px", marginRight: "4px" }} />
                            Imagem
                        </Button>

                        {/* Botão de Áudio */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "audio")}
                            onClick={() => setModalAddAudio("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#6865A5",
                                color: theme.palette.text.primary,
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <MicIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} />
                            Audio
                        </Button>

                        {/* Botão de Vídeo */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "video")}
                            onClick={() => setModalAddVideo("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#6865A5",
                                color: theme.palette.text.primary,
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <Videocam sx={{ width: "16px", height: "16px", marginRight: "4px" }} />
                            Video
                        </Button>

                        {/* Botão de Documento */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "document")}
                            onClick={() => setModalAddDocument("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#6865A5",
                                color: theme.palette.text.primary,
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <Description
                                sx={{ width: "16px", height: "16px", marginRight: "4px" }}
                            />
                            Documento
                        </Button>

                        {/* Botão de FileZip */}
                        <Button
                            draggable
                            onDragStart={event => onDragStart(event, "fileZip")}
                            onClick={() => setModalAddFileZip("create")}
                            variant="contained"
                            style={{
                                backgroundColor: "#6865A5",
                                color: theme.palette.text.primary,
                                padding: 8,
                                textTransform: "none",
                                cursor: "grab"
                            }}
                        >
                            <FolderZip sx={{ width: "16px", height: "16px", marginRight: "4px" }} />
                            ZIP / RAR
                        </Button>
                    </Stack>
                </Paper>
            )}
            {loading && (
                <Stack justifyContent={"center"} alignItems={"center"} height={"70vh"}>
                    <CircularProgress />
                </Stack>
            )}
        </Stack>
    );
};

export const FlowBuilderConfig = () => {
    return (
        <ReactFlowProvider>
            <FlowContent />
        </ReactFlowProvider>
    );
};
