import { Edit, ContentCopy, Delete } from "@mui/icons-material";
import MicIcon from '@mui/icons-material/Mic'
import React, { memo, useEffect, useState } from "react";
import { Handle } from "react-flow-renderer";
import { useNodeStorage } from "../../../stores/useNodeStorage";
import { Typography } from "@mui/material";
// import makeStyles from "@mui/styles/makeStyles";
// import { useTheme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    nodeContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: "4px",
        position: "relative",
        transition: "all 0.2s ease",
        minWidth: "180px"
    },
    nodeHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        gap: theme.spacing(0.4),
        paddingLeft: "4px"
    },
    nodeTitle: {
        color: theme.palette.text.primary,
        fontSize: "16px"
    },
    actionButtons: {
        display: "flex",
        position: "absolute",
        right: "15px",
        top: "12px",
        cursor: "pointer",
        gap: 6
    },
    icon: {
        width: "16px",
        height: "16px",
        color: theme.palette.text.primary,
        marginTop: 0,
        marginRight: 0
    },
    audioContent: {
        color: theme.palette.text.primary,
        fontSize: "12px",
        width: "180px",
        marginTop: theme.spacing(1)
    },
    audioStatus: {
        position: "absolute",
        right: "18px",
        top: "38px",
        color: theme.palette.text.primary
    },
    audioPlayer: {
        marginTop: "24px",
        width: "180px",
        "& audio": {
            width: "100%",
            height: "40px"
        }
    },
    placeholderContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100px",
        border: `1px dashed ${theme.palette.text.secondary}`,
        borderRadius: "4px",
        color: theme.palette.text.secondary,
        width: "180px",
        marginTop: theme.spacing(1)
    },
    placeholderIcon: {
        fontSize: "32px",
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary
    },
    handle: {
        width: "10px",
        height: "10px",
        //backgroundColor: theme?.palette?.mode === "dark" ? "#2a2b2d" : "#99999950",
        backgroundColor: theme.palette.type === "dark" ? "#2a2b2d" : "#dbd9d9",
        borderRadius: "6px"
    }
}));

export default memo(({ data, isConnectable, id }) => {
    const classes = useStyles();
    const theme = useTheme();
    const storageItems = useNodeStorage();
    const [audioKey, setAudioKey] = useState(Date.now());

    const link =
        process.env.REACT_APP_BACKEND_URL === "http://localhost:8090"
            ? "http://localhost:8090"
            : process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        // Força a atualização do player quando data.url muda
        setAudioKey(Date.now());
    }, [data.url]);

    return (
        <div className={classes.nodeContainer}>
            <Handle
                type="target"
                position="left"
                className={classes.handle}
                onConnect={params => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />

            <div className={classes.actionButtons}>
                <Edit
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("edit");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <ContentCopy
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("duplicate");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <Delete
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("delete");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
            </div>

            <div className={classes.nodeHeader}>
                <MicIcon className={classes.icon} />
                <div className={classes.nodeTitle}>Audio</div>
            </div>

            <div className={classes.audioContent}>
                {data.url && (
                    <div className={classes.audioStatus}>
                        {data.record ? "Gravado na hora" : "Audio anexado"}
                    </div>
                )}
                <div className={classes.audioPlayer}>
                    {data.url ? (
                        <audio controls="controls" key={audioKey}>
                            <source src={`${link}/public/${data.url}`} type="audio/mp3" />
                            seu navegador não suporta HTML5
                        </audio>
                    ) : (
                        //<div className={classes.placeholderText}>Adicione seu áudio</div>
                        <div className={classes.placeholderContainer}>
                            <MicIcon className={classes.placeholderIcon} />
                            <Typography variant="body2">Adicione seu áudio</Typography>
                        </div>
                    )}
                </div>
            </div>

            <Handle
                type="source"
                position="right"
                id="a"
                className={classes.handle}
                isConnectable={isConnectable}
            />
        </div>
    );
});
