import { Edit, ContentCopy, Delete, Videocam } from "@mui/icons-material";
import React, { memo, useEffect, useState } from "react";
import { Handle } from "react-flow-renderer";
import { useNodeStorage } from "../../../stores/useNodeStorage";
import { Typography } from "@mui/material";
// import makeStyles from "@mui/styles/makeStyles";
// import { useTheme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    nodeContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: "4px",
        position: "relative",
        transition: "all 0.2s ease",
        minWidth: "180px"
    },
    nodeHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        gap: theme.spacing(0.4),
        paddingLeft: "4px"
    },
    nodeTitle: {
        color: theme.palette.text.primary,
        fontSize: "16px"
    },
    actionButtons: {
        display: "flex",
        position: "absolute",
        right: "15px",
        top: "12px",
        cursor: "pointer",
        gap: 6
    },
    icon: {
        width: "16px",
        height: "16px",
        color: theme.palette.text.primary,
        marginTop: 0,
        marginRight: 0
    },
    videoContent: {
        marginTop: theme.spacing(2),
        color: theme.palette.text.primary,
        fontSize: "12px"
    },
    videoPlayer: {
        marginTop: "24px",
        width: "180px",
        height: "180px",
        "& video": {
            width: "100%",
            height: "100%",
            borderRadius: "4px"
        }
    },
    handle: {
        width: "10px",
        height: "10px",
        //backgroundColor: theme?.palette?.mode === "dark" ? "#2a2b2d" : "#99999950",
        backgroundColor: theme.palette.type === "dark" ? "#2a2b2d" : "#dbd9d9",
        borderRadius: "6px"
    },
    placeholderContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "180px",
        border: `1px dashed ${theme.palette.text.secondary}`,
        borderRadius: "4px",
        color: theme.palette.text.secondary,
        width: "180px",
        marginTop: theme.spacing(1)
    },
    placeholderIcon: {
        fontSize: "32px",
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary
    }
}));

export default memo(({ data, isConnectable, id, onDoubleClick }) => {
    const classes = useStyles();
    const theme = useTheme();
    const storageItems = useNodeStorage();
    const [videoKey, setVideoKey] = useState(Date.now());

    const link =
        process.env.REACT_APP_BACKEND_URL === "http://localhost:8090"
            ? "http://localhost:8090"
            : process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        setVideoKey(Date.now());
    }, [data.url]);

    return (
        <div className={classes.nodeContainer}>
            <Handle
                type="target"
                position="left"
                className={classes.handle}
                onConnect={params => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />

            <div className={classes.actionButtons}>
                <Edit
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("edit");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <ContentCopy
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("duplicate");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
                <Delete
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("delete");
                    }}
                    sx={{ width: "12px", height: "12px", color: theme.palette.text.primary }}
                />
            </div>

            <div className={classes.nodeHeader}>
                <Videocam className={classes.icon} />
                <div className={classes.nodeTitle}>Video</div>
            </div>

            <div className={classes.videoPlayer}>
                {data.url ? (
                    <video controls="controls" key={videoKey}>
                        <source src={`${link}/public/${data.url}`} type="video/mp4" />
                        seu navegador não suporta HTML5
                    </video>
                ) : (
                    <div className={classes.placeholderContainer}>
                        <Videocam className={classes.placeholderIcon} />
                        <Typography variant="body2">Adicione seu vídeo</Typography>
                    </div>
                )}
            </div>

            <Handle
                type="source"
                position="right"
                id="a"
                className={classes.handle}
                isConnectable={isConnectable}
            />
        </div>
    );
});
