import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select, Stack, Grid } from "@mui/material";
import api from "../../services/api";
import { green, orange } from "@material-ui/core/colors";
import { Tooltip } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import SaveSharpIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
        "& .MuiOutlinedInput-root": {
            borderColor: "#909090",
            borderRadius: "4px"
        }
    },
    btnWrapper: {
        position: "relative"
    },
    dialogContent: {
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`
    },
    cancelButton: {
        color: "#F44336"
    },
    addButton: {
        backgroundColor: "#666666",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#777777"
        }
    },
    actionButtons: {
        display: "flex",
        width: "100%",
        gap: theme.spacing(1),
        alignSelf: "flex-end",
        marginTop: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.divider}`
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const selectFieldStyles = {
    ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#909090"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000",
        borderWidth: "thin"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0000FF",
        borderWidth: "thin"
    }
};

const FlowBuilderBusinessHoursModal = ({ open, onSave, onUpdate, data, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [activeModal, setActiveModal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [businessHoursData, setBusinessHoursData] = useState({
        type: "queue",
        targetId: "",
        targetName: ""
    });

    const [options, setOptions] = useState([]);
    const [labels, setLabels] = useState({
        title: "Adicionar Regra de Horário",
        btn: "ADICIONAR"
    });

    const loadOptions = async type => {
        try {
            let response;
            if (type === "queue") {
                response = await api.get("/queue"); // Endpoint correto
                console.log("Filas carregadas:", response.data); // Debug
            } else {
                response = await api.get("/whatsapp");
                console.log("Conexões carregadas:", response.data); // Debug
            }

            if (!isMounted.current) return;

            setOptions(
                response.data.map(item => ({
                    id: item.id,
                    name: item.name
                }))
            );
        } catch (err) {
            console.error("Erro ao carregar opções:", err);
            setOptions([]);
        }
    };

    // Reseta o estado quando o modal abre/fecha
    useEffect(() => {
        if (open === "edit" && data) {
            setLabels({
                title: "Editar Regra de Horário",
                btn: "SALVAR"
            });
            setBusinessHoursData(data.data);
            setActiveModal(true);
        } else if (open === "create") {
            setLabels({
                title: "Adicionar Regra de Horário",
                btn: "ADICIONAR"
            });
            setBusinessHoursData({
                type: "queue",
                targetId: "",
                targetName: ""
            });
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open, data]);

    // Carrega opções quando o tipo muda
    useEffect(() => {
        loadOptions(businessHoursData.type);
    }, [businessHoursData.type]);

    // Cleanup
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
        setBusinessHoursData({
            type: "queue",
            targetId: "",
            targetName: ""
        });
    };

    const handleTypeChange = newType => {
        setBusinessHoursData(prev => ({
            ...prev,
            type: newType,
            targetId: "",
            targetName: ""
        }));
    };

    const handleSaveBusinessHours = async () => {
        setIsSaving(true);
        try {
            const selectedOption = options.find(opt => opt.id === businessHoursData.targetId);
            const dataToSave = {
                ...businessHoursData,
                targetName: selectedOption?.name || ""
            };

            if (open === "edit") {
                await onUpdate({
                    ...data,
                    data: dataToSave
                });
            } else {
                await onSave(dataToSave);
            }
            handleClose();
        } catch (error) {
            console.error("Erro ao salvar:", error);
            toast.error("Erro ao salvar horário de atendimento");
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={activeModal}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                disableEnforceFocus
                PaperProps={{
                    style: { borderRadius: "8px" }
                }}
            >
                <DialogTitle>{labels.title}</DialogTitle>
                <Stack className={classes.dialogContent}>
                    <Stack spacing={2}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel>Tipo de Verificação</InputLabel>
                            <Select
                                value={businessHoursData.type}
                                label="Tipo de Verificação"
                                onChange={e => handleTypeChange(e.target.value)}
                                //sx={selectFieldStyles}
                            >
                                <MenuItem value="queue">Fila</MenuItem>
                                <MenuItem value="whatsapp">Conexão</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel>
                                {businessHoursData.type === "queue" ? "Fila" : "Conexão"}
                            </InputLabel>
                            <Select
                                value={businessHoursData.targetId}
                                label={businessHoursData.type === "queue" ? "Fila" : "Conexão"}
                                onChange={e =>
                                    setBusinessHoursData(prev => ({
                                        ...prev,
                                        targetId: e.target.value
                                    }))
                                }
                                //sx={selectFieldStyles}
                            >
                                {options.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
                <DialogActions>
                    {/* <Button onClick={handleClose} className={classes.cancelButton}>
                        CANCELAR
                    </Button>
                    <Button
                        onClick={handleSave}
                        className={classes.addButton}
                        variant="contained"
                        disabled={!businessHoursData.targetId}
                    >
                        {labels.btn}
                    </Button> */}
                    <div className={classes.actionButtons}>
                        <Grid item xs={12}>
                            <MainHeaderButtonsWrapper>
                                <Tooltip title={i18n.t("buttons.cancel")}>
                                    <Button
                                        onClick={handleClose}
                                        variant="text"
                                        style={{
                                            right: -275
                                        }}
                                    >
                                        <BackspaceOutlinedIcon
                                            style={{ color: orange[700] }}
                                            fontSize="medium"
                                        />
                                    </Button>
                                </Tooltip>
                                <Tooltip title={i18n.t("buttons.save")}>
                                    <Button
                                        type="submit"
                                        variant="text"
                                        onClick={() => handleSaveBusinessHours()}
                                        className={classes.btnWrapper}
                                        style={{
                                            right: -275
                                        }}
                                    >
                                        <SaveSharpIcon
                                            style={{ color: green[700] }}
                                            fontSize="medium"
                                        />
                                        {isSaving && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                </Tooltip>
                            </MainHeaderButtonsWrapper>
                        </Grid>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FlowBuilderBusinessHoursModal;
