import { RocketLaunch } from "@mui/icons-material";
import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    nodeContainer: {
        padding: theme.spacing(0),       
        borderRadius: "4px",
        backgroundColor: theme.palette.background.paper,        
        transition: 'all 0.2s ease',
    },
    nodeHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        gap: theme.spacing(1.5),
        paddingLeft: "4px"
    },
    nodeTitle: {
        padding: theme.spacing(1),
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    nodeDescription: {
        padding: theme.spacing(1),  
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    icon: {
        width: "16px",
        height: "16px",
        marginLeft: "8px",
        marginTop: "4px",
        //color: theme.palette.icons
        color: theme.palette.text.primary,
    },
    handle: {
        width: "10px",
        height: "10px",
        backgroundColor: theme.palette.type === "dark" ? "#2a2b2d" : "#dbd9d9",
        //border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.25)" : "1px solid rgba(255, 255, 255, 0.25)",
        border: "1px solid rgba(255, 255, 255)",
        borderRadius: "6px"
    },
}));

export default memo(({ data, isConnectable }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.nodeContainer}>
            <div className={classes.nodeHeader}>
                <RocketLaunch className={classes.icon} />
                <div className={classes.nodeTitle}>Iniciar</div>
            </div>
            
            <div className={classes.nodeDescription}>
                Começar seu fluxo!
            </div>
            
            <Handle
                type="source"
                position="right"
                id="a"
                className={classes.handle}
                isConnectable={isConnectable}
            />
        </div>
    );
});
