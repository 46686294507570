import React, { useState, useEffect, useRef, useMemo } from "react";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Compressor from "compressorjs";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MessageVariablesPicker from "../MessageVariablesPicker";
//import emojiPt from "@emoji-mart/data/i18n/pt.json";
// import data from "@emoji-mart/data";
// import Picker from "@emoji-mart/react";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

import {
    Grid,
    Paper,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
    Box,
    Slider
} from "@mui/material";
import {
    AccessTime,
    Delete,
    Image,
    Message,
    MicNone,
    Videocam,
    Description,
    FolderZip,
    WebhookOutlined,
    CallSplit
} from "@mui/icons-material";
import QueueIcon from "@mui/icons-material/Queue";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PhoneIcon from "@mui/icons-material/Phone";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import MenuIcon from "@mui/icons-material/Menu";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import SaveSharpIcon from "@mui/icons-material/Save";
import { green, orange } from "@mui/material/colors";
import { AddCircle } from "@mui/icons-material";
import { ImportExport } from "@mui/icons-material";
import { capitalize } from "../../utils/capitalize";
import { Chip } from "@mui/material";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    deleteButtonContainer: {
        position: "absolute",
        right: 6
    },
    deleteButton: {
        position: "absolute",
        right: "0px",
        top: "-15px"
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: theme.spacing(0.5)
    },
    titleIcons: {
        width: "20px",
        height: "20px"
    },
    stackFormContainer: {
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1)
    },
    btnWrapper: {
        position: "relative"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    mediaContainer: {
        border: "1px ",
        backgroundColor: theme.palette.background.default,
        borderRadius: "7px",
        padding: "16px",
        position: "relative",
        width: "100%",
        marginBottom: "16px"
    },
    formCointainerDialog: {
        "& > div > div[role='dialog']": {}
    },
    bottomActionsContainer: {        
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    bottomActions: {
        position: "sticky",
        bottom: 0,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),       
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: theme.spacing(2),
        zIndex: 1000
    },
    emojiContainer: {
        height: 0,
        overflow: "hidden",
        transition: "height 0.3s ease-in-out",
        backgroundColor: theme.palette.background.default,
        width: "100%",
        "&.expanded": {
            height: "385px"
            //padding: theme.spacing(1)
        }
    },
    mediaButtons: {
        display: "flex",
        flexWrap: "wrap",
        gap: theme.spacing(1),
        justifyContent: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    actionButtons: {
        display: "flex",
        width: "100%",
        gap: theme.spacing(1),
        alignSelf: "flex-end",
        marginTop: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.divider}`
    }
}));

const FlowBuilderSingleBlockModal = ({ open, onSave, onUpdate, data, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [activeModal, setActiveModal] = useState(false);
    const [rule, setRule] = useState();
    const [medias, setMedias] = useState([]);
    const [textDig, setTextDig] = useState();
    const [elements, setElements] = useState([]);
    const [elementsSeq, setElementsSeq] = useState([]);
    const [elementsSeqEdit, setElementsSeqEdit] = useState([]);
    const [elementsEdit, setElementsEdit] = useState([]);

    const [loading, setLoading] = useState(false);
    const [previewImg, setPreviewImg] = useState([]);
    const [previewAudios, setPreviewAudios] = useState([]);
    const [previewVideos, setPreviewVideos] = useState([]);
    const [previewDocument, setPreviewDocument] = useState([]);
    const [previewFileZip, setPreviewFileZip] = useState([]);
    const [arrayOption, setArrayOption] = useState([]);

    //const [showEmoji, setShowEmoji] = useState(false);
    //const [currentTextField, setCurrentTextField] = useState(null);

    const [numberMessages, setNumberMessages] = useState(0);
    const [numberMessagesLast, setNumberMessagesLast] = useState(0);
    const [numberImg, setNumberImg] = useState(0);
    const [numberImgLast, setNumberImgLast] = useState(0);
    const [numberAudio, setNumberAudio] = useState(0);
    const [numberAudioLast, setNumberAudioLast] = useState(0);
    const [numberVideo, setNumberVideo] = useState(0);
    const [numberVideoLast, setNumberVideoLast] = useState(0);
    const [numberDocument, setNumberDocument] = useState(0);
    const [numberDocumentLast, setNumberDocumentLast] = useState(0);
    const [numberFileZip, setNumberFileZip] = useState(0);
    const [numberFileZipLast, setNumberFileZipLast] = useState(0);
    const [numberMenu, setNumberMenu] = useState(0);
    const [numberMenuLast, setNumberMenuLast] = useState(0);
    const [numberInterval, setNumberInterval] = useState(0);
    const [numberIntervalLast, setNumberIntervalLast] = useState(0);
    const [numberRandomizer, setNumberRandomizer] = useState(0);
    const [numberRandomizerLast, setNumberRandomizerLast] = useState(0);

    const [businessHoursOptions, setBusinessHoursOptions] = useState([]);
    const [numberBusinessHours, setNumberBusinessHours] = useState(0);
    const [numberBusinessHoursLast, setNumberBusinessHoursLast] = useState(0);
    const [numberConnection, setNumberConnection] = useState(0);
    const [numberConnectionLast, setNumberConnectionLast] = useState(0);
    const [numberCondition, setNumberCondition] = useState(0);
    const [numberConditionLast, setNumberConditionLast] = useState(0);
    const [numberQueue, setNumberQueue] = useState(0);
    const [numberQueueLast, setNumberQueueLast] = useState(0);
    const [numberTags, setNumberTags] = useState(0);
    const [numberTagsLast, setNumberTagsLast] = useState(0);
    const [numberAddTags, setNumberAddTags] = useState(0);
    const [numberAddTagsLast, setNumberAddTagsLast] = useState(0);
    const [numberRemoveTags, setNumberRemoveTags] = useState(0);
    const [numberRemoveTagsLast, setNumberRemoveTagsLast] = useState(0);
    const [numberWebhook, setNumberWebhook] = useState(0);
    const [numberWebhookLast, setNumberWebhookLast] = useState(0);

    const [queueSelections, setQueueSelections] = useState({});
    const [connectionSelections, setConnectionSelections] = useState({});
    const [tagSelections, setTagSelections] = useState({});

    const [, setLabels] = useState({
        title: "Adicionar Funil de conteúdo ao fluxo"
    });

    const handleElements = newNameFiles => {
        let elementsSequence = [];
        console.log("Estado inicial dos elementos:", elementsSequence);

        const newArrMessage = elementsSeq.filter(item => item.includes("message"));
        const newArrImg = elementsSeq.filter(item => item.includes("img"));
        const newArrAudio = elementsSeq.filter(item => item.includes("audio"));
        const newArrVideo = elementsSeq.filter(item => item.includes("video"));
        const newArrDocument = elementsSeq.filter(item => item.includes("document"));
        const newArrFileZip = elementsSeq.filter(item => item.includes("fileZip"));

        const newArrMenu = elementsSeq.filter(item => item.includes("menu"));
        const newArrInterval = elementsSeq.filter(item => item.includes("interval"));
        const newArrRandomizer = elementsSeq.filter(item => item.includes("randomizer"));
        const newArrBusinessHours = elementsSeq.filter(item => item.includes("businessHours"));
        const newArrConnection = elementsSeq.filter(item => item.includes("connection"));
        const newArrCondition = elementsSeq.filter(item => item.includes("condition"));
        const newArrQueue = elementsSeq.filter(item => item.includes("queue"));
        const newArrTags = elementsSeq.filter(item => item.includes("tags"));
        const newArrAddTags = elementsSeq.filter(item => item.includes("addTags"));
        const newArrRemoveTags = elementsSeq.filter(item => item.includes("removeTags"));
        const newArrWebhook = elementsSeq.filter(item => item.includes("webhook"));

        // Mensagens
        for (let i = 0; i < numberMessages; i++) {
            const value = document
                .querySelector(`.${newArrMessage[i]}`)
                .querySelector(".MuiInputBase-input").value;
            if (!value) {
                toast.error("Campos de mensagem vazio!");
                setLoading(false);
                throw "";
            }
            elementsSequence.push({
                type: "message",
                value: value,
                number: newArrMessage[i]
            });
            console.log("text");
        }
        // Imagens
        for (let i = 0; i < numberImg; i++) {
            const onlyImg =
                newNameFiles !== null &&
                newNameFiles.filter(
                    file => file.includes("png") || file.includes("jpg") || file.includes("jpeg")
                );
            const onlyImgNameOriginal = medias.filter(
                file =>
                    file.name.includes("png") ||
                    file.name.includes("jpg") ||
                    file.name.includes("jpeg")
            );
            if (elementsSeqEdit.includes(newArrImg[i])) {
                const itemSelectedEdit = elementsEdit.filter(
                    item => item.number === newArrImg[i]
                )[0];
                elementsSequence.push({
                    type: "img",
                    value: itemSelectedEdit.value,
                    original: itemSelectedEdit.original,
                    number: itemSelectedEdit.number
                });
            } else {
                let indexElem = 0;
                if (elementsSeqEdit.filter(item => item.includes("img")).length > 0) {
                    indexElem = elementsSeqEdit.filter(item => item.includes("img")).length - i;
                } else {
                    indexElem = i;
                }
                elementsSequence.push({
                    type: "img",
                    value: onlyImg[indexElem],
                    original: onlyImgNameOriginal[indexElem].name,
                    number: newArrImg[i]
                });
            }
        }
        // Audios
        for (let i = 0; i < numberAudio; i++) {
            const onlyAudio =
                newNameFiles !== null &&
                newNameFiles.filter(
                    file =>
                        file.includes("mp3") ||
                        file.includes("ogg") ||
                        file.includes("mpeg") ||
                        file.includes("opus")
                );
            const onlyAudioNameOriginal = medias.filter(
                file =>
                    file.name.includes("mp3") ||
                    file.name.includes("ogg") ||
                    file.name.includes("mpeg") ||
                    file.name.includes("opus")
            );

            if (elementsSeqEdit.includes(newArrAudio[i])) {
                const itemSelectedEdit = elementsEdit.filter(
                    item => item.number === newArrAudio[i]
                )[0];
                elementsSequence.push({
                    type: "audio",
                    value: itemSelectedEdit.value,
                    original: itemSelectedEdit.original,
                    number: itemSelectedEdit.number,
                    record: document
                        .querySelector(`.check${newArrAudio[i]}`)
                        .querySelector(".PrivateSwitchBase-input").checked
                });
            } else {
                let indexElem = 0;
                if (elementsSeqEdit.filter(item => item.includes("audio")).length > 0) {
                    indexElem = elementsSeqEdit.filter(item => item.includes("audio")).length - i;
                } else {
                    indexElem = i;
                }
                elementsSequence.push({
                    type: "audio",
                    value: onlyAudio[indexElem],
                    original: onlyAudioNameOriginal[indexElem].name,
                    number: newArrAudio[i],
                    record: document
                        .querySelector(`.check${newArrAudio[i]}`)
                        .querySelector(".PrivateSwitchBase-input").checked
                });
            }
        }
        // Videos
        for (let i = 0; i < numberVideo; i++) {
            const onlyVideo =
                newNameFiles !== null &&
                newNameFiles.filter(file => file.includes("mp4") || file.includes("avi"));
            const onlyVideoNameOriginal = medias.filter(
                file => file.name.includes("mp4") || file.name.includes("avi")
            );
            if (elementsSeqEdit.includes(newArrVideo[i])) {
                const itemSelectedEdit = elementsEdit.filter(
                    item => item.number === newArrVideo[i]
                )[0];
                elementsSequence.push({
                    type: "video",
                    value: itemSelectedEdit.value,
                    original: itemSelectedEdit.original,
                    number: itemSelectedEdit.number
                });
            } else {
                let indexElem = 0;
                if (elementsSeqEdit.filter(item => item.includes("video")).length > 0) {
                    indexElem = elementsSeqEdit.filter(item => item.includes("video")).length - i;
                } else {
                    indexElem = i;
                }
                elementsSequence.push({
                    type: "video",
                    value: onlyVideo[indexElem],
                    original: onlyVideoNameOriginal[indexElem].name,
                    number: newArrVideo[i]
                });
            }
        }
        // Documentos
        for (let i = 0; i < numberDocument; i++) {
            const onlyDoc =
                newNameFiles !== null &&
                newNameFiles.filter(
                    file =>
                        file.includes("pdf") ||
                        file.includes("csv") ||
                        file.includes("txt") ||
                        file.includes("doc") ||
                        file.includes("docx") ||
                        file.includes("xls") ||
                        file.includes("xlsx") ||
                        file.includes("rtf") ||
                        file.includes("odt") ||
                        file.includes("odp") ||
                        file.includes("ods") ||
                        file.includes("ppt") ||
                        file.includes("pptx") ||
                        file.includes("xml") ||
                        file.includes("json") ||
                        file.includes("html") ||
                        file.includes("htm") ||
                        file.includes("xhtml") ||
                        file.includes("css") ||
                        file.includes("py") ||
                        file.includes("c") ||
                        file.includes("cpp") ||
                        file.includes("csharp") ||
                        file.includes("php") ||
                        file.includes("js") ||
                        file.includes("jsx") ||
                        file.includes("ts") ||
                        file.includes("tsx")
                );

            const onlyDocNameOriginal = medias.filter(
                file =>
                    file.name.includes("pdf") ||
                    file.name.includes("csv") ||
                    file.name.includes("txt") ||
                    file.name.includes("doc") ||
                    file.name.includes("docx") ||
                    file.name.includes("xls") ||
                    file.name.includes("xlsx") ||
                    file.name.includes("rtf") ||
                    file.name.includes("odt") ||
                    file.name.includes("odp") ||
                    file.name.includes("ods") ||
                    file.name.includes("ppt") ||
                    file.name.includes("pptx") ||
                    file.name.includes("xml") ||
                    file.name.includes("json") ||
                    file.name.includes("html") ||
                    file.name.includes("htm") ||
                    file.name.includes("xhtml") ||
                    file.name.includes("css") ||
                    file.name.includes("py") ||
                    file.name.includes("c") ||
                    file.name.includes("cpp") ||
                    file.name.includes("csharp") ||
                    file.name.includes("php") ||
                    file.name.includes("js") ||
                    file.name.includes("jsx") ||
                    file.name.includes("ts") ||
                    file.name.includes("tsx")
            );

            if (elementsSeqEdit.includes(newArrDocument[i])) {
                const itemSelectedEdit = elementsEdit.filter(
                    item => item.number === newArrDocument[i]
                )[0];
                elementsSequence.push({
                    type: "document",
                    value: itemSelectedEdit.value,
                    original: itemSelectedEdit.original,
                    number: itemSelectedEdit.number
                });
            } else {
                let indexElem = 0;
                if (elementsSeqEdit.filter(item => item.includes("document")).length > 0) {
                    indexElem =
                        elementsSeqEdit.filter(item => item.includes("document")).length - i;
                } else {
                    indexElem = i;
                }
                elementsSequence.push({
                    type: "document",
                    value: onlyDoc[indexElem],
                    original: onlyDocNameOriginal[indexElem].name,
                    number: newArrDocument[i]
                });
            }
        }
        // Compactados
        for (let i = 0; i < numberFileZip; i++) {
            const onlyZip =
                newNameFiles !== null &&
                newNameFiles.filter(
                    file => file.includes("zip") || file.includes("rar") || file.includes("7z")
                );

            const onlyZipNameOriginal = medias.filter(
                file =>
                    file.name.includes("zip") ||
                    file.name.includes("rar") ||
                    file.name.includes("7z")
            );

            if (elementsSeqEdit.includes(newArrFileZip[i])) {
                const itemSelectedEdit = elementsEdit.filter(
                    item => item.number === newArrFileZip[i]
                )[0];
                elementsSequence.push({
                    type: "fileZip",
                    value: itemSelectedEdit.value,
                    original: itemSelectedEdit.original,
                    number: itemSelectedEdit.number
                });
            } else {
                let indexElem = 0;
                if (elementsSeqEdit.filter(item => item.includes("fileZip")).length > 0) {
                    indexElem = elementsSeqEdit.filter(item => item.includes("fileZip")).length - i;
                } else {
                    indexElem = i;
                }
                elementsSequence.push({
                    type: "fileZip",
                    value: onlyZip[indexElem],
                    original: onlyZipNameOriginal[indexElem].name,
                    number: newArrFileZip[i]
                });
            }
        }
        // Menu
        for (let i = 0; i < numberMenu; i++) {
            const menuNumber = `menu${i}`;
            console.log("Processando menu número:", menuNumber);

            // Pega a mensagem principal do menu
            const message =
                document.querySelector(`.menuMessage${i}`)?.querySelector(".MuiInputBase-input")
                    ?.value || "";

            // Pega o container de opções
            const optionsContainer = document.querySelector(`.menuOptions${i}`);
            let arrayOption = [];

            if (optionsContainer) {
                // Pega todos os inputs de opção
                const optionInputs = optionsContainer.querySelectorAll(".MuiInputBase-input");
                arrayOption = Array.from(optionInputs).map((input, index) => ({
                    number: index + 1,
                    value: input.value || ""
                }));
            }

            // Verifica se tem pelo menos uma opção
            if (arrayOption.length === 0) {
                toast.error("Menu precisa ter pelo menos uma opção!");
                setLoading(false);
                throw "";
            }

            elementsSequence.push({
                type: "menu",
                value: {
                    message: message,
                    arrayOption: arrayOption
                },
                number: menuNumber
            });
        }
        // Daley
        for (let i = 0; i < numberInterval; i++) {
            const value = document
                .querySelector(`.${newArrInterval[i]}`)
                .querySelector(".MuiInputBase-input").value;
            if (parseInt(value) === 0 || parseInt(value) > 120) {
                toast.error("Intervalo não pode ser 0 ou maior que 120!");
                setLoading(false);
                throw "";
            }
            elementsSequence.push({
                type: "interval",
                value: value,
                number: newArrInterval[i]
            });
            console.log("int");
        }
        // Randomizer
        for (let i = 0; i < numberRandomizer; i++) {
            if (elementsSeqEdit.includes(newArrRandomizer[i])) {
                const itemSelectedEdit = elementsEdit.filter(
                    item => item.number === newArrRandomizer[i]
                )[0];
                elementsSequence.push({
                    type: "randomizer",
                    value: itemSelectedEdit.value,
                    number: itemSelectedEdit.number
                });
            } else {
                // Busca o elemento de forma segura
                const randomizerElement = document.querySelector(
                    `.randomizerPercent${newArrRandomizer[i]}`
                );

                // Pega o valor com validação
                const percent = randomizerElement?.getAttribute("aria-valuenow")
                    ? parseInt(randomizerElement.getAttribute("aria-valuenow"))
                    : 0; // ou outro valor padrão que faça sentido

                // Validação opcional
                if (percent === null || isNaN(percent)) {
                    toast.error("Porcentagem do randomizer inválida!");
                    setLoading(false);
                    throw "";
                }

                elementsSequence.push({
                    type: "randomizer",
                    value: {
                        percent: percent
                    },
                    number: newArrRandomizer[i]
                });
            }
        }
        // Expediente
        for (let i = 0; i < numberBusinessHours; i++) {
            if (elementsSeqEdit.includes(newArrBusinessHours[i])) {
                const itemSelectedEdit = elementsEdit.filter(
                    item => item.number === newArrBusinessHours[i]
                )[0];
                elementsSequence.push({
                    type: "businessHours",
                    value: itemSelectedEdit.value,
                    number: itemSelectedEdit.number
                });
            } else {
                const type = document
                    .querySelector(`.businessHoursType${newArrBusinessHours[i]}`)
                    .querySelector("input").value;
                const targetId = document
                    .querySelector(`.businessHoursTarget${newArrBusinessHours[i]}`)
                    .querySelector("input").value;

                const targetName =
                    businessHoursOptions.find(opt => opt.id === targetId)?.name || "";

                elementsSequence.push({
                    type: "businessHours",
                    value: {
                        type,
                        targetId,
                        targetName
                    },
                    number: newArrBusinessHours[i]
                });
            }
        }
        // Condição
        for (let i = 0; i < numberCondition; i++) {
            if (elementsSeqEdit.includes(newArrCondition[i])) {
                const itemSelectedEdit = elementsEdit.filter(
                    item => item.number === newArrCondition[i]
                )[0];
                elementsSequence.push({
                    type: "condition",
                    value: itemSelectedEdit.value,
                    number: itemSelectedEdit.number
                });
            } else {
                const key = document.querySelector(`.conditionKey${newArrCondition[i]}`).value;
                const condition = document.querySelector(
                    `.conditionRule${newArrCondition[i]}`
                ).value;
                const value = document.querySelector(`.conditionValue${newArrCondition[i]}`).value;

                elementsSequence.push({
                    type: "condition",
                    value: {
                        key,
                        condition,
                        value
                    },
                    number: newArrCondition[i]
                });
            }
        }
        // Filas
        for (let i = 0; i < numberQueue; i++) {
            const queueKey = newArrQueue[i];
            console.log("Processando fila:", queueKey);

            if (elementsSeqEdit.includes(queueKey)) {
                const itemSelectedEdit = elementsEdit.find(item => item.number === queueKey);
                elementsSequence.push({
                    type: "queue",
                    value: itemSelectedEdit.value,
                    number: queueKey
                });
            } else {
                // Aqui usamos a chave diretamente
                const selectedValue = queueSelections[queueKey];
                console.log("Valor selecionado:", selectedValue, "Para fila:", queueKey);

                if (!selectedValue) {
                    console.log("Estado atual das filas:", queueSelections);
                    console.log("Filas disponíveis:", queues);
                    toast.error(`Selecione uma fila para o item ${i + 1}`);
                    setLoading(false);
                    throw new Error("Fila não selecionada");
                }

                const queue = queues.find(q => q.id === selectedValue);
                console.log("Fila encontrada:", queue);

                elementsSequence.push({
                    type: "queue",
                    value: {
                        id: queue.id,
                        name: queue.name
                    },
                    number: queueKey
                });
            }
        }
        // Conexões
        for (let i = 0; i < numberConnection; i++) {
            const connectionKey = newArrConnection[i];
            console.log("Processando conexão:", connectionKey);

            if (elementsSeqEdit.includes(connectionKey)) {
                const itemSelectedEdit = elementsEdit.find(item => item.number === connectionKey);
                elementsSequence.push({
                    type: "connection",
                    value: itemSelectedEdit.value,
                    number: connectionKey
                });
            } else {
                const selectedValue = connectionSelections[connectionKey];
                console.log("Valor selecionado:", selectedValue, "Para conexão:", connectionKey);

                if (!selectedValue) {
                    console.log("Estado atual das conexões:", connectionSelections);
                    console.log("Conexões disponíveis:", connections);
                    toast.error(`Selecione uma conexão para o item ${i + 1}`);
                    setLoading(false);
                    throw new Error("Conexão não selecionada");
                }

                const connection = connections.find(c => c.id === selectedValue);
                console.log("Conexão encontrada:", connection);

                elementsSequence.push({
                    type: "connection",
                    value: {
                        id: connection.id,
                        name: connection.name
                    },
                    number: connectionKey
                });
            }
        }
        // Condição de Tags
        for (let i = 0; i < numberTags; i++) {
            if (elementsSeqEdit.includes(newArrTags[i])) {
                const itemSelectedEdit = elementsEdit.filter(
                    item => item.number === newArrTags[i]
                )[0];
                elementsSequence.push({
                    type: "tags",
                    value: itemSelectedEdit.value,
                    number: itemSelectedEdit.number
                });
            } else {
                // Para Material-UI Select
                const conditionSelectElement = document.querySelector(
                    `[class*="tagsCondition${newArrTags[i]}"] input[type="hidden"]`
                );
                const tagsSelectElement = document.querySelector(
                    `[class*="tagsSelect${newArrTags[i]}"] input[type="hidden"]`
                );

                console.log("Condition Select Element:", conditionSelectElement);
                console.log("Tags Select Element:", tagsSelectElement);

                // No Material-UI, pegamos o value diretamente
                const condition = conditionSelectElement?.value;
                const selectedTagIds = tagsSelectElement?.value?.split(",") || [];

                console.log("Selected Condition:", condition);
                console.log("Selected Tag IDs:", selectedTagIds);

                if (!condition) {
                    toast.error(`Selecione uma condição para o item ${i + 1}`);
                    setLoading(false);
                    throw new Error("Condição não selecionada");
                }

                if (selectedTagIds.length === 0) {
                    toast.error(`Selecione pelo menos uma tag para o item ${i + 1}`);
                    setLoading(false);
                    throw new Error("Tags não selecionadas");
                }

                const selectedTags = selectedTagIds.map(id => {
                    const tag = availableTags.find(t => t.id === id);
                    if (!tag) {
                        console.error("Tag não encontrada:", id);
                        toast.error(`Tag selecionada não encontrada`);
                        setLoading(false);
                        throw new Error("Tag não encontrada");
                    }
                    return {
                        id: tag.id,
                        name: tag.name,
                        color: tag.color,
                        type: tag.type
                    };
                });

                console.log("Selected Tags:", selectedTags);

                elementsSequence.push({
                    type: "tags",
                    value: {
                        condition: condition,
                        tags: selectedTags
                    },
                    number: newArrTags[i]
                });
            }
        }

        // AddTags
        const processAddTags = tagKey => {
            const selection = tagSelections[tagKey];

            console.log("Estado atual antes de processar addTags:", {
                elementsSequence,
                tagKey,
                selection
            });

            if (!selection?.tagIds?.length) {
                throw new Error("Selecione pelo menos uma tag para adicionar");
            }

            const selectedTags = selection.tagIds
                .map(id => {
                    const tag = availableTags.find(t => t.id === id);
                    if (!tag?.id) {
                        console.error(`Tag não encontrada para o ID: ${id}`);
                        return null;
                    }
                    return {
                        id: tag.id,
                        name: tag.name,
                        color: tag.color,
                        type: tag.type
                    };
                })
                .filter(Boolean);

            if (selectedTags.length === 0) {
                throw new Error("Nenhuma tag válida selecionada");
            }

            const element = {
                type: "addTags",
                value: {
                    tags: selectedTags,
                    nodeId: selection.nodeId
                },
                number: tagKey
            };

            console.log("Elemento processado addTags:", element);
            return element;
        };
        // Processando AddTags
        for (let i = 0; i < numberAddTags; i++) {
            const tagKey = newArrAddTags[i];
            try {
                const element = processAddTags(tagKey);
                const existingIndex = elementsSequence.findIndex(el => el.number === tagKey);

                if (existingIndex >= 0) {
                    elementsSequence[existingIndex] = element;
                } else {
                    elementsSequence.push(element);
                }

                console.log("Estado após processamento addTags:", elementsSequence);
            } catch (error) {
                console.error("Erro ao processar addTags:", error);
                toast.error(error.message);
                setLoading(false);
                throw error;
            }
        }
        // Remove Tags
        const processRemoveTags = tagKey => {
            const selection = tagSelections[tagKey];

            console.log("Estado atual antes de processar removeTags:", {
                elementsSequence,
                tagKey,
                selection
            });

            if (!selection?.tagIds?.length) {
                throw new Error("Selecione pelo menos uma tag para remover");
            }

            const selectedTags = selection.tagIds
                .map(id => {
                    const tag = availableTags.find(t => t.id === id);
                    if (!tag?.id) {
                        console.error(`Tag não encontrada para o ID: ${id}`);
                        return null;
                    }
                    return {
                        id: tag.id,
                        name: tag.name,
                        color: tag.color,
                        type: tag.type
                    };
                })
                .filter(Boolean);

            if (selectedTags.length === 0) {
                throw new Error("Nenhuma tag válida selecionada para remover");
            }

            const element = {
                type: "removeTags",
                value: {
                    tags: selectedTags,
                    nodeId: selection.nodeId
                },
                number: tagKey
            };

            console.log("Elemento processado removeTags:", element);
            return element;
        };
        // Processando RemoveTags
        for (let i = 0; i < numberRemoveTags; i++) {
            const tagKey = newArrRemoveTags[i];
            try {
                const element = processRemoveTags(tagKey);
                const existingIndex = elementsSequence.findIndex(el => el.number === tagKey);

                if (existingIndex >= 0) {
                    elementsSequence[existingIndex] = element;
                } else {
                    elementsSequence.push(element);
                }

                console.log("Estado após processamento removeTags:", elementsSequence);
            } catch (error) {
                console.error("Erro ao processar removeTags:", error);
                toast.error(error.message);
                setLoading(false);
                throw error;
            }
        }
        // Webhook
        for (let i = 0; i < numberWebhook; i++) {
            if (elementsSeqEdit.includes(newArrWebhook[i])) {
                const itemSelectedEdit = elementsEdit.filter(
                    item => item.number === newArrWebhook[i]
                )[0];
                elementsSequence.push({
                    type: "webhook",
                    value: itemSelectedEdit.value,
                    number: itemSelectedEdit.number
                });
            } else {
                const name = document.querySelector(`.webhookName${newArrWebhook[i]}`).value;
                const url = document.querySelector(`.webhookUrl${newArrWebhook[i]}`).value;
                const method = document.querySelector(`.webhookMethod${newArrWebhook[i]}`).value;
                const headers = document.querySelector(`.webhookHeaders${newArrWebhook[i]}`).value;
                const body = document.querySelector(`.webhookBody${newArrWebhook[i]}`).value;

                elementsSequence.push({
                    type: "webhook",
                    value: {
                        name,
                        url,
                        method,
                        headers,
                        body
                    },
                    number: newArrWebhook[i]
                });
            }
        }

        console.log(elementsSequence);

        return elementsSequence;
    };

    const deleteElementsTypeOne = (id, type) => {
        if (type === "message") {
            setNumberMessages(old => old - 1);
            setElementsSeq(old => old.filter(item => item !== `message${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `message${id}`));
            document.querySelector(`.stackMessage${id}`).remove();
        }
        if (type === "img") {
            setNumberImg(old => old - 1);
            setPreviewImg(old => {
                setMedias(oldMedia => {
                    try {
                        return oldMedia.filter(
                            mediaItem =>
                                mediaItem.name !== old.filter(item => item.number === id)[0].name
                        );
                    } catch (e) {
                        return oldMedia;
                    }
                });
                return old.filter(item => item.number !== id);
            });
            setElementsSeq(old => old.filter(item => item !== `img${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `img${id}`));
            document.querySelector(`.stackImg${id}`).remove();
        }
        if (type === "audio") {
            setNumberAudio(old => old - 1);
            setPreviewAudios(old => {
                setMedias(oldMedia => {
                    try {
                        return oldMedia.filter(
                            mediaItem =>
                                mediaItem.name !== old.filter(item => item.number === id)[0].name
                        );
                    } catch (e) {
                        return oldMedia;
                    }
                });
                return old.filter(item => item.number !== id);
            });
            setElementsSeq(old => old.filter(item => item !== `audio${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `audio${id}`));
            document.querySelector(`.stackAudio${id}`).remove();
        }
        if (type === "video") {
            setNumberVideo(old => old - 1);
            setPreviewVideos(old => {
                setMedias(oldMedia => {
                    try {
                        return oldMedia.filter(
                            mediaItem =>
                                mediaItem.name !== old.filter(item => item.number === id)[0].name
                        );
                    } catch (e) {
                        return oldMedia;
                    }
                });
                return old.filter(item => item.number !== id);
            });
            setElementsSeq(old => old.filter(item => item !== `video${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `video${id}`));
            document.querySelector(`.stackVideo${id}`).remove();
        }
        if (type === "document") {
            setNumberDocument(old => old - 1);
            setPreviewDocument(old => {
                setMedias(oldMedia => {
                    try {
                        return oldMedia.filter(
                            mediaItem =>
                                mediaItem.name !== old.filter(item => item.number === id)[0].name
                        );
                    } catch (e) {
                        return oldMedia;
                    }
                });
                return old.filter(item => item.number !== id);
            });
            setElementsSeq(old => old.filter(item => item !== `document${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `document${id}`));
            document.querySelector(`.stackDocument${id}`).remove();
        }
        if (type === "fileZip") {
            setNumberFileZip(old => old - 1);
            setPreviewFileZip(old => {
                setMedias(oldMedia => {
                    try {
                        return oldMedia.filter(
                            mediaItem =>
                                mediaItem.name !== old.filter(item => item.number === id)[0].name
                        );
                    } catch (e) {
                        return oldMedia;
                    }
                });
                return old.filter(item => item.number !== id);
            });
            setElementsSeq(old => old.filter(item => item !== `fileZip${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `fileZip${id}`));
            document.querySelector(`.stackFileZip${id}`).remove();
        }
        if (type === "menu") {
            setNumberMenu(old => old - 1);
            setElementsSeq(old => old.filter(item => item !== `menu${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `menu${id}`));
            document.querySelector(`.stackMenu${id}`).remove();
        }
        if (type === "interval") {
            setNumberInterval(old => old - 1);
            setElementsSeq(old => old.filter(item => item !== `interval${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `interval${id}`));
            document.querySelector(`.stackInterval${id}`).remove();
        }
        if (type === "randomizer") {
            setNumberRandomizer(old => old - 1);
            setElementsSeq(old => old.filter(item => item !== `randomizer${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `randomizer${id}`));
            document.querySelector(`.stackRandomizer${id}`).remove();
        }
        if (type === "condition") {
            setNumberCondition(old => old - 1);
            setElementsSeq(old => old.filter(item => item !== `condition${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `condition${id}`));
            document.querySelector(`.stackCondition${id}`).remove();
        }
        if (type === "queue") {
            setNumberQueue(old => old - 1);
            setElementsSeq(old => old.filter(item => item !== `queue${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `queue${id}`));
            document.querySelector(`.stackQueue${id}`).remove();
        }
        if (type === "connection") {
            setNumberConnection(old => old - 1);
            setElementsSeq(old => old.filter(item => item !== `connection${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `connection${id}`));
            document.querySelector(`.stackConnection${id}`).remove();
        }
        if (type === "tags") {
            setNumberTags(old => old - 1);
            setElementsSeq(old => old.filter(item => item !== `tags${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `tags${id}`));
            document.querySelector(`.stackTags${id}`).remove();
        }
        if (type === "addTags") {
            setNumberAddTags(old => old - 1);
            setElementsSeq(old => old.filter(item => item !== `addTags${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `addTags${id}`));
            document.querySelector(`.stackAddTags${id}`).remove();
        }
        if (type === "removeTags") {
            setNumberRemoveTags(old => old - 1);
            setElementsSeq(old => old.filter(item => item !== `removeTags${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `removeTags${id}`));
            document.querySelector(`.stackRemoveTags${id}`).remove();
        }
        if (type === "businessHours") {
            setNumberBusinessHours(old => old - 1);
            setElementsSeq(old => old.filter(item => item !== `businessHours${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `businessHours${id}`));
            document.querySelector(`.stackBusinessHours${id}`).remove();
        }
        if (type === "webhook") {
            setNumberWebhook(old => old - 1);
            setElementsSeq(old => old.filter(item => item !== `webhook${id}`));
            setElementsSeqEdit(old => old.filter(item => item !== `webhook${id}`));
            document.querySelector(`.stackWebhook${id}`).remove();
        }
    };

    const moveElementDown = id => {
        setElementsSeq(old => {
            const array = old;
            const index = array.indexOf(id);
            moveItemParaFrente(index);
            console.log("id", id);
            if (index !== -1 && index < array.length - 1) {
                // Verifica se o elemento foi encontrado no array e não está na última posição
                const novoArray = [...array]; // Cria uma cópia do array original
                const elementoMovido = novoArray.splice(index, 1)[0];
                novoArray.splice(index + 1, 0, elementoMovido);
                return novoArray;
            }
            return array;
        });
    };

    const moveElementUp = id => {
        setElementsSeq(old => {
            const array = old;
            const index = array.indexOf(id);
            moveItemParaTras(index);

            if (index !== -1 && index > 0) {
                // Verifica se o elemento foi encontrado no array e não está na primeira posição
                const novoArray = [...array]; // Cria uma cópia do array original
                const elementoMovido = novoArray.splice(index, 1)[0];
                novoArray.splice(index - 1, 0, elementoMovido);
                return novoArray;
            }
            return array;
        });
    };

    function moveItemParaFrente(posicao) {
        setElements(old => {
            const array = old;

            if (posicao >= 0 && posicao < array.length - 1) {
                const novoArray = [...array]; // Cria uma cópia do array original
                const elementoMovido = novoArray.splice(posicao, 1)[0];
                novoArray.splice(posicao + 1, 0, elementoMovido);
                return novoArray;
            }

            return array; // Retorna o array original se a movimentação não for possível
        });
    }

    function moveItemParaTras(posicao) {
        setElements(old => {
            const array = old;
            if (posicao > 0 && posicao < array.length) {
                const novoArray = [...array]; // Cria uma cópia do array original
                const elementoMovido = novoArray.splice(posicao, 1)[0];
                novoArray.splice(posicao - 1, 0, elementoMovido);
                return novoArray;
            }

            return array; // Retorna o array original se a movimentação não for possível
        });
    }

    const handleChangeMediasImg = (e, number) => {
        if (!e.target.files) {
            return;
        }

        if (e.target.files[0].size > 2000000) {
            toast.error("Arquivo é muito grande! 2MB máximo");
            return;
        }
        const imgBlob = URL.createObjectURL(e.target.files[0]);
        setPreviewImg(old => [
            ...old,
            {
                number: number,
                url: imgBlob,
                name: e.target.files[0].name
            }
        ]);
        const selectedMedias = Array.from(e.target.files);
        setMedias(old => [...old, selectedMedias[0]]);

        document.querySelector(`.img${number}`).src = imgBlob;
        document.querySelector(`.btnImg${number}`).remove();
    };

    const handleChangeAudios = (e, number) => {
        if (!e.target.files) {
            return;
        }

        if (e.target.files[0].size > 5000000) {
            toast.error("Arquivo é muito grande! 5MB máximo");
            return;
        }

        const audioBlob = URL.createObjectURL(e.target.files[0]);
        setPreviewAudios(old => [
            ...old,
            {
                number: number,
                url: audioBlob,
                name: e.target.files[0].name
            }
        ]);

        const selectedMedias = Array.from(e.target.files);
        setMedias(old => [...old, selectedMedias[0]]);

        document.querySelector(`.audio${number}`).innerHTML = `<audio controls="controls">
    <source src="${audioBlob}" type="audio/mp3" />
    seu navegador não suporta HTML5
  </audio>`;
        document.querySelector(`.btnAudio${number}`).remove();
    };

    const handleChangeVideos = (e, number) => {
        if (!e.target.files) {
            return;
        }

        if (e.target.files[0].size > 20000000) {
            toast.error("Arquivo é muito grande! 20MB máximo");
            return;
        }
        const videoBlob = URL.createObjectURL(e.target.files[0]);
        setPreviewVideos(old => [
            ...old,
            {
                number: number,
                url: videoBlob,
                name: e.target.files[0].name
            }
        ]);

        var divConteudo = document.createElement("div");

        const selectedMedias = Array.from(e.target.files);
        setMedias(old => [...old, selectedMedias[0]]);

        divConteudo.innerHTML = `<video controls="controls" style="width: 200px;">
    <source src="${videoBlob}" type="video/mp4" />
    seu navegador não suporta HTML5
  </video>`;

        document.querySelector(`.video${number}`).appendChild(divConteudo);
        document.querySelector(`.btnVideo${number}`).remove();
    };

    const handleChangeMediasDocument = (e, number) => {
        if (!e.target.files) {
            return;
        }

        if (e.target.files[0].size > 2000000) {
            toast.error("Arquivo é muito grande! 2MB máximo");
            return;
        }

        const file = e.target.files[0];
        const documentBlob = URL.createObjectURL(file);

        setPreviewDocument(old => [
            ...old,
            {
                number: number,
                url: documentBlob,
                name: file.name
            }
        ]);

        const selectedMedias = Array.from(e.target.files);
        setMedias(old => [...old, selectedMedias[0]]);

        // Atualiza o preview
        const previewElement = document.querySelector(`.Document${number}`);
        if (previewElement) {
            previewElement.innerHTML = `
                    <Description />
                    <span>${file.name}</span>
                `;
        }

        //document.querySelector(`.document${number}`).src = documentBlob;
        document.querySelector(`.btnDocument${number}`).remove();
    };

    const handleChangeMediasFileZip = (e, number) => {
        if (!e.target.files) {
            return;
        }

        if (e.target.files[0].size > 2000000) {
            toast.error("Arquivo é muito grande! 2MB máximo");
            return;
        }

        const file = e.target.files[0];
        const fileZipBlob = URL.createObjectURL(file);

        setPreviewFileZip(old => [
            ...old,
            {
                number: number,
                url: fileZipBlob,
                name: file.name
            }
        ]);

        const selectedMedias = Array.from(e.target.files);
        setMedias(old => [...old, selectedMedias[0]]);

        // Cria um elemento div para o preview
        const divConteudo = document.createElement("div");
        divConteudo.style.display = "flex";
        divConteudo.style.alignItems = "center";
        divConteudo.style.gap = "8px";

        // Usa um ícone em SVG em vez do componente React
        divConteudo.innerHTML = `
            <svg style="width: 24px; height: 24px;">
                <use href="#folder-zip-icon" />
            </svg>
            <span>${file.name}</span>
        `;

        // Atualiza o preview
        const previewElement = document.querySelector(`.fileZip${number}`);
        if (previewElement) {
            previewElement.innerHTML = ""; // Limpa o conteúdo anterior
            previewElement.appendChild(divConteudo);
        }

        document.querySelector(`.btnFileZip${number}`).remove();
    };

    const MessageLayout = ({ number, valueDefault = "", classes, onDelete }) => {
        const [messageText, setMessageText] = useState(valueDefault);
        const [showEmoji, setShowEmoji] = useState(false);
        const [currentTextField, setCurrentTextField] = useState(null);

        // Função local para lidar com as variáveis
        const handleLocalMsgVar = msgVar => {
            const textField = document.querySelector(`#message-field-${number}`);
            if (!textField) return;

            const cursorPos = textField.selectionStart;
            const newText = messageText.slice(0, cursorPos) + msgVar + messageText.slice(cursorPos);

            setMessageText(newText);

            // Reposiciona o cursor após a variável inserida
            setTimeout(() => {
                const newPos = cursorPos + msgVar.length;
                textField.setSelectionRange(newPos, newPos);
                textField.focus();
            }, 100);
        };

        const handleAddEmoji = (e, targetField) => {
            const emoji = e.native;

            if (targetField && targetField.type === "textarea") {
                const cursorPos = targetField.selectionStart;
                const currentValue = targetField.value;
                const newValue =
                    currentValue.slice(0, cursorPos) + emoji + currentValue.slice(cursorPos);

                // Atualiza o estado local
                setMessageText(newValue);

                // Atualiza o estado global
                if (targetField.id.startsWith("message-field-")) {
                    const index = parseInt(targetField.id.split("-")[2]);
                    const newElements = [...elements];
                    if (newElements[index]) {
                        newElements[index] = {
                            ...newElements[index],
                            text: newValue
                        };
                        setElements(newElements);
                    }
                }

                // Reposiciona o cursor após o emoji
                setTimeout(() => {
                    targetField.focus();
                    targetField.setSelectionRange(
                        cursorPos + emoji.length,
                        cursorPos + emoji.length
                    );
                }, 10);

                // // Fecha o picker após selecionar
                // setShowEmoji(false);
            }
        };

        const handleTextFieldFocus = e => {
            setCurrentTextField(e.target);
        };

        return (
            <Formik
                initialValues={{
                    text: valueDefault
                }}
            >
                {({ isSubmitting }) => (
                    <Stack
                        className={`stackMessage${number} ${classes.mediaContainer}`}
                        key={`stackMessage${number}`}
                    >
                        <Stack className={classes.deleteButtonContainer}>
                            <IconButton
                                className={classes.deleteButton}
                                onClick={() => onDelete(number, "message")}
                            >
                                <Delete />
                            </IconButton>
                        </Stack>
                        <div className={classes.titleContainer}>
                            <Message className={classes.titleIcons} />
                            <Typography variant="h6" textAlign="left">
                                {i18n.t("flowbuilderModal.dialog.messageText")} {number}
                            </Typography>
                        </div>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                            <TextField
                                label="Escreva sua Mensagem de Texto"
                                id={`message-field-${number}`}
                                onFocus={handleTextFieldFocus}
                                multiline
                                rows={4}
                                value={messageText}
                                onChange={e => setMessageText(e.target.value)}
                                className={`message${number} ${classes.textField}`}
                                name="text"
                                variant="outlined"
                                margin="dense"
                            />

                            <Grid container spacing={2}>
                                <Grid xs={12} item>
                                    <MessageVariablesPicker
                                        disabled={isSubmitting}
                                        onClick={value => handleLocalMsgVar(value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid xs={12} item>
                                <IconButton
                                    onClick={() => setShowEmoji(!showEmoji)}
                                    color={showEmoji ? "primary" : "default"}
                                >
                                    <SentimentSatisfiedAltIcon />
                                </IconButton>

                                <div
                                    className={`${classes.emojiContainer} ${
                                        showEmoji ? "expanded" : ""
                                    }`}
                                >
                                    <Picker
                                        title="Escolha um emoji"
                                        emoji="point_up"
                                        perLine={10}
                                        style={{ width: "100%" }}
                                        showPreview={false}
                                        showSkinTones={false}
                                        onSelect={emoji => handleAddEmoji(emoji, currentTextField)} // Mudei para onSelect
                                    />
                                </div>
                            </Grid>
                        </FormControl>
                    </Stack>
                )}
            </Formik>
        );
    };
    const messageLayout = (number, valueDefault = "") => {
        return (
            <MessageLayout
                number={number}
                valueDefault={valueDefault}
                classes={classes}
                onDelete={deleteElementsTypeOne}
            />
        );
    };

    const imgLayout = (number, valueDefault = "") => {
        return (
            <Stack
                className={`stackImg${number} ${classes.mediaContainer}`}
                key={`stackImg${number}`}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => deleteElementsTypeOne(number, "img")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <Image className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.image")} {number}
                    </Typography>
                </div>
                <Stack direction={"row"} justifyContent={"center"}>
                    <img
                        src={
                            valueDefault.length > 0
                                ? process.env.REACT_APP_BACKEND_URL + "/public/" + valueDefault
                                : ""
                        }
                        className={`img${number}`}
                        style={{ width: "200px" }}
                    />
                </Stack>

                {valueDefault.length === 0 && (
                    <Button variant="contained" component="label" className={`btnImg${number}`}>
                        Upload imagem
                        <input
                            type="file"
                            accept="image/png, image/jpg, image/jpeg"
                            hidden
                            onChange={e => handleChangeMediasImg(e, number)}
                        />
                    </Button>
                )}
            </Stack>
        );
    };

    const audioLayout = (number, valueDefault = "", valueRecordDefault = "") => {
        return (
            <Stack
                className={`stackAudio${number} ${classes.mediaContainer}`}
                key={`stackAudio${number}`}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => deleteElementsTypeOne(number, "audio")}
                    >
                        <Delete sx={{ cursor: "pointer" }} />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <MicNone className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.audio")} {number}
                    </Typography>
                </div>
                <div
                    className={`audio${number}`}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}
                >
                    {valueDefault.length > 0 && (
                        <audio controls="controls">
                            <source
                                src={process.env.REACT_APP_BACKEND_URL + "/public/" + valueDefault}
                                type="audio/mp3"
                            />
                            seu navegador não suporta HTML5
                        </audio>
                    )}
                </div>

                {valueDefault.length === 0 && (
                    <Button variant="contained" component="label" className={`btnAudio${number}`}>
                        Upload audio
                        <input
                            type="file"
                            accept="audio/ogg, audio/mp3, audio/opus"
                            hidden
                            onChange={e => handleChangeAudios(e, number)}
                        />
                    </Button>
                )}

                <Stack direction={"row"} justifyContent={"center"}>
                    <Checkbox
                        className={`checkaudio${number}`}
                        defaultChecked={valueRecordDefault === "ok" ? false : true}
                    />
                    <Stack justifyContent={"center"}>
                        <Typography>Enviar como audio gravado na hora</Typography>
                    </Stack>
                </Stack>
            </Stack>
        );
    };

    const videoLayout = (number, valueDefault = "") => {
        return (
            <Stack
                className={`stackVideo${number} ${classes.mediaContainer}`}
                key={`stackVideo${number}`}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => deleteElementsTypeOne(number, "video")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <Videocam className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.video")} {number}
                    </Typography>
                </div>
                <div
                    className={`video${number}`}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}
                >
                    {valueDefault.length > 0 && (
                        <video controls="controls" style={{ width: "200px" }}>
                            <source
                                src={process.env.REACT_APP_BACKEND_URL + "/public/" + valueDefault}
                                type="video/mp4"
                            />
                            seu navegador não suporta HTML5
                        </video>
                    )}
                </div>

                {valueDefault.length === 0 && (
                    <Button variant="contained" component="label" className={`btnVideo${number}`}>
                        Upload video
                        <input
                            type="file"
                            accept="video/mp4"
                            hidden
                            onChange={e => handleChangeVideos(e, number)}
                        />
                    </Button>
                )}
            </Stack>
        );
    };

    const documentLayout = (number, valueDefault = "") => {
        const hasValue =
            typeof valueDefault === "string" ? valueDefault.length > 0 : valueDefault?.value;

        return (
            <Stack
                className={`stackDocument${number} ${classes.mediaContainer}`}
                key={`stackDocument${number}`}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => deleteElementsTypeOne(number, "document")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <Description className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.document")} {number}
                    </Typography>
                </div>
                <Stack direction={"row"} justifyContent={"center"}>
                    <div
                        src={
                            hasValue
                                ? process.env.REACT_APP_BACKEND_URL +
                                  "/public/" +
                                  (valueDefault.value || valueDefault)
                                : ""
                        }
                        className={`document${number}`}
                        style={{ width: "200px" }}
                    />
                </Stack>
                <Stack direction={"row"} justifyContent={"center"} alignItems="center" spacing={1}>
                    {hasValue ? (
                        <>
                            <Description />
                            <Typography>
                                {valueDefault.original || valueDefault.split("/").pop()}
                            </Typography>
                        </>
                    ) : (
                        <div
                            className={`Document${number}`}
                            style={{ display: "flex", alignItems: "center", gap: "8px" }}
                        ></div>
                    )}
                </Stack>
                {!hasValue && (
                    <Button
                        variant="contained"
                        component="label"
                        className={`btnDocument${number}`}
                    >
                        Upload documento
                        <input
                            type="file"
                            accept=".pdf,.csv,.txt,.doc,.docx,.xls,.xlsx,.rtf,.odt,.odp,.ods,.ppt,.pptx,.xml,.json,.html,.htm,.xhtml,.css,.py,.c,.cpp,.csharp,.php,.js,.jsx,.ts,.tsx"
                            hidden
                            onChange={e => handleChangeMediasDocument(e, number)}
                        />
                    </Button>
                )}
            </Stack>
        );
    };

    const fileZipLayout = (number, valueDefault = "") => {
        const hasValue =
            typeof valueDefault === "string" ? valueDefault.length > 0 : valueDefault?.value;

        return (
            <Stack
                className={`stackFileZip${number} ${classes.mediaContainer}`}
                key={`stackFileZip${number}`}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => deleteElementsTypeOne(number, "fileZip")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <FolderZip className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.fileZip")} {number}
                    </Typography>
                </div>
                <Stack direction={"row"} justifyContent={"center"}>
                    <div
                        src={
                            hasValue
                                ? process.env.REACT_APP_BACKEND_URL +
                                  "/public/" +
                                  (valueDefault.value || valueDefault)
                                : ""
                        }
                        className={`fileZip${number}`}
                        style={{ width: "200px" }}
                    />
                </Stack>
                <Stack direction={"row"} justifyContent={"center"} alignItems="center" spacing={1}>
                    {hasValue ? (
                        <>
                            <FolderZip />
                            <Typography>
                                {valueDefault.original || valueDefault.split("/").pop()}
                            </Typography>
                        </>
                    ) : (
                        <div
                            className={`FileZip${number}`}
                            style={{ display: "flex", alignItems: "center", gap: "8px" }}
                        ></div>
                    )}
                </Stack>
                {!hasValue && (
                    <Button variant="contained" component="label" className={`btnFileZip${number}`}>
                        Upload ZIP/RAR
                        <input
                            type="file"
                            accept="*/*"
                            hidden
                            onChange={e => handleChangeMediasFileZip(e, number)}
                        />
                    </Button>
                )}
            </Stack>
        );
    };

    const MenuLayout = ({ number, valueDefault = {}, classes, onDelete }) => {
        console.log("valueDefault recebido:", valueDefault);

        const [menuOptions, setMenuOptions] = useState(() => {
            // Se valueDefault for um objeto vazio, retorna array vazio sem warning
            if (Object.keys(valueDefault).length === 0) {
                return [];
            }

            // Se tiver dados, valida o formato
            if (!Array.isArray(valueDefault.arrayOption)) {
                console.warn("Valor padrão inválido para menuOptions:", valueDefault);
                return [];
            }
            return valueDefault.arrayOption;
        });

        const [messageText, setMessageText] = useState(() => {
            // Se valueDefault for um objeto vazio, retorna string vazia sem warning
            if (Object.keys(valueDefault).length === 0) {
                return "";
            }

            // Se tiver dados, valida o formato
            if (typeof valueDefault.message !== "string") {
                console.warn("Valor padrão inválido para messageText:", valueDefault);
                return "";
            }
            return valueDefault.message;
        });

        const [showEmoji, setShowEmoji] = useState(false); // Novo estado para emoji
        const [currentTextField, setCurrentTextField] = useState(null); // Novo estado para campo atual

        // Função local para lidar com as variáveis
        const handleLocalMsgVar = msgVar => {
            const textField = document.querySelector(`#message-field-${number}`);
            if (!textField) return;

            const cursorPos = textField.selectionStart;
            const newText = messageText.slice(0, cursorPos) + msgVar + messageText.slice(cursorPos);

            setMessageText(newText);

            // Reposiciona o cursor após a variável inserida
            setTimeout(() => {
                const newPos = cursorPos + msgVar.length;
                textField.setSelectionRange(newPos, newPos);
                textField.focus();
            }, 100);
        };

        // Nova função para lidar com emojis
        const handleAddEmoji = (e, targetField) => {
            const emoji = e.native;
            console.log("Emoji selecionado:", emoji);
            console.log("Campo alvo:", targetField);

            if (targetField && targetField.type === "textarea") {
                console.log("Inserindo no textarea");
                // Para o campo de mensagem principal
                const cursorPos = targetField.selectionStart;
                const currentValue = targetField.value;
                const newValue =
                    currentValue.slice(0, cursorPos) + emoji + currentValue.slice(cursorPos);

                setMessageText(newValue);

                // Atualiza o estado global
                if (targetField.id.startsWith("message-field-")) {
                    const index = parseInt(targetField.id.split("-")[2]);
                    const newElements = [...elements];
                    if (newElements[index]) {
                        newElements[index] = {
                            ...newElements[index],
                            message: newValue
                        };
                        setElements(newElements);
                    }
                }

                // Reposiciona o cursor após o emoji
                setTimeout(() => {
                    targetField.focus();
                    targetField.setSelectionRange(
                        cursorPos + emoji.length,
                        cursorPos + emoji.length
                    );
                }, 10);
            } else if (targetField && targetField.type === "text") {
                console.log("Inserindo no campo de opção");
                // Para os campos de opções
                const cursorPos = targetField.selectionStart;
                const currentValue = targetField.value || "";
                console.log("Valor atual:", currentValue);
                const newValue =
                    currentValue.slice(0, cursorPos) + emoji + currentValue.slice(cursorPos);
                console.log("Novo valor:", newValue);

                // Dispara um evento de change sintético para o Material-UI
                const event = new Event("input", { bubbles: true });
                Object.defineProperty(event, "target", {
                    writable: false,
                    value: { value: newValue }
                });
                targetField.dispatchEvent(event);

                // Atualiza o estado das opções
                const optionElement = targetField.closest("[data-option-index]");
                if (optionElement) {
                    const index = parseInt(optionElement.getAttribute("data-option-index"));
                    const newOptions = [...menuOptions];
                    newOptions[index].value = newValue;
                    setMenuOptions(newOptions);
                }

                // Reposiciona o cursor após o emoji
                setTimeout(() => {
                    targetField.focus();
                    targetField.setSelectionRange(
                        cursorPos + emoji.length,
                        cursorPos + emoji.length
                    );
                }, 10);
            }
        };

        // Nova função para focar no campo
        const handleTextFieldFocus = e => {
            setCurrentTextField(e.target);
        };

        const addOption = () => {
            setMenuOptions(prev => [
                ...prev,
                {
                    number: prev.length + 1,
                    value: ""
                }
            ]);
        };

        const removeOption = optionNumber => {
            setMenuOptions(prev => prev.filter(item => item.number !== optionNumber));
        };

        return (
            <Formik
                initialValues={{
                    text: valueDefault
                }}
            >
                {({ isSubmitting }) => (
                    <Stack
                        className={`stackMenu${number} ${classes.mediaContainer}`}
                        key={`stackMenu${number}`}
                    >
                        <Stack className={classes.deleteButtonContainer}>
                            <IconButton
                                className={classes.deleteButton}
                                onClick={() => onDelete(number, "menu")}
                            >
                                <Delete />
                            </IconButton>
                        </Stack>
                        <div className={classes.titleContainer}>
                            <MenuIcon className={classes.titleIcons} />
                            <Typography variant="h6">
                                {i18n.t("flowbuilderModal.dialog.menu")} {number}
                            </Typography>
                        </div>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                            <TextField
                                label="Mensagem de explicação do menu"
                                id={`message-field-${number}`}
                                onFocus={handleTextFieldFocus}
                                multiline
                                rows={4}
                                value={messageText}
                                onChange={e => setMessageText(e.target.value)}
                                className={`menuMessage${number} ${classes.textField}`}
                                name="text"
                                variant="outlined"
                                margin="dense"
                            />

                            <Grid container spacing={2}>
                                <Grid xs={12} item>
                                    <MessageVariablesPicker
                                        disabled={isSubmitting}
                                        onClick={value => handleLocalMsgVar(value)}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <IconButton
                                        onClick={() => setShowEmoji(!showEmoji)}
                                        color={showEmoji ? "primary" : "default"}
                                    >
                                        <SentimentSatisfiedAltIcon />
                                    </IconButton>

                                    <div
                                        className={`${classes.emojiContainer} ${
                                            showEmoji ? "expanded" : ""
                                        }`}
                                    >
                                        <Picker
                                            title="Escolha um emoji"
                                            emoji="point_up"
                                            perLine={10}
                                            style={{ width: "100%" }}
                                            showPreview={false}
                                            showSkinTones={false}
                                            onSelect={emoji =>
                                                handleAddEmoji(emoji, currentTextField)
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </FormControl>
                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems="center"
                            mt={2}
                        >
                            <Typography>Adicionar Opção</Typography>
                            <Button onClick={addOption} color="primary" variant="contained">
                                <AddCircle />
                            </Button>
                        </Stack>
                        <Stack spacing={2} className={`menuOptions${number}`}>
                            {menuOptions.map((option, index) => (
                                <Stack key={option.number} width={"100%"}>
                                    <Typography>Digite {option.number}</Typography>
                                    <Stack
                                        direction={"row"}
                                        width={"100%"}
                                        spacing={1}
                                        size="small"
                                    >
                                        <TextField
                                            placeholder={"Digite opção"}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={option.value}
                                            className={classes.textField}
                                            onFocus={handleTextFieldFocus}
                                            data-option-index={index}
                                            onChange={e => {
                                                const newOptions = [...menuOptions];
                                                newOptions[index].value = e.target.value;
                                                setMenuOptions(newOptions);
                                            }}
                                        />
                                        {menuOptions.length === option.number && (
                                            <IconButton onClick={() => removeOption(option.number)}>
                                                <Delete />
                                            </IconButton>
                                        )}
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                )}
            </Formik>
        );
    };
    const menuLayout = (number, valueDefault = {}) => {
        return (
            <MenuLayout
                number={number}
                valueDefault={valueDefault}
                classes={classes}
                onDelete={deleteElementsTypeOne}
            />
        );
    };

    const intervalLayout = (number, valueDefault = 0) => {
        return (
            <Stack
                className={`stackInterval${number} ${classes.mediaContainer}`}
                key={`stackInterval${number}`}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => deleteElementsTypeOne(number, "interval")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <AccessTime className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.interval")} {number}
                    </Typography>
                </div>

                <TextField
                    label={"Tempo em segundos"}
                    className={`interval${number} ${classes.textField}`}
                    defaultValue={valueDefault}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 120 } }}
                    variant="outlined"
                    margin="dense"
                    style={{ width: "100%" }}
                />
            </Stack>
        );
    };

    const randomizerLayout = (number, valueDefault = {}) => {
        return (
            <Stack
                className={`stackRandomizer${number} ${classes.mediaContainer}`}
                key={`stackRandomizer${number}`}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => deleteElementsTypeOne(number, "randomizer")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <CallSplit className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.randomizer")} {number}
                    </Typography>
                </div>

                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <Slider
                        defaultValue={valueDefault.percent || 0}
                        valueLabelDisplay="auto"
                        step={10}
                        marks
                        min={0}
                        max={100}
                        className={`randomizerPercent${number}`}
                    />
                </Stack>

                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography className={`randomizerValue${number}`}>
                        {valueDefault.percent || 0}%
                    </Typography>
                    <Typography>{100 - (valueDefault.percent || 0)}%</Typography>
                </Stack>
            </Stack>
        );
    };

    const BusinessHoursLayout = ({ number, valueDefault = {}, classes, onDelete }) => {
        const [selectedType, setSelectedType] = useState(valueDefault.type || "queue");
        const [selectedTarget, setSelectedTarget] = useState(valueDefault.targetId || "");
        const [options, setOptions] = useState([]);

        useEffect(() => {
            const loadOptions = async () => {
                try {
                    let response;
                    if (selectedType === "queue") {
                        response = await api.get("/queue");
                    } else {
                        response = await api.get("/whatsapp");
                    }

                    setOptions(
                        response.data.map(item => ({
                            id: item.id,
                            name: item.name
                        }))
                    );
                } catch (error) {
                    console.error("Erro ao carregar opções:", error);
                    setOptions([]);
                }
            };

            loadOptions();
        }, [selectedType]);

        return (
            <Stack
                className={`stackBusinessHours${number} ${classes.mediaContainer}`}
                key={`stackBusinessHours${number}`}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => onDelete(number, "businessHours")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>

                <div className={classes.titleContainer}>
                    <AccessTime className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.businessHours")} {number}
                    </Typography>
                </div>

                <Stack className={classes.stackFormContainer}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel>Tipo de Verificação</InputLabel>
                        <Select
                            value={selectedType}
                            label="Tipo de Verificação"
                            onChange={e => setSelectedType(e.target.value)}
                            className={`businessHoursType${number}`}
                        >
                            <MenuItem value="queue">Fila</MenuItem>
                            <MenuItem value="whatsapp">Conexão</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel>
                            {selectedType === "queue" ? "Selecionar Fila" : "Selecionar Conexão"}
                        </InputLabel>
                        <Select
                            value={selectedTarget}
                            label={
                                selectedType === "queue" ? "Selecionar Fila" : "Selecionar Conexão"
                            }
                            onChange={e => setSelectedTarget(e.target.value)}
                            className={`businessHoursTarget${number}`}
                            MenuProps={{
                                disableEnforceFocus: true,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                        >
                            {options.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Stack>
        );
    };
    const businessHoursLayout = (number, valueDefault = {}) => {
        return (
            <BusinessHoursLayout
                number={number}
                valueDefault={valueDefault}
                classes={classes}
                onDelete={deleteElementsTypeOne}
            />
        );
    };

    const ConditionLayout = ({ number, valueDefault = {}, classes, onDelete }) => {
        return (
            <Stack
                className={`stackCondition${number} ${classes.mediaContainer}`}
                key={`stackCondition${number}`}
                sx={{ minHeight: "120px" }}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => onDelete(number, "condition")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <ImportExport className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.condition")} {number}
                    </Typography>
                </div>

                <Stack className={classes.stackFormContainer}>
                    <TextField
                        label={"Campo da condição (Digiter apenas 1 chave)"}
                        className={`conditionKey${number} ${classes.textField}`}
                        defaultValue={valueDefault.key || ""}
                        size="small"
                        variant="outlined"
                        margin="dense"
                        style={{ width: "100%" }}
                    />

                    <FormControl size="small">
                        <InputLabel>Regra de validação</InputLabel>
                        <Select
                            defaultValue={valueDefault.condition || ""}
                            label="Regra de validação"
                            className={`conditionRule${number} ${classes.textField}`}
                            MenuProps={{
                                disableEnforceFocus: true,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                        >
                            <MenuItem value={1}>==</MenuItem>
                            <MenuItem value={2}>&gt;=</MenuItem>
                            <MenuItem value={3}>&lt;=</MenuItem>
                            <MenuItem value={4}>&lt;</MenuItem>
                            <MenuItem value={5}>&gt;</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        label={"Valor da condição a ser analisada"}
                        defaultValue={valueDefault.value || ""}
                        className={`conditionValue${number} ${classes.textField}`}
                        size="small"
                        variant="outlined"
                        margin="dense"
                        style={{ width: "100%" }}
                    />
                </Stack>
            </Stack>
        );
    };
    const conditionLayout = (number, valueDefault = {}) => {
        return (
            <ConditionLayout
                number={number}
                valueDefault={valueDefault}
                classes={classes}
                onDelete={deleteElementsTypeOne}
            />
        );
    };

    const QueueLayout = ({ number, valueDefault = {}, classes, onDelete }) => {
        const queueKey = `queue${number}`;
        console.log("Renderizando fila:", queueKey);
        const [selectedValue, setSelectedValue] = useState("");

        useEffect(() => {
            if (queueSelections[queueKey]) {
                setSelectedValue(queueSelections[queueKey]);
            }
        }, [queueSelections, queueKey]);

        const handleChange = e => {
            const newValue = e.target.value;
            setSelectedValue(newValue);
            setQueueSelections(prev => ({
                ...prev,
                [queueKey]: newValue
            }));
        };

        return (
            <Stack
                className={`stackQueue${number} ${classes.mediaContainer}`}
                key={`stackQueue${number}`}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => onDelete(number, "queue")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <QueueIcon className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.queue")} {number}
                    </Typography>
                </div>
                <Stack className={classes.stackFormContainer}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel>Selecione uma Fila</InputLabel>
                        <Select
                            label="Selecione uma Fila"
                            value={selectedValue}
                            onChange={handleChange}
                            className={`queueSelect${number}`}
                            MenuProps={{
                                disableEnforceFocus: true,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                        >
                            {queues.map(queue => (
                                <MenuItem key={queue.id} value={queue.id}>
                                    {queue.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Stack>
        );
    };
    const queueLayout = (number, valueDefault = {}) => {
        return (
            <QueueLayout
                number={number}
                valueDefault={valueDefault}
                classes={classes}
                onDelete={deleteElementsTypeOne}
            />
        );
    };

    const ConnectionLayout = ({ number, valueDefault = {}, classes, onDelete }) => {
        const connectionKey = `connection${number}`;
        console.log("Renderizando conexão:", connectionKey);
        const [selectedValue, setSelectedValue] = useState("");

        useEffect(() => {
            if (connectionSelections[connectionKey]) {
                setSelectedValue(connectionSelections[connectionKey]);
            }
        }, [connectionSelections, connectionKey]);

        const handleChange = e => {
            const newValue = e.target.value;
            setSelectedValue(newValue);
            setConnectionSelections(prev => ({
                ...prev,
                [connectionKey]: newValue
            }));
        };

        return (
            <Stack
                className={`stackConnection${number} ${classes.mediaContainer}`}
                key={`stackConnection${number}`}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => onDelete(number, "connection")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <PhoneIcon className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.connection")} {number}
                    </Typography>
                </div>
                <Stack className={classes.stackFormContainer}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel>Selecione uma Conexão</InputLabel>
                        <Select
                            label="Selecione uma Conexão"
                            value={selectedValue}
                            onChange={handleChange}
                            className={`connectionSelect${number}`}
                            MenuProps={{
                                disableEnforceFocus: true,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                        >
                            {connections.map(connection => (
                                <MenuItem key={connection.id} value={connection.id}>
                                    {connection.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Stack>
        );
    };
    const connectionLayout = (number, valueDefault = {}) => {
        return (
            <ConnectionLayout
                number={number}
                valueDefault={valueDefault}
                classes={classes}
                onDelete={deleteElementsTypeOne}
            />
        );
    };

    const TagsLayout = ({ number, valueDefault = {}, classes, onDelete }) => {
        return (
            <Stack
                className={`stackTags${number} ${classes.mediaContainer}`}
                key={`stackTags${number}`}
                sx={{ minHeight: "120px" }}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => onDelete(number, "tags")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <LoyaltyIcon className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.tagsCondition")} {number}
                    </Typography>
                </div>
                <FormControl fullWidth variant="outlined" size="small">
                    <Select
                        defaultValue={valueDefault.condition || "all"}
                        className={`tagsCondition${number} ${classes.textField}`}
                    >
                        <MenuItem value="all">Todas as tags</MenuItem>
                        <MenuItem value="any">Qualquer tag</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 1 }}>
                    <InputLabel>Selecione Tags para condicionar</InputLabel>
                    <Select
                        label="Selecione Tags para condicionar"
                        multiple
                        defaultValue={valueDefault.tags?.map(t => t.id) || []}
                        className={`tagsSelect${number} ${classes.textField}`}
                        MenuProps={{
                            disableEnforceFocus: true,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        renderValue={selected => (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {selected.map(value => {
                                    const tag = availableTags.find(t => t.id === value);
                                    return (
                                        <Chip
                                            key={value}
                                            size="small"
                                            label={tag?.name}
                                            style={{
                                                backgroundColor: tag?.color,
                                                color: "white",
                                                textShadow: "1px 1px #000"
                                            }}
                                        />
                                    );
                                })}
                            </Box>
                        )}
                    >
                        {availableTags.map(tag => (
                            <MenuItem key={tag.id} value={tag.id}>
                                <Chip
                                    size="small"
                                    label={`${tag.name} (${
                                        tag.type === "kanban" ? "Kanban" : "Tag"
                                    })`}
                                    style={{
                                        backgroundColor: tag.color,
                                        color: "white",
                                        textShadow: "1px 1px #000"
                                    }}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        );
    };
    const tagsLayout = (number, valueDefault = {}) => {
        return (
            <TagsLayout
                number={number}
                valueDefault={valueDefault}
                classes={classes}
                onDelete={deleteElementsTypeOne}
            />
        );
    };

    const AddTagsLayout = ({ number, valueDefault = {}, classes, onDelete }) => {
        const tagKey = `addTags${number}`;
        console.log("Renderizando AddTags com props:", {
            number,
            valueDefault,
            hasExistingTags: Boolean(valueDefault.tags?.length)
        });
        const [nodeId] = useState(() => valueDefault.nodeId || null);
        // Inicializa com as tags existentes ou array vazio
        const [selectedValues, setSelectedValues] = useState(() => {
            if (valueDefault.tags?.length > 0) {
                return valueDefault.tags.map(t => t.id);
            }
            return [];
        });

        // Sincroniza com o estado global quando monta ou quando valueDefault muda
        useEffect(() => {
            if (valueDefault.tags?.length > 0) {
                const tagIds = valueDefault.tags.map(t => t.id);
                setSelectedValues(tagIds);
                setTagSelections(prev => ({
                    ...prev,
                    [tagKey]: {
                        nodeId: nodeId,
                        tagIds: tagIds
                    }
                }));
            }
        }, [valueDefault, tagKey, nodeId]);

        const handleChange = e => {
            const newValues = e.target.value;

            setSelectedValues(newValues);
            setTagSelections(prev => ({
                ...prev,
                [tagKey]: {
                    nodeId: nodeId,
                    tagIds: newValues
                }
            }));
        };

        return (
            <Stack
                className={`stackAddTags${number} ${classes.mediaContainer}`}
                key={`stackAddTags${number}`}
                sx={{ minHeight: "120px" }}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => onDelete(number, "addTags")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <AddIcon sx={{ width: 10, height: 10 }} />
                    <LocalOfferIcon className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.addTags")} {number}
                    </Typography>
                </div>

                <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 1 }}>
                    <InputLabel>Selecione Tags para adicionar</InputLabel>
                    <Select
                        label="Selecione Tags para adicionar"
                        multiple
                        value={selectedValues}
                        onChange={handleChange}
                        className={`addTagsSelect${number} ${classes.textField}`}
                        MenuProps={{
                            disableEnforceFocus: true,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        renderValue={selected => (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {selected.map(value => {
                                    const tag = availableTags.find(t => t.id === value);
                                    return (
                                        <Chip
                                            key={value}
                                            size="small"
                                            label={`${tag?.name} (${
                                                tag?.type === "kanban" ? "Kanban" : "Tag"
                                            })`}
                                            style={{
                                                backgroundColor: tag?.color,
                                                color: "white",
                                                textShadow: "1px 1px #000"
                                            }}
                                        />
                                    );
                                })}
                            </Box>
                        )}
                    >
                        {availableTags.map(tag => (
                            <MenuItem key={tag.id} value={tag.id}>
                                <Chip
                                    size="small"
                                    label={`${tag.name} (${
                                        tag.type === "kanban" ? "Kanban" : "Tag"
                                    })`}
                                    style={{
                                        backgroundColor: tag.color,
                                        color: "white",
                                        textShadow: "1px 1px #000"
                                    }}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        );
    };
    const addTagsLayout = (number, valueDefault = {}) => {
        return (
            <AddTagsLayout
                number={number}
                valueDefault={valueDefault}
                classes={classes}
                onDelete={deleteElementsTypeOne}
            />
        );
    };

    const RemoveTagsLayout = ({ number, valueDefault = {}, classes, onDelete }) => {
        const tagKey = `removeTags${number}`;
        console.log("Renderizando RemoveTags com props:", {
            number,
            valueDefault,
            hasExistingTags: Boolean(valueDefault.tags?.length)
        });
        const [nodeId] = useState(() => valueDefault.nodeId || null);

        const [selectedValues, setSelectedValues] = useState(() => {
            if (valueDefault.tags?.length > 0) {
                return valueDefault.tags.map(t => t.id);
            }
            return [];
        });

        useEffect(() => {
            if (valueDefault.tags?.length > 0) {
                const tagIds = valueDefault.tags.map(t => t.id);
                setSelectedValues(tagIds);
                setTagSelections(prev => ({
                    ...prev,
                    [tagKey]: {
                        nodeId: nodeId,
                        tagIds: tagIds
                    }
                }));
            }
        }, [valueDefault, tagKey, nodeId]);

        const handleChange = e => {
            const newValues = e.target.value;

            setSelectedValues(newValues);
            setTagSelections(prev => ({
                ...prev,
                [tagKey]: {
                    nodeId: nodeId,
                    tagIds: newValues
                }
            }));
        };

        return (
            <Stack
                className={`stackRemoveTags${number} ${classes.mediaContainer}`}
                key={`stackRemoveTags${number}`}
                sx={{
                    minHeight: "120px",
                    borderColor: "#F44336"
                }}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => onDelete(number, "removeTags")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <RemoveIcon sx={{ width: 10, height: 10 }} />
                    <LocalOfferIcon className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.removeTags")} {number}
                    </Typography>
                </div>

                <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 1 }}>
                    <InputLabel>Selecione Tags para remover</InputLabel>
                    <Select
                        label="Selecione Tags para remover"
                        multiple
                        value={selectedValues}
                        onChange={handleChange}
                        className={`removeTagsSelect${number} ${classes.textField}`}
                        MenuProps={{
                            disableEnforceFocus: true,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        renderValue={selected => (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {selected.map(value => {
                                    const tag = availableTags.find(t => t.id === value);
                                    return (
                                        <Chip
                                            key={value}
                                            size="small"
                                            label={`${tag?.name} (${
                                                tag?.type === "kanban" ? "Kanban" : "Tag"
                                            })`}
                                            style={{
                                                backgroundColor: tag?.color,
                                                color: "white",
                                                textShadow: "1px 1px #000"
                                            }}
                                        />
                                    );
                                })}
                            </Box>
                        )}
                    >
                        {availableTags.map(tag => (
                            <MenuItem key={tag.id} value={tag.id}>
                                <Chip
                                    size="small"
                                    label={`${tag.name} (${
                                        tag.type === "kanban" ? "Kanban" : "Tag"
                                    })`}
                                    style={{
                                        backgroundColor: tag.color,
                                        color: "white",
                                        textShadow: "1px 1px #000"
                                    }}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        );
    };
    const removeTagsLayout = (number, valueDefault = {}) => {
        return (
            <RemoveTagsLayout
                number={number}
                valueDefault={valueDefault}
                classes={classes}
                onDelete={deleteElementsTypeOne}
            />
        );
    };

    const WebhookLayout = ({ number, valueDefault = {}, classes, onDelete }) => {
        return (
            <Stack
                className={`stackWebhook${number} ${classes.mediaContainer}`}
                key={`stackWebhook${number}`}
                sx={{ minHeight: "120px" }}
            >
                <Stack className={classes.deleteButtonContainer}>
                    <IconButton
                        className={classes.deleteButton}
                        onClick={() => onDelete(number, "webhook")}
                    >
                        <Delete />
                    </IconButton>
                </Stack>
                <div className={classes.titleContainer}>
                    <WebhookOutlined className={classes.titleIcons} />
                    <Typography variant="h6">
                        {i18n.t("flowbuilderModal.dialog.webhook")} {number}
                    </Typography>
                </div>

                <Stack className={classes.stackFormContainer}>
                    <TextField
                        label="Nome"
                        size="small"
                        variant="outlined"
                        defaultValue={valueDefault.name || ""}
                        className={`webhookName${number} ${classes.textField}`}
                    />

                    <TextField
                        label="URL"
                        size="small"
                        variant="outlined"
                        defaultValue={valueDefault.url || ""}
                        className={`webhookUrl${number} ${classes.textField}`}
                    />

                    <FormControl size="small">
                        <InputLabel>Método</InputLabel>
                        <Select
                            label="Método"
                            defaultValue={valueDefault.method || "POST"}
                            className={`webhookMethod${number} ${classes.textField}`}
                            MenuProps={{
                                disableEnforceFocus: true,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                        >
                            <MenuItem value="GET">GET</MenuItem>
                            <MenuItem value="POST">POST</MenuItem>
                            <MenuItem value="PUT">PUT</MenuItem>
                            <MenuItem value="DELETE">DELETE</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        label="Headers"
                        size="small"
                        variant="outlined"
                        multiline
                        rows={4}
                        defaultValue={valueDefault.headers || ""}
                        className={`webhookHeaders${number} ${classes.textField}`}
                    />

                    <TextField
                        label="Body"
                        size="small"
                        variant="outlined"
                        multiline
                        rows={4}
                        defaultValue={valueDefault.body || ""}
                        className={`webhookBody${number} ${classes.textField}`}
                    />
                </Stack>
            </Stack>
        );
    };
    const webhookLayout = (number, valueDefault = {}) => {
        return (
            <WebhookLayout
                number={number}
                valueDefault={valueDefault}
                classes={classes}
                onDelete={deleteElementsTypeOne}
            />
        );
    };

    useEffect(() => {
        if (open === "edit") {
            setLabels({
                title: "Editar conteúdo"
            });

            // Verifica se data e suas propriedades existem
            if (!data?.data?.seq || !data?.data?.elements) {
                console.warn("Dados inválidos:", data);
                return;
            }

            setElementsSeq(data.data.seq);
            setElementsSeqEdit(data.data.seq);
            setElementsEdit(data.data.elements);

            if (data) {
                const elementsEditLoc = data.data.elements;
                const sequence = data.data.seq;

                sequence.map(item => {
                    //const itemNode = elementsEditLoc.filter(inode => inode.number === item)[0];
                    const itemNode = elementsEditLoc.filter(inode => inode.number === item)[0];
                    if (!itemNode || !itemNode.type) {
                        console.warn("Item inválido encontrado:", item);
                        return;
                    }
                    if (itemNode.type === "message") {
                        const numberLoc = parseInt(item.replace("message", ""));
                        setElements(elm => [...elm, messageLayout(numberLoc, itemNode.value)]);
                        setNumberMessages(old => {
                            const arsOnly = sequence.filter(item => item.includes("message"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("message", ""))
                            );
                            setNumberMessagesLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "img") {
                        const numberLoc = parseInt(item.replace("img", ""));
                        setElements(elm => [...elm, imgLayout(numberLoc, itemNode.value)]);
                        setNumberImg(old => {
                            const arsOnly = sequence.filter(item => item.includes("img"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("img", ""))
                            );
                            setNumberImgLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "audio") {
                        const numberLoc = parseInt(item.replace("audio", ""));
                        setElements(elm => [
                            ...elm,
                            audioLayout(numberLoc, itemNode.value, itemNode.record ? "" : "ok")
                        ]);
                        setNumberAudio(old => {
                            const arsOnly = sequence.filter(item => item.includes("audio"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("audio", ""))
                            );
                            setNumberAudioLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "video") {
                        const numberLoc = parseInt(item.replace("video", ""));
                        setElements(elm => [...elm, videoLayout(numberLoc, itemNode.value)]);
                        setNumberVideo(old => {
                            const arsOnly = sequence.filter(item => item.includes("video"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("video", ""))
                            );
                            setNumberVideoLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "document") {
                        const numberLoc = parseInt(item.replace("document", ""));
                        setElements(elm => [...elm, documentLayout(numberLoc, itemNode.value)]);
                        setNumberDocument(old => {
                            const arsOnly = sequence.filter(item => item.includes("document"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("document", ""))
                            );
                            setNumberDocumentLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "fileZip") {
                        const numberLoc = parseInt(item.replace("fileZip", ""));
                        setElements(elm => [...elm, fileZipLayout(numberLoc, itemNode.value)]);
                        setNumberFileZip(old => {
                            const arsOnly = sequence.filter(item => item.includes("fileZip"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("fileZip", ""))
                            );
                            setNumberFileZipLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "menu") {
                        const numberLoc = parseInt(item.replace("menu", ""));
                        setElements(elm => [...elm, menuLayout(numberLoc, itemNode.value)]);
                        setNumberMenu(old => {
                            const arsOnly = sequence.filter(item => item.includes("menu"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("menu", ""))
                            );
                            setNumberMenuLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "interval") {
                        const numberLoc = parseInt(item.replace("interval", ""));
                        setElements(elm => [...elm, intervalLayout(numberLoc, itemNode.value)]);
                        setNumberInterval(old => {
                            const arsOnly = sequence.filter(item => item.includes("interval"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("interval", ""))
                            );
                            setNumberIntervalLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "randomizer") {
                        const numberLoc = parseInt(item.replace("randomizer", ""));
                        setElements(elm => [...elm, randomizerLayout(numberLoc, itemNode.value)]);
                        setNumberRandomizer(old => {
                            const arsOnly = sequence.filter(item => item.includes("randomizer"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("randomizer", ""))
                            );
                            setNumberRandomizerLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "businessHours") {
                        const numberLoc = parseInt(item.replace("businessHours", ""));
                        setElements(elm => [
                            ...elm,
                            businessHoursLayout(numberLoc, itemNode.value)
                        ]);
                        setNumberBusinessHours(old => {
                            const arsOnly = sequence.filter(item => item.includes("businessHours"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("businessHours", ""))
                            );
                            setNumberBusinessHoursLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "connection") {
                        const numberLoc = parseInt(item.replace("connection", ""));
                        setElements(elm => [...elm, connectionLayout(numberLoc, itemNode.value)]);
                        setNumberConnection(old => {
                            const arsOnly = sequence.filter(item => item.includes("connection"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("connection", ""))
                            );
                            setNumberConnectionLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "queue") {
                        const numberLoc = parseInt(item.replace("queue", ""));
                        setElements(elm => [...elm, queueLayout(numberLoc, itemNode.value)]);
                        setNumberQueue(old => {
                            const arsOnly = sequence.filter(item => item.includes("queue"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("queue", ""))
                            );
                            setNumberQueueLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "tags") {
                        const numberLoc = parseInt(item.replace("tags", ""));
                        setElements(elm => [...elm, tagsLayout(numberLoc, itemNode.value)]);
                        setNumberTags(old => {
                            const arsOnly = sequence.filter(item => item.includes("tags"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("tags", ""))
                            );
                            setNumberTagsLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "addTags") {
                        const numberLoc = parseInt(item.replace("addTags", ""));
                        setElements(elm => [...elm, addTagsLayout(numberLoc, itemNode.value)]);
                        setNumberAddTags(old => {
                            const arsOnly = sequence.filter(item => item.includes("addTags"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("addTags", ""))
                            );
                            setNumberAddTagsLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "removeTags") {
                        const numberLoc = parseInt(item.replace("removeTags", ""));
                        setElements(elm => [...elm, removeTagsLayout(numberLoc, itemNode.value)]);
                        setNumberRemoveTags(old => {
                            const arsOnly = sequence.filter(item => item.includes("removeTags"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("removeTags", ""))
                            );
                            setNumberRemoveTagsLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "webhook") {
                        const numberLoc = parseInt(item.replace("webhook", ""));
                        setElements(elm => [...elm, webhookLayout(numberLoc, itemNode.value)]);
                        setNumberWebhook(old => {
                            const arsOnly = sequence.filter(item => item.includes("webhook"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("webhook", ""))
                            );
                            setNumberWebhookLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                    if (itemNode.type === "condition") {
                        const numberLoc = parseInt(item.replace("condition", ""));
                        setElements(elm => [...elm, conditionLayout(numberLoc, itemNode.value)]);
                        setNumberCondition(old => {
                            const arsOnly = sequence.filter(item => item.includes("condition"));
                            const arrNumberMax = arsOnly.map(item =>
                                parseInt(item.replace("condition", ""))
                            );
                            setNumberConditionLast(Math.max.apply(null, arrNumberMax) + 1);
                            return old + 1;
                        });
                    }
                });
            }
            setActiveModal(true);
        }
        if (open === "create") {
            setLabels({
                title: "Adicionar menu ao fluxo"
            });
            setTextDig();
            setArrayOption([]);
            setActiveModal(true);
        }
    }, [open]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    // Adicionar o estado para armazenar as conexões
    const [connections, setConnections] = useState([]);

    // Adicionar ao useEffect inicial para carregar as conexões
    useEffect(() => {
        const loadConnections = async () => {
            try {
                const { data } = await api.get("/whatsapp");
                setConnections(data);
            } catch (error) {
                console.error(error);
            }
        };

        loadConnections();
    }, []);

    // Adicionar o estado para armazenar as filas
    const [queues, setQueues] = useState([]);

    // Adicionar o useEffect para carregar as filas
    useEffect(() => {
        const loadQueues = async () => {
            try {
                const { data } = await api.get("/queue");
                setQueues(data);
            } catch (error) {
                console.error(error);
            }
        };

        loadQueues();
    }, []);

    const [availableTags, setAvailableTags] = useState([]);

    // Adicionar o useEffect para carregar as tags
    useEffect(() => {
        const loadTags = async () => {
            try {
                const { data: simpleTags } = await api.get("/tags", {
                    params: { searchParam: "", pageNumber: 1, kanban: 0 }
                });
                const { data: kanbanTags } = await api.get("/tags", {
                    params: { searchParam: "", pageNumber: 1, kanban: 1 }
                });

                const allTags = [
                    ...simpleTags.tags.map(tag => ({ ...tag, type: "simple" })),
                    ...kanbanTags.tags.map(tag => ({ ...tag, type: "kanban" }))
                ];

                setAvailableTags(allTags);
            } catch (error) {
                console.error(error);
            }
        };

        loadTags();
    }, []);

    const handleClose = async () => {
        close(null);
        setActiveModal(false);
        setTimeout(() => {
            setMedias([]);
            setPreviewImg([]);
            setPreviewAudios([]);
            setPreviewVideos([]);
            setArrayOption([]);
            setElements([]);
            setElementsSeq([]);
            setElementsEdit([]);
            setElementsSeqEdit([]);
            setNumberMessages(0);
            setNumberMessagesLast(0);
            setNumberInterval(0);
            setNumberIntervalLast(0);
            setNumberAudio(0);
            setNumberAudioLast(0);
            setNumberVideo(0);
            setNumberVideoLast(0);
            setNumberImg(0);
            setNumberImgLast(0);
            setNumberQueue(0);
            setNumberQueueLast(0);
            setNumberTags(0);
            setNumberTagsLast(0);
            setNumberAddTags(0);
            setNumberAddTagsLast(0);
            setNumberRemoveTags(0);
            setNumberRemoveTagsLast(0);
            setNumberWebhook(0);
            setNumberWebhookLast(0);
            setNumberCondition(0);
            setNumberConditionLast(0);
            setNumberDocument(0);
            setNumberDocumentLast(0);
            setNumberFileZip(0);
            setNumberFileZipLast(0);
            setNumberMenu(0);
            setNumberMenuLast(0);
            setNumberRandomizer(0);
            setNumberRandomizerLast(0);
            setNumberBusinessHours(0);
            setNumberBusinessHoursLast(0);
            setNumberConnection(0);
            setNumberConnectionLast(0);
        }, 500);
    };

    // const verifyButtonsUpload = () => {
    //     const newArrImg = elementsSeq.filter(item => item.includes("img"));
    //     const newArrAudio = elementsSeq.filter(item => item.includes("audio"));
    //     const newArrVideo = elementsSeq.filter(item => item.includes("video"));
    //     const newArrDocument = elementsSeq.filter(item => item.includes("document"));
    //     const newArrFileZip = elementsSeq.filter(item => item.includes("fileZip"));

    //     for (let i = 0; i < numberImg; i++) {
    //         const imgVerify = document.querySelector(`.btn${capitalize(newArrImg[i])}`);
    //         if (imgVerify) {
    //             return true;
    //         }
    //     }
    //     for (let i = 0; i < numberAudio; i++) {
    //         const audioVerify = document.querySelector(`.btn${capitalize(newArrAudio[i])}`);
    //         if (audioVerify) {
    //             return true;
    //         }
    //     }
    //     for (let i = 0; i < numberVideo; i++) {
    //         const videoVerify = document.querySelector(`.btn${capitalize(newArrVideo[i])}`);
    //         if (videoVerify) {
    //             return true;
    //         }
    //     }
    //     for (let i = 0; i < numberDocument; i++) {
    //         const docVerify = document.querySelector(`.btn${capitalize(newArrDocument[i])}`);
    //         if (docVerify) {
    //             return true;
    //         }
    //     }
    //     for (let i = 0; i < numberFileZip; i++) {
    //         const zipVerify = document.querySelector(`.btn${capitalize(newArrFileZip[i])}`);
    //         if (zipVerify) {
    //             return true;
    //         }
    //     }
    //     return false;
    // };

    const verifyButtonsUpload = () => {
        const newArrImg = elementsSeq.filter(item => item.includes("img"));
        const newArrAudio = elementsSeq.filter(item => item.includes("audio"));
        const newArrVideo = elementsSeq.filter(item => item.includes("video"));
        const newArrDocument = elementsSeq.filter(item => item.includes("document"));
        const newArrFileZip = elementsSeq.filter(item => item.includes("fileZip"));

        // Verifica se há botões de upload vazios
        const hasEmptyUpload = (type, arr, num) => {
            for (let i = 0; i < num; i++) {
                const uploadButton = document.querySelector(`.btn${capitalize(arr[i])}`);
                const previewElement = document.querySelector(`.${capitalize(type)}${arr[i]}`);

                // Se tem botão de upload E não tem preview, está vazio
                if (uploadButton && (!previewElement || !previewElement.innerHTML.trim())) {
                    return true;
                }
            }
            return false;
        };

        return (
            hasEmptyUpload("Img", newArrImg, numberImg) ||
            hasEmptyUpload("Audio", newArrAudio, numberAudio) ||
            hasEmptyUpload("Video", newArrVideo, numberVideo) ||
            hasEmptyUpload("Document", newArrDocument, numberDocument) ||
            hasEmptyUpload("FileZip", newArrFileZip, numberFileZip)
        );
    };

    const handleSaveNode = async () => {
        if (open === "edit") {
            setLoading(true);

            // Log inicial do estado
            console.log("Estado inicial ao editar:", {
                elementsSeq,
                currentElements: data.elements,
                currentData: data
            });

            const formData = new FormData();

            medias.forEach(async (media, idx) => {
                const file = media;

                if (!file) {
                    return;
                }

                if (media?.type.split("/")[0] == "image") {
                    new Compressor(file, {
                        quality: 0.7,

                        async success(media) {
                            formData.append("medias", media);
                            formData.append("body", media.name);
                        },
                        error(err) {
                            alert("erro");
                            console.log(err.message);
                        }
                    });
                } else {
                    formData.append("medias", media);
                    formData.append("body", media.name);
                }
            });

            setTimeout(async () => {
                if (
                    (numberAudio === 0 &&
                        numberVideo === 0 &&
                        numberImg === 0 &&
                        numberDocument === 0 &&
                        numberFileZip === 0) ||
                    medias.length === 0
                ) {
                    try {
                        const mountData = {
                            seq: elementsSeq,
                            elements: handleElements(null)
                        };

                        // Log antes do update sem mídia
                        console.log("Dados montados sem mídia:", {
                            mountData,
                            elements: handleElements(null)
                        });

                        onUpdate({
                            ...data,
                            data: mountData
                        });

                        // Log após montar o objeto de update
                        console.log("Objeto final do update:", {
                            updatedData: {
                                ...data,
                                data: mountData
                            }
                        });

                        toast.success("Conteúdo adicionada com sucesso!");
                        handleClose();
                        setLoading(false);

                        return;
                    } catch (e) {
                        console.log(e);
                        setLoading(false);
                    }
                    return;
                }
                const verify = verifyButtonsUpload();
                if (verify) {
                    setLoading(false);
                    return toast.error("Delete os cards vazios(Imagem, Audio e Video)");
                }
                await api
                    .post("/flowbuilder/content", formData)
                    .then(async res => {
                        const mountData = {
                            seq: elementsSeq,
                            elements: handleElements(res.data)
                        };

                        // Log antes do update com mídia
                        console.log("Dados montados com mídia:", {
                            mountData,
                            responseData: res.data,
                            elements: handleElements(res.data)
                        });

                        onUpdate({
                            ...data,
                            data: mountData
                        });

                        // Log após montar objeto de update
                        console.log("Objeto final do update com mídia:", {
                            updatedData: {
                                ...data,
                                data: mountData
                            }
                        });

                        toast.success("Conteúdo adicionada com sucesso!");
                        await handleClose();
                        setLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }, 1500);
        } else if (open === "create") {
            setLoading(true);
            const formData = new FormData();

            medias.forEach(async (media, idx) => {
                const file = media;

                if (!file) {
                    return;
                }

                if (media?.type.split("/")[0] == "image") {
                    new Compressor(file, {
                        quality: 0.7,

                        async success(media) {
                            formData.append("medias", media);
                            formData.append("body", media.name);
                        },
                        error(err) {
                            alert("erro");
                            console.log(err.message);
                        }
                    });
                } else {
                    formData.append("medias", media);
                    formData.append("body", media.name);
                }
            });

            setTimeout(async () => {
                if (
                    numberAudio === 0 &&
                    numberVideo === 0 &&
                    numberImg === 0 &&
                    numberDocument === 0 &&
                    numberFileZip === 0
                ) {
                    try {
                        const mountData = {
                            seq: elementsSeq,
                            elements: handleElements(null)
                        };
                        onSave({
                            ...mountData
                        });
                        toast.success("Conteúdo adicionada com sucesso!");
                        handleClose();
                        setLoading(false);

                        return;
                    } catch (e) {
                        setLoading(false);
                    }
                }
                const verify = verifyButtonsUpload();
                if (verify) {
                    setLoading(false);
                    return toast.error(
                        "Delete os cards vazios(Imagem, Audio, Video, Documento e FileZip)"
                    );
                }
                await api
                    .post("/flowbuilder/content", formData)
                    .then(res => {
                        const mountData = {
                            seq: elementsSeq,
                            elements: handleElements(res.data)
                        };
                        onSave({
                            ...mountData
                        });
                        toast.success("Conteúdo adicionada com sucesso!");
                        handleClose();
                        setLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }, 1500);
        }
    };

    const scrollToBottom = className => {
        const element = document.querySelector(className);
        if (element) {
            // Adicionar esta verificação
            element.scrollTop = element.scrollHeight;
        }
    };

    // const handleAddEmoji = (e, targetField) => {
    //     // Verifica se o emoji tem a propriedade 'native'
    //     const emoji = e.native || e.emoji || e;
    //     console.log("Emoji to insert:", emoji);

    //     if (targetField && targetField.type === "textarea") {
    //         const cursorPos = targetField.selectionStart;
    //         const currentValue = targetField.value || "";

    //         const newValue =
    //             currentValue.slice(0, cursorPos) + emoji + currentValue.slice(cursorPos);
    //         console.log("New Value:", newValue);

    //         if (targetField.id.startsWith("message-field-")) {
    //             const index = parseInt(targetField.id.split("-")[2]);

    //             const newElements = [...elements];
    //             if (newElements[index]) {
    //                 // Se for um menu, atualiza a mensagem
    //                 if (newElements[index].type === "menu") {
    //                     newElements[index] = {
    //                         ...newElements[index],
    //                         message: newValue
    //                     };
    //                 } else {
    //                     // Para outros tipos, atualiza o texto
    //                     newElements[index] = {
    //                         ...newElements[index],
    //                         text: newValue
    //                     };
    //                 }
    //                 setElements(newElements);
    //             }
    //         }

    //         // Reposiciona o cursor após o emoji
    //         setTimeout(() => {
    //             targetField.focus();
    //             targetField.setSelectionRange(cursorPos + emoji.length, cursorPos + emoji.length);
    //         }, 10);
    //     }
    // };

    // const handleTextFieldFocus = e => {
    //     setCurrentTextField(e.target);
    // };

    return (
        <Formik
            initialValues={{
                elements: elements || [],
                elementsSeq: elementsSeq || []
            }}
            //onSubmit={handleSaveNode}
        >
            {({ isSubmitting }) => (
                <Dialog
                    className={classes.formCointainerDialog}
                    open={activeModal}
                    onClose={handleClose}
                    maxWidth="xs"
                    fullWidth
                    scroll="paper"
                >
                    <DialogTitle>Adicionar conteúdo ao fluxo</DialogTitle>

                    <DialogContent dividers>
                        <div className={classes.root}>
                            {elements.map((element, index) => element)}
                        </div>
                    </DialogContent>

                    <DialogActions className={classes.bottomActions}>
                        <div className={classes.bottomActionsContainer}>
                            {/* <div
                                className={`${classes.emojiContainer} ${
                                    showEmoji ? "expanded" : ""
                                }`}                            >
                               
                                <Picker
                                    title="Escolha um emoji"
                                    emoji="point_up"
                                    style={{ width: "100%" }}
                                    showPreview={false}
                                    showSkinTones={false}
                                    onEmojiSelect={emojiObject => {
                                        console.log("Emoji Object:", emojiObject);
                                        handleAddEmoji(emojiObject, currentTextField);
                                    }}
                                />
                            </div> */}

                            <div className={classes.mediaButtons}>
                                {/* Botão de Texto */}
                                <Tooltip title={i18n.t("buttons.addMessageNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                messageLayout(numberMessagesLast)
                                            ]);
                                            setNumberMessages(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `message${numberMessagesLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberMessagesLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <Message />
                                    </Button>
                                </Tooltip>

                                {/* Botão de Imagem */}
                                <Tooltip title={i18n.t("buttons.addImgNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [...old, imgLayout(numberImgLast)]);
                                            setNumberImg(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `img${numberImgLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberImgLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <Image />
                                    </Button>
                                </Tooltip>

                                {/* Botão de Audio */}
                                <Tooltip title={i18n.t("buttons.addAudioNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                audioLayout(numberAudioLast)
                                            ]);
                                            setNumberAudio(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `audio${numberAudioLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberAudioLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <MicNone />
                                    </Button>
                                </Tooltip>

                                {/* Botão de Video */}
                                <Tooltip title={i18n.t("buttons.addVideoNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                videoLayout(numberVideoLast)
                                            ]);
                                            setNumberVideo(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `video${numberVideoLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberVideoLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <Videocam />
                                    </Button>
                                </Tooltip>

                                {/* Botão de Documento */}
                                <Tooltip title={i18n.t("buttons.addDocumentNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                documentLayout(numberDocumentLast)
                                            ]);
                                            setNumberDocument(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `document${numberDocumentLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberDocumentLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <Description />
                                    </Button>
                                </Tooltip>

                                {/* Botão de ZIP/RAR */}
                                <Tooltip title={i18n.t("buttons.addFileZipNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                fileZipLayout(numberFileZipLast)
                                            ]);
                                            setNumberFileZip(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `fileZip${numberFileZipLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberFileZipLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <FolderZip />
                                    </Button>
                                </Tooltip>

                                {/* Botão de Menu */}
                                {/* <Tooltip title={i18n.t("buttons.addMenuNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                menuLayout(numberMenuLast)
                                            ]);
                                            setNumberMenu(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `menu${numberMenuLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberMenuLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <MenuIcon />
                                    </Button>
                                </Tooltip> */}

                                {/* Botão de Delay */}
                                <Tooltip title={i18n.t("buttons.addDelayNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                intervalLayout(numberIntervalLast)
                                            ]);
                                            setNumberInterval(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `interval${numberIntervalLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberIntervalLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <AccessTime />
                                    </Button>
                                </Tooltip>

                                {/* Botão de Radomize */}
                                {/* <Tooltip title={i18n.t("buttons.addRadomizerNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                randomizerLayout(numberRandomizerLast)
                                            ]);
                                            setNumberRandomizer(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `randomizer${numberRandomizerLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberRandomizerLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <CallSplit />
                                    </Button>
                                </Tooltip> */}

                                {/* Botão de Condição */}
                                {/* <Tooltip title={i18n.t("buttons.addConditionNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                conditionLayout(numberConditionLast)
                                            ]);
                                            setNumberCondition(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `condition${numberConditionLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberConditionLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <ImportExport />
                                    </Button>
                                </Tooltip> */}

                                {/* Botão de Fila */}
                                <Tooltip title={i18n.t("buttons.addQueueNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                queueLayout(numberQueueLast)
                                            ]);
                                            setNumberQueue(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `queue${numberQueueLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberQueueLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <QueueIcon />
                                    </Button>
                                </Tooltip>

                                {/* Botão de Conexão */}
                                <Tooltip title={i18n.t("buttons.addConnectionNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                connectionLayout(numberConnectionLast)
                                            ]);
                                            setNumberConnection(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `connection${numberConnectionLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberConnectionLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <PhoneIcon />
                                    </Button>
                                </Tooltip>

                                {/* Botão de Condição de Tags */}
                                {/* <Tooltip title={i18n.t("buttons.addConditionTagsNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                tagsLayout(numberTagsLast)
                                            ]);
                                            setNumberTags(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `tags${numberTagsLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberTagsLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <LoyaltyIcon />
                                    </Button>
                                </Tooltip> */}

                                {/* Botão de Add Tags */}
                                <Tooltip title={i18n.t("buttons.addAddTagsNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                addTagsLayout(numberAddTagsLast)
                                            ]);
                                            setNumberAddTags(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `addTags${numberAddTagsLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberAddTagsLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <AddIcon
                                            sx={{
                                                width: "16px",
                                                height: "16px",
                                                marginRight: "4px"
                                            }}
                                        />
                                        <LocalOfferIcon />
                                    </Button>
                                </Tooltip>

                                {/* Botão de Remove Tags */}
                                <Tooltip title={i18n.t("buttons.addRemoveTgasNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                removeTagsLayout(numberRemoveTagsLast)
                                            ]);
                                            setNumberRemoveTags(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `removeTags${numberRemoveTagsLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberRemoveTagsLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <RemoveIcon
                                            sx={{
                                                width: "16px",
                                                height: "16px",
                                                marginRight: "4px"
                                            }}
                                        />
                                        <LocalOfferIcon />
                                    </Button>
                                </Tooltip>

                                {/* Botão de Expediente */}
                                {/* <Tooltip title={i18n.t("buttons.addBbusinessHoursNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                businessHoursLayout(numberBusinessHoursLast)
                                            ]);
                                            setNumberBusinessHours(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `businessHours${numberBusinessHoursLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberBusinessHoursLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <AccessTime />
                                    </Button>
                                </Tooltip> */}

                                {/* Botão de Webhook */}
                                {/* <Tooltip title={i18n.t("buttons.addWebhookNode")}>
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setElements(old => [
                                                ...old,
                                                webhookLayout(numberWebhookLast)
                                            ]);
                                            setNumberWebhook(old => {
                                                setElementsSeq(oldEleme => [
                                                    ...oldEleme,
                                                    `webhook${numberWebhookLast}`
                                                ]);
                                                return old + 1;
                                            });
                                            setNumberWebhookLast(old => old + 1);
                                            setTimeout(() => {
                                                scrollToBottom(".body-card");
                                            }, 100);
                                        }}
                                    >
                                        <WebhookOutlined />
                                    </Button>
                                </Tooltip>*/}
                            </div>
                        </div>

                        <div className={classes.actionButtons}>
                            <Grid item xs={12}>
                                <MainHeaderButtonsWrapper>
                                    {/* <IconButton
                                        onClick={() => setShowEmoji(!showEmoji)}
                                        color={showEmoji ? "primary" : "default"}
                                        style={{
                                            right: -220
                                        }}
                                    >
                                        <SentimentSatisfiedAltIcon />
                                    </IconButton> */}

                                    <Tooltip title={i18n.t("buttons.cancel")}>
                                        <Button
                                            onClick={handleClose}
                                            disabled={isSubmitting}
                                            variant="text"
                                            style={{
                                                right: -275
                                            }}
                                        >
                                            <BackspaceOutlinedIcon
                                                style={{ color: orange[700] }}
                                                fontSize="medium"
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={i18n.t("buttons.save")}>
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting}
                                            variant="text"
                                            onClick={() => handleSaveNode()}
                                            className={classes.btnWrapper}
                                            style={{
                                                right: -275
                                            }}
                                        >
                                            <SaveSharpIcon
                                                style={{ color: green[700] }}
                                                fontSize="medium"
                                            />
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </Tooltip>
                                </MainHeaderButtonsWrapper>
                            </Grid>
                        </div>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

export default FlowBuilderSingleBlockModal;
